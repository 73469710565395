import { BasePageLayout } from '@kirz/mui-admin';
import { Box, Button } from '@mui/material';
import { Trello } from 'mdi-material-ui';
import React, { useRef, useState } from 'react';

import DealsFilter from 'components/DealsBoard/DealsFilter';
import DealsReportTable from 'components/DealsBoard/DealsReport';

export function DealsReport() {
  const [dealsFilter, setDealsFilter] = useState<Record<string, any>>();

  const filtersControlRef = useRef<any>();

  return (
    <BasePageLayout
      title="Отчет по сделкам"
      actionContent={
        <>
          <Button sx={{ ml: 2, mt: 0.5 }} startIcon={<Trello />} href="/deals">
            Воронка
          </Button>
        </>
      }
    >
      <Box sx={{ ml: 2, mt: 2.5 }}>
        <DealsFilter
          mode="report"
          controlRef={filtersControlRef}
          onStagesFilterChange={(value) => {}}
          onDealsFilterChange={(value) => {
            setDealsFilter(value);
          }}
        />
      </Box>
      <DealsReportTable dealsFilter={dealsFilter} />
    </BasePageLayout>
  );
}
