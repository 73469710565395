import {
  buildHasuraQuery,
  ConfigurationType as MuiAdminConfigurationType,
  DefaultConfiguration as MuiFormDefaultConfiguration,
  HasuraQuery,
} from '@kirz/mui-admin';

import 'dayjs/locale/ru';
import { api } from 'services/api';
import store from 'services/store';
import { User } from 'types/user';
import { getKeyboardInvariantStrings } from 'utils/getKeyboardInvariantStrings';

const request: MuiAdminConfigurationType['hasura']['request'] = async (
  query,
  options,
) => {
  const { extractResult, ...data } = buildHasuraQuery(query);

  const isMutation =
    typeof query === 'object' &&
    (query.type === 'mutation' ||
      (query.type === 'custom' && query.query.trim().startsWith('mutation')));

  if (isMutation) {
    const { data: response } = await api.hasuraMutationClientInstance.post(
      '',
      data,
    );
    return extractResult ? extractResult(response.data) : response.data;
  }

  const { data: response } = await api.hasuraClientInstance.post('', data, {
    headers: {
      ...(options?.showRemoved && {
        'X-Hasura-Removed-Entities': 'true',
      }),
    },
  });

  return extractResult ? extractResult(response.data) : response.data;
};

const updatePreferences = async (preferences: Record<string, any>) => {
  if (!store.user) {
    return;
  }

  request({
    type: 'mutation',
    source: 'user',
    action: 'update',
    where: {
      id: { _eq: store.user.id },
      companyId: { _eq: store.user.companyId },
    },
    set: {
      preferences,
    },
  });
};

export const Configuration = (user?: User) => ({
  muiAdmin: {
    ...MuiFormDefaultConfiguration,
    translations: {
      valueRequired: 'Требуется значение',
      wrongEmailFormat: 'Неверный формат',
      wrongPhoneFormat: 'Неверный формат',
      wrongDateFormat: 'Неверный формат',
      autocompletePlaceholder: 'Введите и нажмите «Enter»',
      tableSearchPlaceholder: 'Поиск по названию',
      tableAddFilterTooltip: 'Добавить фильтр',
      tableNoRows: 'Нет данных',
      unexpectedError: 'Произошла ошибка',
      deleteTableRow: 'Удалить',
      attachmentsZoneTitle: 'Выберите тип вложения',
      attachmentsZoneLabel: 'Тип вложения',
      attachmentsZoneAccept: 'Загрузить',
      attachmentsZoneCancel: 'Отмена',
      attachmentsUploadedSuccessfully: 'Файлы загружены',
      attachmentsUploadedOrDropFileHere: 'Перетащите или выберите файл',
      attachmentsNoFiles: 'Нет загруженных файлов',
      attachmentsFileMenuDownload: 'Скачать',
      attachmentsFileMenuDelete: 'Удалить',
      addNewAutocompleteValue: 'Добавить',
      cancel: 'Отмена',
      addNew: 'Добавить',
      copied: 'Скопировано',
      create: 'Создать',
      open: 'Открыть',
      save: 'Сохранить',
      saveAndExit: 'Сохранить и выйти',
      nullSelectOptionText: 'None',
      changesNotSaved: 'Изменения не сохранены!',
      youWillLoseAllUnsavedData:
        'При выходе вы потеряете все несохраненные данные.',
      goAnyways: 'Перейти',
      clear: 'Очистить',
      noFile: 'Отсутствует',
      notSet: 'Отсутствует',
      columnFillAvailableSpace: 'Заполнить свободное пространство',
      resetTableState: 'Сбросить к значениям по умолчанию',
    },
    defaultPhoneCountry: 'RU',
    defaultLocale: 'ru',
    availableLocales: ['en', 'ru'],
    onSearch: (value) => getKeyboardInvariantStrings(value.toLowerCase()),
    defaultDeleteConfirm: {
      ...MuiFormDefaultConfiguration.defaultDeleteConfirm,
      title: 'Удалить выбранный элемент?',
      accept: 'Удалить',
      cancel: 'Отмена',
    },
    defaultDeleteFileConfirm: {
      ...MuiFormDefaultConfiguration.defaultDeleteFileConfirm,
      title: 'Удалить файл?',
      accept: 'Удалить',
      cancel: 'Отмена',
    },
    hasura: {
      ...MuiFormDefaultConfiguration.hasura,
      request,
      subscribe(request: HasuraQuery, onNext: (value: any) => void) {
        const { extractResult, query, variables } = buildHasuraQuery(request);

        const onNextResponse = ({ data }: any) => {
          onNext(
            typeof extractResult === 'function' ? extractResult(data) : data,
          );
        };

        const unsubscribe = api.hasuraWsClientInstance!.subscribe(
          {
            query,
            variables,
          },
          {
            next: onNextResponse,
            error: () => {},
            complete: () => {},
          },
        );

        return unsubscribe;
      },
      primaryKey: 'id',
    },
    rest: {
      ...MuiFormDefaultConfiguration.rest,
      client: api.instance,
    },
    alerts: {
      ...MuiFormDefaultConfiguration.alerts,
      snackbars: {
        ...MuiFormDefaultConfiguration.alerts.snackbars,
        entityCreated: { text: 'Сохранено', variant: 'success' },
        entityUpdated: { text: 'Сохранено', variant: 'success' },
        entityDeleted: { text: 'Удалено', variant: 'success' },
      },
    },
    ...(user?.roleId !== -1 && {
      store: {
        // ...MuiFormDefaultConfiguration.store,
        setItem(key, value) {
          store.preferences[key.replaceAll('_', '').toLowerCase()] = value;
          updatePreferences(store.preferences);
        },
        removeItem(key) {
          delete store.preferences[key.replaceAll('_', '').toLowerCase()];
          updatePreferences(store.preferences);
        },
        getItem(key) {
          return store.preferences[key.replaceAll('_', '').toLowerCase()];
        },
      },
    }),
  } as MuiAdminConfigurationType,
});
