import { numberToQuarter } from './other';

export const getSaleQueueName = (
  quarter: number,
  year: number,
  short?: boolean,
) =>
  short
    ? `${numberToQuarter(quarter)}к${(year % 100).toString().padStart(2, '0')}`
    : `${numberToQuarter(quarter)} кв. ${year} г.`;

export const RoomsNumbers = [
  { text: 'Комната', value: 'room' },
  { text: 'Студия', value: 'studio' },
  { text: 'Кладовка', value: 'pantry' },
  { text: 'Паркинг', value: 'parking' },
  { text: 'Офис', value: 'office' },
  { text: 'Таунхаус', value: 'townhouse' },
  { text: 'Коттедж', value: 'cottage' },
  { text: 'Комерческое помещение', value: 'commercial' },
  { text: '1', value: '1' },
  { text: '2Е', value: '2E' },
  { text: '2', value: '2' },
  { text: '3Е', value: '3E' },
  { text: '3', value: '3' },
  { text: '4Е', value: '4E' },
  { text: '4', value: '4' },
  { text: '5Е', value: '5E' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: 'CA', value: 'CA' },
  { text: '1A', value: '1A' },
  { text: '2A', value: '2A' },
  { text: '3A', value: '3A' },
];

export const Statuses = {
  BOOKED: 'booked',
  DEPOSIT_MADE: 'depositMade',
  BOUGHT: 'bought',
  FOR_SALE: 'for sale',
  SOLD_OUT: 'sold out',
};

export const Types = [
  { text: 'Не указано', value: 'unknown' },
  { text: 'Переуступка', value: 'assignment' },
  { text: 'В собственности', value: 'owned' },
  { text: 'Вторичка', value: 'secondary' },
  { text: 'Аренда', value: 'rent' },
  { text: 'ДДУ', value: 'ddu' },
  { text: 'ПДКП', value: 'pdkp' },
  { text: 'ДКП', value: 'dkp' },
];

export const HouseTypes = [
  { text: 'Монолит', value: 'monolith' },
  { text: 'Кирпич-монолит', value: 'monolith-brick' },
  { text: 'Кирпич', value: 'brick' },
  { text: 'Панель', value: 'panel' },
  { text: 'Другое', value: 'other' },
];

export const Sources = [
  { text: 'Брокер', value: 'broker' },
  { text: 'Партнер', value: 'partner' },
  { text: 'Реклама', value: 'adverts' },
];

export const EntityCounterparties = [
  { text: 'Не выбрано', value: 'unknown' },
  { text: 'Прямой', value: 'direct' },
  { text: 'Тренд', value: 'trend' },
  { text: 'ПаН', value: 'PaN' },
  { text: 'нМаркет', value: 'nMarket' },
];

export const Finishing = [
  { text: 'Без отделки', value: 'none' },
  { text: 'Чистовая', value: 'clear' },
  { text: 'Предчистовая', value: 'beforeClear' },
  { text: 'С мебелью', value: 'furnished' },
];

export const Obligations = [
  { text: 'Не указано', value: 'unknown' },
  { text: 'Есть', value: 'yes' },
  { text: 'Нет', value: 'no' },
];

export const PaymentForms = [
  { text: '100%', value: '100%' },
  { text: 'Ипотека', value: 'mortgage' },
  { text: 'Рассрочка', value: 'delayed' },
  { text: 'Военная ипотека', value: 'military' },
  { text: 'Ипотека без данных', value: 'mortgage-without-data' },
];

export const PaymentFormNotes = [
  { text: 'Мат. капитал', value: 'maternal-capital' },
  { text: 'Субсидия', value: 'subsidy' },
  { text: 'Военная ипотека', value: 'military' },
];

export const InstallmentTypes = [
  { text: 'Равномерная', value: 'uniform' },
  { text: 'Индивидуальная', value: 'individual' },
];
