import {
  AutocompleteInput,
  CheckboxInput,
  ConfigurationContext,
  DateInput,
  FormInput,
  FormTab,
  FormGetter,
  RadioGroupInput,
  NotificationsContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Star, StarOutline } from 'mdi-material-ui';
import React, { useContext, useRef } from 'react';

import { DealsTable } from 'components/DealsTable';
import { TasksTable } from 'components/TasksTable';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export default function MainTab(props: { clientId: number }) {
  const { clientId } = props;
  const { user, hasPermission } = useContext(UserContext);
  const { leadSources, tags } = useContext(SettingsContext);
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);
  const phonesTableRef = useRef<DataTableExRef>(null);
  const emailsTableRef = useRef<DataTableExRef>(null);

  const buildTableProps = (table: 'phones' | 'emails') => ({
    columnHeaderHeight: 0,
    inline: true,
    autoHeight: true,
    slots: {
      noRowsOverlay: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            opacity: 0.7,
          }}
        >
          Нет данных
        </Box>
      ),
      footer: () => null,
      columnHeaders: () => null,
    },
    selectProps: {
      filter: { clientId: { _eq: clientId } },
    },
    sortBy: { field: 'isFavourite', sort: 'desc' },
    editable: {
      columnProps: { width: 40, minWidth: 40 },
    },
    deletable: {
      columnProps: { width: 45, minWidth: 45 },
      isEnabled: (row: any) => !row.isFavourite,
    },
    formDialogProps: {
      maxWidth: 'xs',
      formSubmitterProps: {
        preSubmit: async (item: any) => {
          const Source = table === 'phones' ? 'ClientPhone' : 'ClientEmail';
          const source = table === 'phones' ? 'clientPhone' : 'clientEmail';

          const {
            items: [favoritePhone],
          } = await hasura.request({
            type: 'custom',
            query: `
            query FetchFavourite($where: ${Source}BoolExp) {
              items: ${source}(where: $where, limit: 1) {
                id
              }
            }`,
            variables: {
              where: {
                clientId: { _eq: clientId },
                isFavourite: { _eq: true },
              },
            },
          });

          return {
            ...item,
            clientId,
            ...(!favoritePhone && {
              isFavourite: true,
            }),
          };
        },
      },
    },
    sx: {
      borderColor: 'transparent',
    },
  });

  const buildFavouriteColumn = (table: 'phones' | 'emails') =>
    ({
      field: 'isFavourite',
      headerName: table === 'phones' ? 'Основной?' : 'Основная?',
      type: 'icon-button',
      // eslint-disable-next-line react/no-unstable-nested-components
      icon: (row) =>
        !row.isFavourite ? <StarOutline /> : <Star htmlColor="#FFCD3C" />,
      width: 30,
      onClick: async (row) => {
        if (row.isFavourite) {
          return;
        }

        const Source = table === 'phones' ? 'ClientPhone' : 'ClientEmail';

        await hasura.request({
          type: 'custom',
          query: `mutation ToggleDefault ($id: Int!, $clientId: Int!) {
          set: update${Source}(where: { id: {_eq: $id}, clientId: {_eq: $clientId} }, _set:{ isFavourite: true }) {
            affected_rows
          }
          
          unset: update${Source}(where: { id: {_neq: $id}, clientId: {_eq: $clientId} }, _set:{ isFavourite: false }) {
            affected_rows
          }
        }`,
          variables: { id: row.id, clientId },
        });

        showAlert('Сохранено', 'success');
        if (table === 'phones') {
          await phonesTableRef.current!.reload();
        } else {
          await emailsTableRef.current!.reload();
        }
      },
    } as HasuraDataTableColumnDef);

  return (
    <FormTab
      tab="main"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      {(isVisible) => (
        <>
          <Grid container sx={{ flexBasis: 'auto !important' }}>
            <Grid xs={30} container columns={30}>
              <FormInput
                name="secondName"
                label="Фамилия"
                xs={30}
                sm={10}
                lg={6}
              />
              <FormInput
                name="firstName"
                label="Имя"
                xs={30}
                sm={10}
                required
                lg={6}
              />
              <FormInput
                name="middleName"
                label="Отчество"
                xs={30}
                sm={10}
                lg={6}
              />
              <DateInput
                name="birthday"
                label="День рождения"
                xs={30}
                sm={15}
                lg={6}
              />
              <RadioGroupInput
                name="gender"
                label="Пол"
                xs={30}
                sm={15}
                lg={6}
                items={[
                  { text: 'муж.', value: 'male' },
                  { text: 'жен.', value: 'female' },
                ]}
              />
            </Grid>
            <Grid xs={12} md={6} lg={3}>
              {isVisible && (
                <DataTableEx
                  id="client-phones-table"
                  ref={phonesTableRef}
                  title="Телефоны"
                  source="clientPhone"
                  columns={[
                    buildFavouriteColumn('phones'),
                    {
                      field: 'phone',
                      headerName: 'Значение',
                      type: 'phone',
                      flex: 1,
                    },
                  ]}
                  formTitle={(isNew) =>
                    isNew ? 'Новый телефон' : 'Редактирование телефона'
                  }
                  skeletonRowsCount={2}
                  {...(buildTableProps('phones') as any)}
                >
                  <FormInput name="phone" label="Телефон" required type="tel" />
                </DataTableEx>
              )}
            </Grid>
            <Grid xs={12} md={6} lg={3}>
              {isVisible && (
                <DataTableEx
                  id="client-emails-table"
                  ref={emailsTableRef}
                  title="Почта"
                  source="clientEmail"
                  columns={[
                    buildFavouriteColumn('emails'),
                    {
                      field: 'email',
                      headerName: 'Значение',
                      type: 'email',
                      flex: 1,
                    },
                  ]}
                  formTitle={(isNew) =>
                    isNew ? 'Новая почта' : 'Редактирование почты'
                  }
                  skeletonRowsCount={2}
                  {...(buildTableProps('emails') as any)}
                >
                  <FormInput name="email" label="Почта" required type="email" />
                </DataTableEx>
              )}
            </Grid>
            <Grid sm={12} lg={6} container alignItems="start">
              <FormGetter
                names={['userId']}
                render={({ userId }) => (
                  <Grid xs={12} container spacing={0}>
                    <CheckboxInput
                      name="mainExpertAcceptanceRequired"
                      label="Не звонить без согласования с основным экспертом"
                      sm={12}
                      disabled={user.id !== userId}
                    />
                    <CheckboxInput
                      name="messengersNotAllowed"
                      label="Не писать в мессенджеры"
                      sm={12}
                      md={6}
                    />
                    <CheckboxInput
                      name="mailingsNotAllowed"
                      label="Без рассылок"
                      sm={12}
                      md={6}
                    />
                  </Grid>
                )}
              />
              <AutocompleteInput
                name="userId"
                mode="hasura"
                source="user"
                label="Персональный менеджер"
                selection="id fullName"
                itemText="fullName"
                sm={6}
                required
              />
              <AutocompleteInput
                name="refererUserId"
                mode="hasura"
                source="user"
                label="Реферер эксперт"
                selection="id fullName"
                itemText="fullName"
                sm={6}
              />
              <AutocompleteInput
                name="refererClientId"
                mode="hasura"
                source="client"
                label="Реферер клиент"
                selection="id fullName"
                itemText="fullName"
                sm={6}
                fetchAll={false}
              />
              <AutocompleteInput
                name="source"
                label="Источник"
                sm={6}
                freeSolo
                required
                options={leadSources.map((x) => ({ value: x, text: x }))}
              />
            </Grid>
            <AutocompleteInput
              name="tags"
              label="Теги"
              multiple
              options={tags
                .filter((x) => !x.systemType && x.entity === 'client')
                .map((x) => ({ value: x.id, text: x.name }))}
              formFetcherValueResolver={{
                source: 'clientTag',
                selection: 'tagId',
                filter: { clientId: { _eq: clientId } },
                resolveItems: (items) => items.map((x) => x.tagId),
              }}
              formSubmitterValueResolver={{
                type: 'hasura-many-to-many',
                source: 'clientTag',
                foreignKey: 'tagId',
                entityId: { clientId, companyId: user.companyId },
              }}
            />
            <FormInput
              name="comment"
              label="Комментарий"
              type="text"
              multiline
              rows={2}
              sm={12}
            />
          </Grid>
          {isVisible && (
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <TasksTable
                id="client-tasks-table"
                title="Задачи"
                sx={{ minHeight: 400, mx: -2, borderColor: 'transparent' }}
                disableInitialization={clientId == null}
                selectProps={{
                  filter: { clientId: { _eq: clientId } },
                }}
                formDialogProps={{
                  formProps: {
                    defaultValues: { clientId },
                  },
                  ...({ disableClient: true } as any),
                }}
              />
              <DealsTable
                id="client-deals-table"
                title="Сделки"
                sx={{ minHeight: 400, mx: -2, borderColor: 'transparent' }}
                disableInitialization={clientId == null}
                deletable={hasPermission(Permissions.DEALS_DELETE)}
                sortBy={{ field: 'dealDate', sort: 'desc' }}
                selectProps={{
                  filter: {
                    _or: [
                      { clientId: { _eq: clientId } },
                      { sellerId: { _eq: clientId } },
                      { clients: { clientId: { _eq: clientId } } },
                    ],
                  },
                }}
                formDialogProps={{
                  formProps: {
                    defaultValues: { clientId },
                  },
                  ...({ disableClient: true } as any),
                }}
              />
            </Box>
          )}
        </>
      )}
    </FormTab>
  );
}
