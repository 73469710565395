import {
  DateInput,
  Form,
  FormGetter,
  FormSetter,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Box, Button, IconButton, Tooltip, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { ChevronLeft, ChevronRight, FileExcel } from 'mdi-material-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';

import { UserSalariesTable } from 'components/UserSalariesTable';

export function Salaries() {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<string | null>(null);
  const [selectedTab] = useState<string>('active');
  const fetchedItemsRef = useRef<any[]>([]);

  const tableRef = useRef<DataTableExRef>(null);

  const filterColumns = useCallback(
    (columns: HasuraDataTableColumnDef[]) => {
      const ignore = ['result'];

      if (selectedTab === 'assigned' || selectedTab === 'completed') {
        return columns
          .filter(
            (x) =>
              !(selectedTab === 'assigned' && x.field === 'creator') &&
              !(selectedTab === 'completed' && x.field === 'user'),
          )
          .filter((x) => !ignore.includes(x.field))
          .flatMap((x) =>
            x.field === 'task'
              ? [
                  x,
                  {
                    headerName: 'Результат',
                    field: 'result',
                    placeholder: false,
                    ...(x.width && { width: x.width }),
                    ...(x.minWidth && { minWidth: x.minWidth }),
                    ...(x.flex && { flex: x.flex }),
                    renderCell: x.renderCell,
                  } as any,
                ]
              : x,
          );
      }

      return columns.filter((x) => !ignore.includes(x.field));
    },
    [selectedTab],
  );

  useEffect(() => {
    tableRef.current?.reload();
  }, [selectedDate, selectedDateTo]);

  return (
    <TablePageLayout
      title="Зарплата"
      actionContent={
        <CsvDownloader
          datas={async () => {
            const items = fetchedItemsRef.current.map((x) => ({
              ID: x.id,
              ФИО: x.user.fullName,
              Роль: x.user.role.name,
              'Коммиссия по завершенным сделкам': x.salarySum,
              'Число завершенных сделок': x.finishedDealsCount,
            }));

            return items;
          }}
          filename={`${dayjs(selectedDate).format('DD.MM.YYYY')}-${dayjs(
            selectedDateTo,
          ).format('DD.MM.YYYY')}.csv`}
          style={{ marginLeft: 'auto' }}
        >
          <Button variant="contained" startIcon={<FileExcel />}>
            Экспорт в CSV
          </Button>
        </CsvDownloader>
      }
    >
      <Box sx={{ my: 0.5, ml: -1, mb: 1 }}>
        <Form
          sx={{ width: { xs: 200, md: 500 } }}
          defaultValues={{
            date: dayjs().startOf('month').format('YYYY-MM-DD'),
            toDate: dayjs().endOf('month').format('YYYY-MM-DD'),
          }}
          grid={false}
          dense
          persistStateMode={{ type: 'query', queryPrefix: 'df_' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: -0.5,
            }}
          >
            <FormSetter
              render={(setValue) => (
                <FormGetter
                  onChange={(item) => {
                    setSelectedDate(`${item.date}T00:00:00Z`);
                    setSelectedDateTo(`${item.toDate}T00:00:00Z`);
                  }}
                  render={(item) => (
                    <>
                      <Tooltip title="Предыдущий месяц">
                        <IconButton
                          onClick={() =>
                            setValue(
                              'date',
                              dayjs(item.date)
                                .add(-1, 'month')
                                .format('YYYY-MM-DD'),
                            )
                          }
                          size="small"
                        >
                          <ChevronLeft
                            htmlColor={theme.palette.brand.main}
                            sx={{ opacity: 0.5 }}
                          />
                        </IconButton>
                      </Tooltip>
                      <DateInput
                        label="Дата (от)"
                        name="date"
                        xs={12}
                        grid={false}
                        clearable={false}
                        sx={{ mx: 0.01 }}
                      />
                      <Tooltip title="Следующий месяц">
                        <IconButton
                          onClick={() =>
                            setValue(
                              'date',
                              dayjs(item.date)
                                .add(1, 'month')
                                .format('YYYY-MM-DD'),
                            )
                          }
                          size="small"
                        >
                          <ChevronRight
                            htmlColor={theme.palette.brand.main}
                            sx={{ opacity: 0.5 }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Предыдущий месяц">
                        <span>
                          <IconButton
                            disabled={!item.toDate}
                            onClick={() =>
                              setValue(
                                'toDate',
                                dayjs(item.toDate)
                                  .add(-1, 'month')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                            size="small"
                          >
                            <ChevronLeft
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <DateInput
                        label="Дата (до)"
                        name="toDate"
                        xs={12}
                        grid={false}
                        clearable={false}
                        sx={{
                          ml: 0.1,
                          display: { xs: 'none', md: 'flex' },
                        }}
                      />
                      <Tooltip title="Следующий месяц">
                        <span>
                          <IconButton
                            disabled={!item.toDate}
                            onClick={() =>
                              setValue(
                                'toDate',
                                dayjs(item.toDate)
                                  .add(1, 'month')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                            size="small"
                          >
                            <ChevronRight
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                />
              )}
            />
          </Box>
        </Form>
      </Box>

      <UserSalariesTable
        id="salaries-table"
        ref={tableRef}
        selectProps={{
          functionArgs: {
            from_date: selectedDate,
            to_date: selectedDateTo,
          },
          onFetch(items) {
            fetchedItemsRef.current = items;
            return items;
          },
        }}
        skeletonRowsCount={50}
        disableInitialization={!selectedDate}
        filterColumns={filterColumns}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по ФИО или ID',
          },
          filter: (search) => {
            return {
              _or: [
                ...search.flatMap((str) => [
                  ...(Number.isInteger(parseInt(str, 10)) && str.length < 7
                    ? [{ user: { id: { _eq: parseInt(str, 10) } } }]
                    : []),
                  {
                    user: { fullName: { _ilike: `%${str}%` } },
                  },
                ]),
              ],
            };
          },
        }}
      />
    </TablePageLayout>
  );
}
