import {
  ConfigurationContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Box, Link, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { AlertCircle, Star } from 'mdi-material-ui';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { NewTaskDialog } from 'components/NewTaskDialog';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export const TasksTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { locale } = useContext(ConfigurationContext);
    const { hasPermission } = useContext(UserContext);

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          { headerName: 'ID', field: 'id', width: 70 },
          {
            headerName: 'Дата',
            field: 'datetime',
            selector: 'date time datetime result',
            width: 170,
            renderCell({ row }) {
              const { date, time, datetime, result } = row as {
                date: string;
                time?: string;
                datetime: string;
                result?: string;
              };

              const dateString = new Date(date).toLocaleDateString();
              const datetimeDate = time && new Date(datetime);
              const datetimeString =
                datetimeDate &&
                `${datetimeDate.toLocaleDateString()} ${datetimeDate.toLocaleTimeString(
                  locale,
                  {
                    hour: '2-digit',
                    minute: '2-digit',
                  },
                )}`;

              const isOverdue = (() => {
                if (result) {
                  return false;
                }

                return time
                  ? new Date(datetime) < new Date()
                  : dayjs(date).startOf('day') < dayjs().startOf('day');
              })();

              return (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}
                >
                  <Box component="span" sx={{ width: time ? 120 : 75 }}>
                    {datetimeString || dateString}
                  </Box>
                  {isOverdue && (
                    <Tooltip title="Задача просрочена">
                      <AlertCircle
                        htmlColor="red"
                        sx={{ ml: 0.5, fontSize: '18px' }}
                      />
                    </Tooltip>
                  )}
                  {row.isImportant && (
                    <Tooltip title="Важная задача">
                      <Star
                        htmlColor="#FFCD3C"
                        sx={{ ml: !isOverdue ? 0.5 : 0, fontSize: '22px' }}
                      />
                    </Tooltip>
                  )}
                </Box>
              );
            },
            onSort: (sort) => [{ datetime: sort }],
          },
          {
            headerName: 'Клиент',
            field: 'dynamicClient',
            width: 150,
            type: 'relationship',
            selector: 'dynamicClient { data { *fullName* favouritePhone } }',
            renderCell({ value }) {
              return (
                <Box
                  sx={{
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {value || '—'}
                </Box>
              );
            },
          },
          {
            headerName: 'Задача',
            field: 'task',
            flex: 1,
            minWidth: 150,
          },
          {
            headerName: 'Результат',
            field: 'result',
            flex: 1,
            minWidth: 150,
            placeholder: false,
          },
          {
            headerName: 'Телефон',
            field: 'phone',
            type: 'phone',
            selector: false,
            valueGetter: ({ row }) => row.dynamicClient?.data.favouritePhone,
            onSort: (sort) => [
              { dynamicClient: { data: { favouritePhone: sort } } },
            ],
          },
          {
            headerName: 'Этап воронки',
            field: 'funnelStage',
            width: 100,
            type: 'relationship',
            selector: 'deal { funnelStage { *name* }}',
          },
          {
            headerName: 'Cделка',
            field: 'dealId',
            width: 100,
            renderCell({ value }) {
              return value ? (
                <Link href={`/deals/${value}`}>#{value}</Link>
              ) : (
                '—'
              );
            },
          },
          {
            headerName: 'Постановщик',
            field: 'creator',
            width: 110,
            type: 'relationship',
            selector: 'creator { *shortFullName* }',
          },
          {
            headerName: 'Исполнитель',
            field: 'user',
            width: 110,
            selector: 'user { *shortFullName* }',
            type: 'relationship',
          },
        ]),
      [locale, filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="task"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 3}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        getRowHeight={() => 'auto'}
        deletable={hasPermission(Permissions.TASKS_DELETE)}
        disableColumnPinning
        selectProps={{
          ...rest.selectProps,
          onSelection: (selections) => [...selections, 'isImportant'],
        }}
        getRowClassName={({ row }) => row.isImportant && 'important-task'}
        sx={{
          '.MuiDataGrid-row': {
            minHeight: '52px !important',
          },
          ...rest.sx,
        }}
        editable={{
          link: (row) => `/tasks/${row.id}`,
        }}
        components={{
          FormDialog: NewTaskDialog,
        }}
      />
    );
  },
);
