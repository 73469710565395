import {
  AttachmentsZone,
  NotificationsContextType,
  formatPhone,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import React from 'react';

import { AudioPlayer } from 'components/AudioPlayer';

import { DealTimelineItemProps } from './DealTimelineItem';

const extractCreator = (item: any) =>
  item.creator && {
    name: `${item.creator.secondName || ''} ${
      item.creator.firstName || ''
    }`.trim(),
    color: item.creator.avatarColor || 'black',
  };

export const dealLogs: Record<
  string,
  (
    item: any,
    { showPrompt }: { showPrompt: NotificationsContextType['showPrompt'] },
  ) => DealTimelineItemProps
> = {
  on_deal_created: ({ payload, ...item }: any) => {
    return {
      name: `Создана сделка #${item.id}`,
      date: item.createdAt,
      user: extractCreator(item),
      list: [
        ...(payload.funnelStageName
          ? [
              {
                name: 'Этап',
                value: `${payload.funnelStageName} (${payload.funnelName})`,
              },
            ]
          : []),
        ...(payload.buyerName
          ? [
              {
                name: 'Клиент',
                value: `${payload.buyerName}`,
                url: `/clients/${payload.buyerId}`,
              },
            ]
          : []),
        ...(payload.sellerName
          ? [
              {
                name: 'Продавец',
                value: `${payload.sellerName}`,
                url: `/clients/${payload.sellerId}`,
              },
            ]
          : []),
        ...(payload.estateAddress
          ? [
              {
                name: 'Объект',
                value: `${payload.estateAddress}`,
                url: `/estates/${payload.estateId}`,
              },
            ]
          : []),
        ...(payload.userShortFullName
          ? [
              {
                name: 'Эксперт',
                value: `${payload.userSecondName || ''} ${
                  payload.userFirstName || ''
                }`.trim(),
              },
            ]
          : []),
      ],
      nameColumnWidth: 110,
    };
  },
  ...Object.assign(
    {},
    ...(
      [
        {
          name: 'client',
          event: 'initialized',
        },
        {
          name: 'seller',
          event: 'initialized',
        },
        {
          name: 'client',
          event: 'changed',
        },
        {
          name: 'seller',
          event: 'changed',
        },
        {
          name: 'client',
          event: 'removed',
        },
        {
          name: 'seller',
          event: 'removed',
        },
      ] as const
    ).map((x) => {
      const key = `on_${x.name}_${x.event}`;
      const Name = x.name === 'client' ? 'Клиент' : 'Продавец';

      return {
        [key]: ({ payload, ...item }: any) => ({
          name:
            x.event === 'initialized'
              ? `${Name} #${payload.clientId} добавлен`
              : x.event === 'changed'
              ? `${Name} изменен`
              : `${Name} удален`,
          user: extractCreator(item),
          list: [
            ...(payload.clientName
              ? [
                  {
                    name: 'Имя',
                    value: `${payload.clientName}`,
                  },
                ]
              : []),
            ...(payload.clientPhone
              ? [
                  {
                    name: 'Телефон',
                    value: formatPhone(payload.clientPhone),
                  },
                ]
              : []),
          ],
          ...(x.event === 'initialized'
            ? {
                from: null,
                to: {
                  name: `Клиент #${payload.clientId}`,
                  url: `/clients/${payload.clientId}`,
                },
              }
            : x.event === 'changed'
            ? {
                from: {
                  name: `Клиент #${payload.oldClientId}`,
                  url: `/clients/${payload.oldClientId}`,
                },
                to: {
                  name: `Клиент #${payload.clientId}`,
                  url: `/clients/${payload.clientId}`,
                },
              }
            : {
                from: {
                  name: `Клиент #${payload.clientId}`,
                  url: `/clients/${payload.clientId}`,
                },
                to: null,
              }),
          fromToAlignment: 'start',
          nameColumnWidth: 100,
          icon:
            x.event === 'initialized'
              ? `add`
              : x.event === 'changed'
              ? `edit`
              : `delete`,
          color: x.event === 'removed' ? `#fff8f8` : null,
        }),
      };
    }),
  ),
  ...Object.assign(
    {},
    ...(
      [
        {
          name: 'estate',
          event: 'initialized',
        },
        {
          name: 'estate',
          event: 'changed',
        },
        {
          name: 'estate',
          event: 'removed',
        },
      ] as const
    ).map((x) => {
      const key = `on_${x.name}_${x.event}`;
      const Name = 'Объект';

      return {
        [key]: ({ payload, ...item }: any) => ({
          name:
            x.event === 'initialized'
              ? `${Name} #${payload.estateId} добавлен`
              : x.event === 'changed'
              ? `${Name} изменен`
              : `${Name} удален`,
          user: extractCreator(item),
          list: [
            ...(payload.estateAddress
              ? [
                  {
                    name: 'Адрес',
                    value: `${payload.estateAddress}`,
                  },
                ]
              : []),
            ...(payload.residentialComplexName
              ? [
                  {
                    name: 'ЖК',
                    value: `${payload.residentialComplexName}`,
                    url: `/residential-complexes/${payload.residentialComplexId}`,
                  },
                ]
              : []),
            ...(payload.estateActualPrice
              ? [
                  {
                    name: 'Стоимость',
                    value:
                      Number(payload.estateActualPrice).toLocaleString() + '₽',
                  },
                ]
              : []),
          ],
          ...(x.event === 'initialized'
            ? {
                from: null,
                to: {
                  name: `Объект #${payload.estateId}`,
                  url: `/estates/${payload.estateId}`,
                },
              }
            : x.event === 'changed'
            ? {
                from: {
                  name: `Объект #${payload.oldEstateId}`,
                  url: `/estates/${payload.oldEstateId}`,
                },
                to: {
                  name: `Объект #${payload.estateId}`,
                  url: `/estates/${payload.estateId}`,
                },
              }
            : {
                from: {
                  name: `Объект #${payload.estateId}`,
                  url: `/estates/${payload.estateId}`,
                },
                to: null,
              }),
          fromToAlignment: 'start',
          nameColumnWidth: 100,
          icon:
            x.event === 'initialized'
              ? `add`
              : x.event === 'changed'
              ? `edit`
              : `delete`,
          color: x.event === 'removed' ? `#fff8f8` : null,
        }),
      };
    }),
  ),
  ...Object.assign(
    {},
    ...(
      [
        {
          name: 'user',
          event: 'initialized',
        },
        {
          name: 'user',
          event: 'changed',
        },
        {
          name: 'user',
          event: 'removed',
        },
      ] as const
    ).map((x) => {
      const key = `on_${x.name}_${x.event}`;
      const Name = 'Эксперт';

      return {
        [key]: ({ payload, ...item }: any) => ({
          name:
            x.event === 'initialized'
              ? `${Name} #${payload.userId} добавлен`
              : x.event === 'changed'
              ? `${Name} изменен`
              : `${Name} удален`,
          user: extractCreator(item),
          list: [
            ...(payload.userShortFullName
              ? [
                  {
                    name: 'Имя',
                    value: `${payload.userSecondName || ''} ${
                      payload.userFirstName || ''
                    }`.trim(),
                  },
                ]
              : []),
          ],
          ...(x.event === 'initialized'
            ? {
                from: null,
                to: {
                  name: `Эксперт #${payload.userId}`,
                },
              }
            : x.event === 'changed'
            ? {
                from: {
                  name: `Эксперт #${payload.oldUserId}`,
                },
                to: {
                  name: `Эксперт #${payload.userId}`,
                },
              }
            : {
                from: {
                  name: `Эксперт #${payload.userId}`,
                },
                to: null,
              }),
          fromToAlignment: 'start',
          nameColumnWidth: 100,
          icon:
            x.event === 'initialized'
              ? `add`
              : x.event === 'changed'
              ? `edit`
              : `delete`,
          color: x.event === 'removed' ? `#fff8f8` : null,
        }),
      };
    }),
  ),
  ...Object.assign(
    {},
    ...(
      [
        {
          name: 'funnelStage',
          event: 'changed',
        },
      ] as const
    ).map((x) => {
      const key = `on_${x.name}_${x.event}`;
      const Name = 'Этап';

      return {
        [key]: ({ payload, ...item }: any) => ({
          name: x.event === 'changed' ? `${Name} изменен` : `${Name} удален`,
          user: extractCreator(item),
          list: [
            ...(payload.funnelName
              ? [
                  {
                    name: 'Воронка',
                    value: payload.funnelName,
                  },
                ]
              : []),
          ],
          ...(x.event === 'changed'
            ? {
                from: {
                  name: `${payload.oldFunnelStageName}`,
                },
                to: {
                  name: `${payload.funnelStageName}`,
                },
              }
            : {
                from: {
                  name: `${payload.funnelStageName}`,
                },
                to: null,
              }),
          fromToAlignment: 'start',
          nameColumnWidth: 100,
          icon: `edit`,
        }),
      };
    }),
  ),
  on_task_created: ({ payload, ...item }: any) => ({
    icon: 'add',
    name: `Создана задача #${payload.taskId}`,
    user: extractCreator(item),
    list: [
      ...(payload.taskText
        ? [
            {
              value: payload.taskText,
              url: `/tasks/${payload.taskId}`,
              valueSx: {
                fontStyle: 'italic',
                fontWeight: '400',
              },
            },
          ]
        : []),
    ],
    from: payload.creatorShortFullName
      ? {
          name: `${payload.creatorSecondName || ''} ${
            payload.creatorFirstName || ''
          }`.trim(),
        }
      : null,
    to: payload.userShortFullName
      ? {
          name: `${payload.userSecondName || ''} ${
            payload.userFirstName || ''
          }`.trim(),
        }
      : null,
    ...(item.task?.isFinished && {
      result: {
        text: `Задача закрыта`,
      },
    }),
    nameColumnWidth: 95,
  }),
  on_task_removed: ({ payload, ...item }: any) => ({
    name: `Задача #${payload.taskId} удалена`,
    user: extractCreator(item),
    list: [],
    color: '#fff8f8',
    icon: 'delete',
  }),
  on_task_finished: ({ payload, ...item }: any) => ({
    name: `Задача #${payload.taskId} завершена`,
    user: extractCreator(item),
    list: [
      {
        name: 'Результат',
        value: payload.taskResult,
        url: `/tasks/${payload.taskId}`,
        valueSx: {
          fontStyle: 'italic',
          fontWeight: '400',
        },
      },
    ],
    nameColumnWidth: 100,
    color: '#f4ffee',
    icon: 'complete',
  }),
  on_task_reopened: ({ payload, ...item }: any) => ({
    name: `Задача #${payload.taskId} переоткрыта`,
    user: extractCreator(item),
    list: [],
  }),
  on_task_moved: ({ payload, ...item }: any) => ({
    name: `Задача #${payload.taskId} перемещена`,
    user: extractCreator(item),
    list: [
      ...(payload.taskText
        ? [
            {
              value: payload.taskText,
              url: `/tasks/${payload.taskId}`,
              valueSx: {
                fontStyle: 'italic',
                fontWeight: '400',
              },
            },
          ]
        : []),
    ],
    from: payload.oldDealId
      ? {
          name: `Сделка #${payload.oldDealId}`,
          url: `/deals/${payload.oldDealId}`,
        }
      : null,
    to: payload.newDealId
      ? {
          name: `Сделка #${payload.newDealId}`,
          url: `/deals/${payload.newDealId}`,
        }
      : null,
    ...(item.task?.isFinished && {
      result: {
        text: `Задача закрыта`,
      },
    }),
  }),
  on_note_created: ({ payload, ...item }: any) => ({
    icon: 'add',
    name: `Комментарий добавлен`,
    user: extractCreator(item),
    list: [
      ...(payload.dealNoteText
        ? [
            {
              value: payload.dealNoteText,
              valueSx: {
                fontStyle: 'italic',
                fontWeight: '400',
              },
            },
          ]
        : []),
    ],
  }),
  on_note_removed: ({ payload, ...item }: any) => ({
    color: '#fff8f8',
    icon: 'delete',
    name: `Комментарий удален`,
    user: extractCreator(item),
    list: [
      ...(payload.dealNoteText
        ? [
            {
              value: payload.dealNoteText,
              valueSx: {
                fontStyle: 'italic',
                fontWeight: '400',
              },
            },
          ]
        : []),
    ],
  }),
  on_call_created: ({ payload, ...item }: any, { showPrompt }: any) => ({
    icon: 'add',
    name: `Добавлен звонок #${payload.callId}`,
    user: extractCreator(item),
    ...(payload.direction === 'in'
      ? {
          from: {
            name: `${payload.clientSecondName || ''} ${
              payload.clientFirstName || ''
            }`.trim(),
            url: `/clients/${payload.clientId}`,
          },
          to: {
            name: `${payload.userSecondName || ''} ${
              payload.userFirstName || ''
            }`.trim(),
          },
        }
      : {
          from: {
            name: `${payload.userSecondName || ''} ${
              payload.userFirstName || ''
            }`.trim(),
          },
          to: {
            name: `${payload.clientSecondName || ''} ${
              payload.clientFirstName || ''
            }`.trim(),
            url: `/clients/${payload.clientId}`,
          },
        }),
    fromToAlignment: 'start',
    nameColumnWidth: 100,
    ...(item.call && {
      content: (
        <Box sx={{ height: 30, mt: 2, mb: 1 }}>
          <AudioPlayer
            callId={payload.callId}
            size="small"
            url={item.call.recordLink}
            autoplay={false}
          />
        </Box>
      ),
      ...(item.call.extractedText && {
        action: {
          text: 'Расшифровка',
          async onClick() {
            await showPrompt({
              title: 'Расшифровка звонка',
              form: <Box>{item.call.extractedText}</Box>,
              cancel: ' ',
              accept: 'Закрыть',
              dialogProps: {
                maxWidth: 'md',
              },
            });
          },
        },
      }),
    }),
  }),
  on_file_created: ({ payload, ...item }: any) => ({
    icon: 'add',
    name: `Файл добавлен`,
    user: extractCreator(item),
    content: (
      <Box
        sx={{
          '.MuiFormControl-root > .MuiBox-root > .MuiBox-root': {
            border: 'none !important',
            p: 0,
            ml: -2,
            mb: -3,
            mt: -2,
          },
        }}
      >
        <AttachmentsZone
          value={[payload.file]}
          readOnly
          attachmentsTypes={[]}
          gridProps={{
            xl: 6,
            lg: 8,
            md: 12,
            sm: 12,
            xs: 12,
          }}
          {...({} as any)}
        />
      </Box>
    ),
  }),
  on_file_removed: ({ payload, ...item }: any) => ({
    icon: 'add',
    name: `Файл удален`,
    user: extractCreator(item),
    content: (
      <Box
        sx={{
          '.MuiFormControl-root > .MuiBox-root > .MuiBox-root': {
            border: 'none !important',
            p: 0,
            ml: -2,
          },
        }}
      >
        <AttachmentsZone
          value={[payload.file]}
          readOnly
          attachmentsTypes={[]}
          gridProps={{
            xl: 6,
            lg: 8,
            md: 12,
            sm: 12,
            xs: 12,
          }}
          {...({} as any)}
        />
      </Box>
    ),
  }),
  ...Object.assign(
    {},
    ...(
      [
        {
          name: 'dealPrice',
          label: 'Сумма сделки изменена',
          valueProcessor: (value?: number) =>
            (value || 0).toLocaleString() + '₽',
        },
      ] as const
    ).map((x) => {
      const key = `on_${x.name}_changed`;

      return {
        [key]: ({ payload, ...item }: any) => ({
          name: x.label,
          user: extractCreator(item),
          list: [
            {
              name: 'Старое',
              value: x.valueProcessor(payload.oldValue),
            },
            {
              name: 'Новое',
              value: x.valueProcessor(payload.newValue),
            },
          ],
          nameColumnWidth: 80,
        }),
      };
    }),
  ),
};

export const dealLogTypes = Object.entries(dealLogs).map(([key, value]) => {
  const data = value(
    { payload: {} },
    {
      showPrompt: (() => {}) as any,
    },
  );

  return {
    value: key,
    text: data.name.replace('#undefined', '').replace(/ +/g, ' '),
  };
});
