import {
  AutocompleteInput,
  ConfigurationContext,
  FormInput,
  HasuraQuery,
  NotificationsContext,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';

import { FunnelStageType } from 'constants/funnels';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';
import { DealBookingInputs } from 'pages/Deal/tabs/PriceTab/PriceTab';

export function useDealStageManager() {
  const { user, hasPermission } = useContext(UserContext);
  const { hasura } = useContext(ConfigurationContext);
  const { dealCancelReasons, nonTargetedLeadType } =
    useContext(SettingsContext);
  const { showAlert, showPrompt } = useContext(NotificationsContext);

  const validateDealStageChange = useCallback(
    async (
      dealId: number,
      sourceStageType: string,
      destinationStageType: string,
    ) => {
      if (
        ([FunnelStageType.FAILURE, FunnelStageType.WRONG].includes(
          sourceStageType,
        ) &&
          !hasPermission(Permissions.DEALS_CANCEL_FINAL_STAGE)) ||
        (sourceStageType === FunnelStageType.DEAL &&
          !hasPermission(Permissions.DEALS_CANCEL_FINAL_STAGE))
      ) {
        showAlert('Сделка находится на конечном этапе', 'error');
        return false;
      }

      if (
        [FunnelStageType.DIFFICULT_CALL_STAGE].includes(destinationStageType)
      ) {
        showAlert('На данный этап сделки попадают автоматически', 'error');
        return false;
      }

      if (destinationStageType === FunnelStageType.DEAL) {
        const requiredFields = [
          { name: 'dealDate', text: 'Дата сделки' },
          { name: 'sellerId', text: 'Клиент' },
          { name: 'clientId', text: 'Покупатель' },
          { name: 'estateId', text: 'Объект' },
          { name: 'userId', text: 'Эксперт' },
          { name: 'obligations', text: 'Обременения' },
          { name: 'contractNumber', text: 'Номер договора' },
          { name: 'paymentForm', text: 'Форма оплаты' },
          { name: 'dealPrice', text: 'Сумма сделки' },
          { name: 'dealContractPrice', text: 'Сумма сделки по договору' },
          { name: 'contractForm', text: 'Форма договора' },
        ];

        const [deal] = await hasura.request({
          source: 'deal',
          where: { id: { _eq: dealId } },
          limit: 1,
          selection: requiredFields.map((x) => x.name),
        });

        const missingFields = requiredFields
          .filter((x) => !['sellerId', 'clientId'].includes(x.name))
          .filter((x) => !deal[x.name]);

        if (!deal.sellerId && !deal.clientId) {
          missingFields.push({
            name: 'clientId',
            text: 'Клиент или покупатель',
          });
        }

        if (missingFields.length) {
          await showPrompt({
            title: 'Поля не заполнены',
            form: (
              <Box component="ul" sx={{ pt: 0, mt: 0 }}>
                {missingFields.map((x) => (
                  <Box component="li" key={x.name}>
                    {x.text}
                  </Box>
                ))}
              </Box>
            ),
            cancel: false,
            accept: 'ОК',
          });

          return false;
        }
      }

      let note = null;
      const updates: HasuraQuery[] = [];

      if (
        [FunnelStageType.FAILURE, FunnelStageType.WRONG].includes(
          destinationStageType,
        )
      ) {
        try {
          const result = await showPrompt({
            title: 'Требуется подтверждение',
            form: (
              <AutocompleteInput
                name="reason"
                label="Причина отказа"
                options={(destinationStageType === FunnelStageType.FAILURE
                  ? dealCancelReasons
                  : nonTargetedLeadType
                ).map((x) => ({ text: x, value: x }))}
                required
                inputProps={{ autoFocus: true, autoComplete: 'none' }}
              />
            ),
            accept: 'Подтвердить',
            cancel: 'Отмена',
          });

          if (!result) {
            throw new Error('cancel');
          }

          note = `Причина отказа: ${result.reason}`;
        } catch {
          showAlert('Перенос отменен');
          return false;
        }
      } else if ([FunnelStageType.BOOKING].includes(destinationStageType)) {
        try {
          const result = await showPrompt({
            title: 'Внесенный аванс',
            form: <>{DealBookingInputs}</>,
            accept: 'Подтвердить',
            cancel: 'Отмена',
          });

          if (!result) {
            throw new Error('cancel');
          }

          updates.push({
            type: 'mutation',
            action: 'update',
            source: 'deal',
            where: {
              id: { _eq: dealId },
            },
            set: {
              ...result,
              isPrepaymentDone: true,
            },
          });
        } catch {
          showAlert('Перенос отменен');
          return false;
        }
      } else {
        try {
          const result = await showPrompt({
            title: 'Требуется подтверждение',
            form: (
              <>
                <FormInput
                  multiline
                  rows={5}
                  required
                  name="comment"
                  label="Комментарий"
                />
              </>
            ),
            accept: 'Подтвердить',
            cancel: 'Отмена',
          });

          if (!result) {
            throw new Error('cancel');
          }

          note = `${result.comment}${
            result.dealDate
              ? `. Дата сделки: ${dayjs(result.dealDate).format('DD.MM.YYYY')}`
              : ''
          }`;
        } catch {
          showAlert('Перенос отменен');
          return false;
        }
      }

      if (note) {
        updates.push({
          type: 'mutation',
          action: 'insert',
          source: 'dealNote',
          items: [
            {
              dealId,
              text: note,
              userId: user.id,
              companyId: user.companyId,
            },
          ],
        });
      }

      return {
        updates: [
          ...(sourceStageType === FunnelStageType.DEAL
            ? [
                {
                  type: 'mutation',
                  action: 'update',
                  source: 'deal',
                  where: {
                    id: { _eq: dealId },
                  },
                  set: {
                    fixedPrices: null,
                    fixedSalaries: null,
                  },
                } as HasuraQuery,
              ]
            : []),
          ...updates,
        ],
      };
    },
    [user, dealCancelReasons, nonTargetedLeadType, showAlert, showPrompt],
  );

  return {
    validateDealStageChange,
  };
}
