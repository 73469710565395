import { InputAdornment, Tooltip } from '@mui/material';
import { FunctionVariant } from 'mdi-material-ui';
import React from 'react';

import { AmountInput } from 'shared/inputs';

export const computedFieldInputProps = (style?: any) => ({
  readOnly: true,
  disabled: false,
  ...(style ?? AmountInput),
  placeholder: '—',
  md: 3,
  lg: 3,
  sx: {},
  formFetcherValueResolver: null,
  formSubmitterValueResolver: null,
  InputProps: {
    ...(style ?? AmountInput).InputProps,
    startAdornment: (
      <Tooltip title="Вычисляемое значение">
        <InputAdornment position="start">
          <FunctionVariant />
        </InputAdornment>
      </Tooltip>
    ),
  },
});
