import {
  AutocompleteInput,
  FormInput,
  FormSetter,
  NotificationsContext,
  HasuraAutocompleteRef,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import Add from '@mui/icons-material/Add';
import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { TagsGroup } from 'components/TagsGroup';
import {
  Finishing,
  getSaleQueueName,
  HouseTypes,
  PaymentForms,
  RoomsNumbers,
  Types,
} from 'constants/estate';
import { SettingsContext } from 'contexts/SettingsContext';

export const EstatesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const { estateStatuses } = useContext(SettingsContext);
    const { showPrompt } = useContext(NotificationsContext);
    const tableRef = useRef<DataTableExRef>(null);
    const complexAutocompleteRef = useRef<HasuraAutocompleteRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          { headerName: 'ID', field: 'id', width: 60 },
          {
            headerName: 'ЖК',
            field: 'residentialComplex',
            type: 'relationship',
            minWidth: 140,
            flex: 1,
            selector: 'residentialComplex { *name* }',
          },
          {
            headerName: 'Кол. комнат',
            width: 80,
            field: 'roomsNumber',
            type: 'select',
            items: RoomsNumbers,
          },
          {
            headerName: 'Дата сдачи',
            field: 'saleQueue',
            width: 90,
            type: 'relationship',
            selector: 'saleQueue { *releaseYear* releaseQuarter }',
            valueGetter({ value }) {
              return (
                value &&
                getSaleQueueName(value.releaseQuarter, value.releaseYear, true)
              );
            },
            onSort(sort) {
              return [{ saleQueue: { releaseDate: sort } }];
            },
          },
          {
            headerName: 'Отделка',
            field: 'finishing',
            width: 110,
            type: 'select',
            items: Finishing,
          },
          {
            headerName: 'Рекламная стоимость',
            field: 'price',
            width: 120,
            selector: 'price { adsPrice }',
            valueGetter({ value }) {
              return value?.adsPrice && `${value?.adsPrice.toLocaleString()}₽`;
            },
            onSort(sort) {
              return [{ price: { adsPrice: sort } }];
            },
          },
          {
            headerName: 'Статус',
            field: 'status',
            type: 'select',
            items: estateStatuses,
            width: 70,
          },
          {
            headerName: 'Тип',
            field: 'type',
            type: 'select',
            items: Types,
            width: 70,
          },
          {
            field: 'tags',
            headerName: 'Теги',
            selector: 'tags { tagId }',
            sortable: false,
            width: 40,
            renderCell(params) {
              return <TagsGroup ids={params.value.map((x: any) => x.tagId)} />;
            },
          },
          {
            headerName: 'Клиент',
            field: 'client',
            width: 140,
            type: 'relationship',
            selector:
              'client { data { *shortFullName* } user { shortFullName isRemoved } }',
          },
          {
            headerName: 'Реферер',
            field: 'referrer',
            width: 110,
            selector: false,
            type: 'relationship',
            isRemovedGetter: (row) => row.client?.user?.isRemoved,
            valueGetter: ({ row }) => row.client?.user?.shortFullName,
            onSort: (sort) => [{ client: { user: { fullName: sort } } }],
          },
          {
            headerName: 'Эксперт',
            field: 'user',
            width: 110,
            selector: 'user { data { *shortFullName* } }',
            type: 'relationship',
          },

          {
            headerName: 'Актуальная стоимость',
            field: 'actualPrice',
            width: 120,
            selector: 'price { actualPrice }',
            hidden: true,
            valueGetter({ row }) {
              return (
                row.price?.actualPrice &&
                `${row.price?.actualPrice.toLocaleString()}₽`
              );
            },
            onSort(sort) {
              return [{ price: { actualPrice: sort } }];
            },
          },
          {
            headerName: 'Месяц покупки',
            width: 85,
            field: 'contractSigningDate',
            selector: 'dealsInfo { purchaseDeal { *dealDate* } }',
            hidden: true,
            valueGetter: ({ row }) =>
              row.dealsInfo?.purchaseDeal?.dealDate &&
              dayjs(row.dealsInfo?.purchaseDeal?.dealDate).format('MM/YYYY'),
          },
          {
            headerName: 'Застройщик',
            field: 'developer',
            width: 110,
            hidden: true,
          },
          {
            headerName: 'Тип дома',
            field: 'houseType',
            width: 110,
            type: 'select',
            items: HouseTypes,
            hidden: true,
          },
          {
            headerName: 'Форма оплаты',
            field: 'dealsInfo { purchaseDeal { *paymentForm* } }',
            width: 110,
            hidden: true,
            valueGetter({ row }) {
              return PaymentForms.find(
                (x) => x.value === row.dealsInfo?.purchaseDeal?.paymentForm,
              )?.text;
            },
          },
          {
            headerName: 'Стоимость покупки',
            field: 'price { *purchasePrice* }',
            width: 110,
            type: 'relationship',
            fetchRemoved: false,
            hidden: true,
            valueGetter({ row }) {
              return row.price?.purchasePrice?.toLocaleString();
            },
          },
          {
            headerName: 'Дата покупки',
            field: 'price { *purchaseDate* }',
            hidden: true,
            valueGetter({ row }) {
              return row.price?.purchaseDate;
            },
            width: 110,
            type: 'date',
            fetchRemoved: false,
          },
          {
            headerName: 'Название банка, в котором взята ипотека',
            field: 'dealsInfo { purchaseDeal { *mortgageBankName* } }',
            width: 110,
            type: 'relationship',
            hidden: true,
          },
          {
            headerName: 'Собственник',
            field: 'owner',
            selector:
              'owner: clients(where:{isOwner: {_eq:true} }, limit: 1) { client { shortFullName } }',
            valueGetter({ value }) {
              return value?.length ? value[0].client.shortFullName : null;
            },
            width: 110,
            sortable: false,
            hidden: true,
          },
        ]),
      [filterColumns, estateStatuses],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="estate"
        editPageUrl={rest.editPageUrl ?? '/estates'}
        columns={rest.columns || columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 3}
        sortBy={rest.sortBy ?? { field: 'id', sort: 'desc' }}
        persistStateMode="query"
        persistScrollBar={false}
        formTitle={() => 'Новый объект'}
        formDialogProps={{
          ...props.formDialogProps,
          formSubmitterProps: {
            ...props.formDialogProps?.formSubmitterProps,
            preSubmit({ clientId, ...item }) {
              return {
                ...item,
                ...(clientId && {
                  clients: {
                    data: [
                      {
                        clientId,
                      },
                    ],
                  },
                }),
              };
            },
          },
        }}
      >
        <AutocompleteInput
          name="clientId"
          label="Клиент"
          mode="hasura"
          source="client"
          fetchAll={false}
          selection="id fullName"
          itemText="fullName"
          disabled={!!props.formDialogProps?.formProps?.defaultValues.clientId}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <AutocompleteInput
            controlRef={complexAutocompleteRef}
            name="residentialComplexId"
            mode="hasura"
            source="residentialComplex"
            label="Жилой комплекс"
            selection="id name"
            itemText="name"
            required
          />
          <FormSetter
            render={(setValue) => (
              <IconButton
                onClick={async () => {
                  const values = await showPrompt({
                    title: 'Новый ЖК',
                    form: (
                      <>
                        <FormInput name="name" label="Название" required />
                        <FormInput
                          name="floorsNumberFrom"
                          label="Этажность (от)"
                          md={6}
                          type="number"
                          min={0}
                          max={100}
                        />
                        <FormInput
                          name="floorsNumberTo"
                          label="Этажность (до)"
                          md={6}
                          type="number"
                          min={0}
                          max={100}
                        />
                        <AutocompleteInput
                          name="developer"
                          label="Застройщик"
                          mode="hasura"
                          source="residentialComplex"
                          preset="suggestion"
                        />
                        <AutocompleteInput
                          name="houseType"
                          label="Тип дома"
                          options={HouseTypes}
                        />
                      </>
                    ),
                    accept: 'Создать',
                    width: 500,
                    formSubmitterProps: {
                      source: 'residentialComplex',
                      selection: ['id'],
                    },
                  });

                  if (values) {
                    complexAutocompleteRef.current?.refetch();
                    setValue('residentialComplexId', values.id);
                  }
                }}
              >
                <Add />
              </IconButton>
            )}
          />
        </Box>

        <AutocompleteInput
          name="status"
          label="Статус"
          options={estateStatuses}
          required
        />
        <AutocompleteInput
          name="roomsNumber"
          label="Кол. комнат"
          options={RoomsNumbers}
        />
      </DataTableEx>
    );
  },
);
