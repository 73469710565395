import React, { useContext } from 'react';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

import { ClientDeals } from './ClientDeals';
import { UserDeals } from './UserDeals';

export default function Deals() {
  const { hasPermission } = useContext(UserContext);

  if (hasPermission(Permissions.IS_CLIENT)) {
    return <ClientDeals />;
  }

  return <UserDeals />;
}
