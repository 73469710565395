import { createContext } from 'react';

import { Funnel, FunnelStage, Tag } from 'types/entities';

export type SettingsContextType = {
  leadSources: string[];
  dealCancelReasons: string[];
  nonTargetedLeadType: string[];
  sellingCommissionSumFunc: string;
  receivedCommissionSumFunc: string;
  sellerReceivedSumFunc: string;
  sellerCommissionSumFunc: string;
  partnerCommissionSumFunc: string;
  developerCommissionSumFunc: string;
  agencyCommissionSumFunc: string;
  expertCommissionSumFunc: string;
  totalIncomeFunc: string;
  taxFunc: string;
  yearlyIncomeFunc: string;
  adsPriceFunc: string;
  agencyCommissionValues: number[];
  expertCommissionValues: number[];
  minActualPriceCorrectionPercent: string;
  maxActualPriceCorrectionPercent: string;
  minMortgageActualPriceCorrectionPercent: string;
  maxMortgageActualPriceCorrectionPercent: string;
  // other settings
  tags: Tag[];
  funnels: Funnel[];
  funnelStages: FunnelStage[];
  estateStatuses: { text: string; value: string }[];
  dealClientRoles: { text: string; value: string }[];
  estateDefaultCommission: number;

  reload: () => Promise<void>;
};

export const SettingsContext = createContext<SettingsContextType>({} as any);
