import { Box, Tooltip } from '@mui/material';
import parseHtml from 'html-react-parser';
import React, { useContext } from 'react';

import { SettingsContext } from 'contexts/SettingsContext';

export function TagsGroup(props: {
  ids: number[];
  size?: 'medium' | 'small';
  placeholder?: string | null | false;
}) {
  const { ids, size, placeholder } = props;
  const { tags: allTags } = useContext(SettingsContext);

  const tags = ids
    .map((id) => allTags.find((x) => x.id === id)!)
    .filter((x) => !!x);

  if (!tags.length) {
    if (placeholder === false || placeholder === null) {
      return null;
    }

    return <span>{placeholder ?? '—'}</span>;
  }

  const iconWidth = (() => {
    if ((size ?? 'medium') === 'medium') {
      return 24;
    }

    return 18;
  })();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {tags.map((tag) => (
        <Tooltip key={tag.id} title={tag.name}>
          <Box
            component="span"
            sx={{
              display: 'flex',
              color: tag.color,
              mr: 0.5,
              '& svg': {
                width: `${iconWidth}px !important`,
                height: `${iconWidth}px !important`,
              },
            }}
          >
            {parseHtml(tag.icon)}
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}
