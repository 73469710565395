import {
  AutocompleteInput,
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

import { HouseTypes, getSaleQueueName } from 'constants/estate';

export function ResidentialComplexes() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'name', headerName: 'Название', flex: 1 },
      {
        field: 'developer',
        headerName: 'Застройщик',
        flex: 1,
      },
      {
        field: 'houseType',
        headerName: 'Тип дома',
        type: 'select',
        items: HouseTypes,
        flex: 1,
      },
      {
        field: 'floorsNumberFrom',
        headerName: 'Этажность',
        selector: 'floorsNumberFrom floorsNumberTo',
        valueGetter: ({ row: { floorsNumberFrom, floorsNumberTo } }) => {
          if (
            floorsNumberFrom &&
            floorsNumberTo &&
            floorsNumberFrom !== floorsNumberTo
          ) {
            return `${floorsNumberFrom}-${floorsNumberTo}`;
          }

          if (floorsNumberFrom || floorsNumberTo) {
            return `${floorsNumberFrom || floorsNumberTo}`;
          }

          return null;
        },
        onSort(sort) {
          return [{ floorsNumberFrom: sort }, { floorsNumberTo: sort }];
        },
      },
      {
        field: 'address',
        headerName: 'Адрес',
        flex: 2,
        selector: 'addresses (limit: 1) { address }',
        valueGetter: (params) => params.row.addresses[0]?.address,
        onSort(sort) {
          return [{ addressesAggregate: { min: { address: sort } } }];
        },
      },
      {
        field: 'saleQueue',
        headerName: 'Очередь сдачи',
        flex: 2,
        selector:
          'salesQueues(orderBy:{releaseDate:ASC}, limit: 1) { *releaseYear* releaseQuarter }',
        valueGetter({ row }) {
          return (
            row.salesQueues?.[0] &&
            getSaleQueueName(
              row.salesQueues[0].releaseQuarter,
              row.salesQueues[0].releaseYear,
              false,
            )
          );
        },
        sortable: false,
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Жилые комплексы"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTableEx
        id="residential-complexes-table"
        ref={tableRef}
        source="residentialComplex"
        columns={columns}
        editPageUrl="/residential-complexes"
        deletable
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по названию, адресу или ID',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              ...(Number.isInteger(parseInt(str, 10)) && str.length < 7
                ? [{ id: { _eq: parseInt(str, 10) } }]
                : []),
              { name: { _ilike: `%${str}%` } },
              { addresses: { address: { _ilike: `%${str}%` } } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Застройщик',
              field: 'developer',
              source: 'residentialComplex',
              preset: 'suggestion',
            },
            {
              type: 'autocomplete',
              name: 'Тип дома',
              field: 'houseType',
              options: HouseTypes,
            },
          ],
        }}
        formTitle={(isNew) =>
          isNew ? 'Новый жилой комплекс' : 'Редактирование жилого комплекса'
        }
        formDialogProps={{
          formSubmitterProps: {
            preSubmit: (item) => ({
              ...item,
              floorsNumberTo: item.floorsNumberTo || item.floorsNumberFrom,
            }),
          },
        }}
      >
        <FormInput name="name" label="Название" required />
        <FormInput
          name="floorsNumberFrom"
          label="Этажность (от)"
          md={6}
          type="number"
          min={0}
          max={100}
        />
        <FormInput
          name="floorsNumberTo"
          label="Этажность (до)"
          md={6}
          type="number"
          min={0}
          max={100}
        />
        <AutocompleteInput
          name="developer"
          label="Застройщик"
          mode="hasura"
          source="residentialComplex"
          preset="suggestion"
        />
        <AutocompleteInput
          name="houseType"
          label="Тип дома"
          options={HouseTypes}
        />
      </DataTableEx>
    </TablePageLayout>
  );
}
