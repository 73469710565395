import {
  AutocompleteInput,
  ConfigurationContext,
  FormGetter,
  HasuraSelector,
  formatPhone,
  NotificationsContext,
  useNavigate,
  OpenInNewInputAdorement,
  FormDialog,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Button, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NewClientDialog } from 'components/NewClientDialog';
import { TasksTable } from 'components/TasksTable';
import { UserContext } from 'contexts';

export function ActiveCallDialog(props: {
  open: boolean;
  onClose: () => void;
  callId: number;
  clientPhone: string;
  callDirection: 'in' | 'out';
}) {
  const { open, onClose, callId, clientPhone, callDirection } = props;
  const [isNewClientDialogOpened, setIsNewClientDialogOpened] = useState(false);
  const { user } = useContext(UserContext);
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const location = useLocation();
  const tableRef = useRef<DataTableExRef>(null);
  const isFirstRender = useRef(true);
  const resultComment = useRef('');
  const tasksTableFilterRef = useRef<any>(null);
  const isAddCommentDisabled = !comment.trim().length;

  const saveComment = async () => {
    setComment('');

    resultComment.current = !resultComment.current
      ? comment
      : `${resultComment.current}\n${comment}`;

    await hasura.request({
      type: 'mutation',
      source: 'call',
      action: 'update',
      where: { id: { _eq: callId } },
      set: { comment: resultComment.current },
    });

    showAlert('Сохранено', 'success');
  };

  const saveCommentAsTask = async (clientId: number) => {
    setComment('');

    const date = dayjs().add(1, 'day');

    await hasura.request({
      type: 'mutation',
      source: 'task',
      action: 'insertOne',
      item: {
        clientId,
        task: comment,
        userId: user.id,
        date: date.format('YYYY-MM-DD'),
        time: date.format('HH:mm'),
      },
    });

    tableRef.current?.reload();
    showAlert('Сохранено', 'success');
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <FormDialog
      source="call"
      entityId={callId}
      open={open}
      onClose={onClose}
      title={`${
        callDirection === 'in' ? 'Входящий' : 'Исходящий'
      } звонок от ${formatPhone(clientPhone)}`}
      maxWidth="md"
      components={{
        ActionButtons: (
          <Button variant="text" sx={{ ml: 'auto' }} onClick={() => onClose()}>
            Закрыть
          </Button>
        ),
      }}
    >
      <HasuraSelector
        name="clientByPhone"
        selection="clientsByPhone {clientId}"
        resolveValue={({ clientsByPhone }) => {
          return {
            clientByPhone: clientsByPhone?.[0] ?? null,
          };
        }}
      />

      <FormGetter
        names={['clientByPhone']}
        render={({ clientByPhone }) => {
          if (!tasksTableFilterRef.current) {
            tasksTableFilterRef.current = clientByPhone?.clientId && {
              clientId: { _eq: clientByPhone?.clientId },
            };
          }

          // eslint-disable-next-line no-nested-ternary
          return clientByPhone?.clientId ? (
            <Grid container xs={12}>
              <AutocompleteInput
                value={clientByPhone?.clientId}
                name="clientId"
                mode="hasura"
                source="client"
                label="Клиент"
                selection="id fullName"
                itemText="fullName"
                md={6}
                fetchAll={false}
                required
                readOnly
                inputProps={{
                  InputProps: {
                    startAdornment: (
                      <OpenInNewInputAdorement
                        field="clientId"
                        baseUrl="/clients"
                        entityId={clientByPhone?.clientId}
                      />
                    ),
                  },
                }}
              />
              <Grid xs="auto" md={6} />
              <Grid xs={12} md={6}>
                <TextField
                  value={comment}
                  multiline
                  minRows={5}
                  label="Комментарий"
                  fullWidth
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Button disabled={isAddCommentDisabled} onClick={saveComment}>
                  Сохранить как комментарий к звонку
                </Button>
                <Button
                  disabled={isAddCommentDisabled}
                  onClick={() => saveCommentAsTask(clientByPhone.clientId)}
                >
                  Сохранить как задачу
                </Button>
              </Grid>
              <Grid xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Задачи
                </Typography>
                <TasksTable
                  id="active-call-client-tasks-table"
                  ref={tableRef}
                  inline
                  autoHeight
                  selectProps={{
                    filter: tasksTableFilterRef.current,
                  }}
                  deletable={false}
                  filterColumns={(columns) =>
                    columns.filter(
                      (x) => !['dynamicClient', 'phone'].includes(x.field),
                    )
                  }
                  pageSizeOptions={[5, 25, 50]}
                />
              </Grid>
            </Grid>
          ) : clientByPhone === undefined ? null : (
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5" sx={{ textAlign: 'center', mt: 2 }}>
                Клиента нет в базе данных
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => {
                  setIsNewClientDialogOpened(true);
                }}
              >
                Создать клиента
              </Button>
            </Grid>
          );
        }}
      />

      <NewClientDialog
        open={isNewClientDialogOpened}
        onClose={() => setIsNewClientDialogOpened(false)}
        formProps={{
          defaultValues: {
            phone: clientPhone,
          },
        }}
        onSubmit={(item) => {
          navigate(`/clients/${item.id}`);
        }}
      />
    </FormDialog>
  );
}
