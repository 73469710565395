import {
  FormTabs,
  FormGetter,
  HasuraSelector,
  FormPageLayout,
} from '@kirz/mui-admin';
import { Box } from '@mui/material';
import {
  AccountGroupOutline,
  Text,
  PhoneMessageOutline,
  HomeVariantOutline,
  AccountMultipleOutline,
  FileDocumentOutline,
  AccountDetailsOutline,
  CardAccountPhoneOutline,
} from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import { TagsGroup } from 'components/TagsGroup';
import { FunnelStageType } from 'constants/funnels';

import {
  AdditionalContactsTab,
  AdditionalTab,
  CallsTab,
  DocumentsTab,
  EstatesTab,
  MainTab,
  ParticipantsTab,
} from './tabs';

export default function Client() {
  const routeParams = useParams() as Record<string, string>;
  const clientId = parseInt(routeParams.id, 10);

  const buildBadge = (source: string, filter: Record<string, any>) => {
    return {
      badge: {
        subscribe: {
          source,
          selection: 'aggregate {count}',
          extractCount: (data: any) => data.aggregate.count,
          aggregation: true,
          filter,
        },
        badgeProps: {
          sx: {
            '.MuiBadge-badge': { backgroundColor: 'rgba(255,255,255,0.75)' },
          },
        },
      },
    };
  };

  return (
    <FormPageLayout
      source="client"
      breadcrumbs={[
        { text: 'Клиенты', icon: AccountGroupOutline, href: '/clients' },
        (item) => ({
          text: `#${item.id} ${item.secondName || ''} ${item.firstName || ''} ${
            item.middleName || ''
          }`.trim(),
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['secondName', 'firstName', 'middleName']}
      defaultRoute="/clients"
      tagsSlot={
        <Box sx={{ ml: 1 }}>
          <FormGetter
            names={['systemTags', 'tags']}
            render={({ systemTags, tags }) =>
              systemTags &&
              tags && (
                <TagsGroup
                  ids={[...systemTags, ...tags]}
                  size="small"
                  placeholder={false}
                />
              )
            }
          />
        </Box>
      }
    >
      <HasuraSelector
        name="systemTags"
        selection="systemTags: tags(where: {tag: {systemType: {_isNull: false}}}) {tagId}"
        resolveValue={(item) => ({
          systemTags: item.systemTags.map((x: any) => x.tagId),
        })}
      />

      <FormTabs
        persistStateMode="query"
        tabs={[
          { id: 'main', label: 'Информация', icon: <Text /> },
          {
            id: 'estates',
            label: 'Объекты',
            icon: <HomeVariantOutline />,
            ...buildBadge('estate', {
              isRemoved: { _eq: false },
              _or: [
                {
                  price: {
                    purchaseDeal: {
                      isRemoved: { _eq: false },
                      _or: [
                        { clientId: { _eq: clientId } },
                        {
                          clients: {
                            type: { _eq: 'buyer' },
                            clientId: { _eq: clientId },
                            isRemoved: { _eq: false },
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  deals: {
                    _or: [
                      { sellerId: { _eq: clientId } },
                      {
                        clients: {
                          type: { _eq: 'seller' },
                          clientId: { _eq: clientId },
                          isRemoved: { _eq: false },
                        },
                      },
                    ],
                    funnelStage: { type: { _eq: FunnelStageType.DEAL } },
                  },
                },
              ],
            }),
          },
          {
            id: 'documents',
            label: 'Документы',
            icon: <FileDocumentOutline />,
            ...buildBadge('file', {
              clientId: { _eq: clientId },
            }),
          },
          {
            id: 'calls',
            label: 'Звонки',
            icon: <PhoneMessageOutline />,
            ...buildBadge('call', {
              clientByPhone: { clientId: { _eq: clientId } },
            }),
          },
          {
            id: 'other-contacts',
            label: 'Доп. контакты',
            icon: <CardAccountPhoneOutline />,
            ...buildBadge('clientRelationship', {
              _or: [
                { clientId: { _eq: clientId } },
                { relatedClientId: { _eq: clientId } },
              ],
            }),
          },
          {
            id: 'participants',
            label: 'Сотрудники',
            icon: <AccountMultipleOutline />,
            ...buildBadge('clientDynamicParticipant', {
              clientId: { _eq: clientId },
            }),
          },
          {
            id: 'additional',
            label: 'Доп. информация',
            icon: <AccountDetailsOutline />,
          },
        ]}
        tabsWrapperStyle={{
          mx: { xs: 0, md: 0 },
        }}
      >
        <MainTab clientId={clientId} />
        <EstatesTab clientId={clientId} />
        <DocumentsTab clientId={clientId} />
        <CallsTab clientId={clientId} />
        <AdditionalContactsTab clientId={clientId} />
        <ParticipantsTab clientId={clientId} />
        <AdditionalTab clientId={clientId} />
      </FormTabs>
    </FormPageLayout>
  );
}
