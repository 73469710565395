import {
  DataTable,
  AutocompleteInput,
  Form,
  FormElementRef,
  FormFetcher,
  FormSubmitter,
  SubmitButton,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button } from '@mui/material';
import { Star, StarOutline } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { EditFunnelDialog } from 'components/EditFunnelDialog';
import { FunnelTypes } from 'constants/funnels';
import { UserContext } from 'contexts';

export function Funnels() {
  const { user } = useContext(UserContext);
  const [isEditItemModalOpened, setIsEditItemModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const tableRef = useRef<DataTableRef>(null);
  const formRef = useRef<FormElementRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'date' },
      {
        field: 'isDefault',
        headerName: 'Основная?',
        type: 'icon-button',
        // eslint-disable-next-line react/no-unstable-nested-components
        icon: (row) =>
          !row.isDefault ? <StarOutline /> : <Star htmlColor="#FFCD3C" />,
        tooltip: (row) => !row.isDefault && 'Сделать основной',
        width: 100,
        onClick: async (row, { configuration: { hasura } }) => {
          if (row.isDefault) {
            return;
          }

          await hasura.request({
            type: 'custom',
            query: `mutation ToggleDefault ($id: Int!) {
            set: updateFunnel(where: { id: {_eq: $id} }, _set:{ isDefault: true }) {
              affected_rows
            }
            
            unset: updateFunnel(where: { id: {_neq: $id} }, _set:{ isDefault: false }) {
              affected_rows
            }
          }`,
            variables: { id: row.id },
          });

          await tableRef.current!.reload();
        },
      },
      {
        field: 'name',
        headerName: 'Название',
        minWidth: 200,
      },
      {
        field: 'dealCommission',
        headerName: 'Комиссия по сделке',
        valueFormatter({ value }) {
          return `${value}%`;
        },
      },
      {
        field: 'type',
        headerName: 'Тип',
        type: 'select',
        items: FunnelTypes,
      },
      {
        field: 'stages',
        headerName: 'Этапы',
        flex: 1,
        selector: 'stages (orderBy: { serialNumber: ASC }) { name }',
        valueGetter: (x) => x.value.map((y: any) => y.name).join(', '),
        sortable: false,
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Воронки"
      actionContent={
        <SubmitButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          formRef={formRef}
          activateOnDirty
        >
          Сохранить
        </SubmitButton>
      }
    >
      <FormSubmitter
        source="settings"
        entityId={{ companyId: { _eq: user.companyId } }}
      >
        <FormFetcher
          source="settings"
          entityId={{ companyId: { _eq: user.companyId } }}
        >
          <Form ref={formRef} sx={{ pt: 1 }}>
            <AutocompleteInput
              name="leadSources"
              label="Источники лидов"
              mode="memory"
              options={[]}
              multiple
              freeSolo
            />
            <AutocompleteInput
              name="dealCancelReasons"
              label="Причины отказа от сделки"
              mode="memory"
              options={[]}
              multiple
              freeSolo
            />
            <AutocompleteInput
              name="nonTargetedLeadType"
              label="Типы нецелевого лида"
              mode="memory"
              options={[]}
              multiple
              freeSolo
            />
          </Form>
        </FormFetcher>
      </FormSubmitter>
      <Box
        sx={{
          minHeight: 300,
          flexGrow: 1,
          width: '100%',
          mt: { xs: 2, md: 4, lg: 7 },
        }}
      >
        <DataTable
          id="funnels-table"
          ref={tableRef}
          mode="hasura"
          source="funnel"
          columns={columns}
          editable={{
            onEdit: (row) => {
              setSelectedItem(row);
              setIsEditItemModalOpened(true);
            },
          }}
          deletable={{
            isEnabled: (row) => !row.systemType,
          }}
          title={{
            actionButton: (
              <Button
                sx={{ ml: 'auto' }}
                startIcon={<AddIcon />}
                variant="outlined"
                size="small"
                onClick={() => {
                  setSelectedItem(null);
                  setIsEditItemModalOpened(true);
                }}
              >
                Добавить воронку
              </Button>
            ),
          }}
          sortBy={{ field: 'createdAt', sort: 'desc' }}
        />
      </Box>
      <EditFunnelDialog
        open={isEditItemModalOpened}
        onClose={() => setIsEditItemModalOpened(false)}
        onSubmit={tableRef.current?.reload}
        onStageAdded={tableRef.current?.reload}
        onStageDeleted={tableRef.current?.reload}
        onStageSortChanged={tableRef.current?.reload}
        entityId={selectedItem?.id}
      />
    </TablePageLayout>
  );
}
