import {
  AutocompleteInput,
  ConfigurationContext,
  FormTab,
  NotificationsContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, { useContext, useMemo, useRef } from 'react';

export default function ParticipantsTab(props: { clientId: number }) {
  const { clientId } = props;
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);

  const additionalClientsRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'userId',
        headerName: 'ID',
        width: 70,
        valueGetter: ({ row }) => row.user?.id,
      },
      {
        field: 'fullName',
        headerName: 'ФИО',
        flex: 1,
        valueGetter: ({ row }) => row.user?.fullName,
        onSort: (sort) => [{ user: { fullName: sort } }],
      },
      {
        field: 'phone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
        valueGetter: ({ row }) => row.user?.phone,
        onSort: (sort) => [{ user: { phone: sort } }],
      },
      {
        field: 'email',
        headerName: 'E-mail',
        type: 'email',
        flex: 1,
        valueGetter: ({ row }) => row.user?.email,
        onSort: (sort) => [{ user: { email: sort } }],
      },
      {
        field: 'role',
        headerName: 'Роль',
        flex: 1,
        type: 'relationship',
        isRemovedGetter(row) {
          return row.user?.role?.isRemoved;
        },
        valueGetter: ({ row }) => row.user?.role?.name,
        onSort: (sort) => [{ user: { role: { name: sort } } }],
      },
    ],
    [],
  );

  return (
    <FormTab
      tab="participants"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex' }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <DataTableEx
            id="client-participants-table"
            ref={additionalClientsRef}
            source="clientParticipant"
            columns={columns}
            selectProps={{
              source: 'clientDynamicParticipant',
              filter: { clientId: { _eq: clientId } },
              onSelection: () => [
                'id: participantId user { id fullName phone email role { name isRemoved } }',
              ],
            }}
            deletable={{
              isEnabled: (row) => !!row.id,
            }}
            disableRemovedFilter
            getRowId={(row) => row.id || Math.floor(Math.random() * 100)}
            formTitle={() => 'Добавить cотрудника'}
            skeletonRowsCount={1}
            sx={{ mx: -2, borderColor: 'transparent' }}
            title="Сотрудники"
            editable={false}
            sortBy={{ field: 'fullName', sort: 'asc' }}
            formDialogProps={{
              formSubmitterProps: {
                preSubmit: async ({ userId, ...other }) => {
                  const [existingItem] = await hasura.request({
                    source: 'clientDynamicParticipant',
                    selection: '__typename',
                    limit: 1,
                    where: {
                      userId: { _eq: userId },
                      clientId: { _eq: clientId },
                    },
                  });

                  if (existingItem) {
                    showAlert('Сотрудник уже существует', 'error');
                    return null;
                  }

                  return { ...other, userId, clientId };
                },
              },
            }}
          >
            <AutocompleteInput
              mode="hasura"
              source="user"
              selection="id fullName"
              itemText={(x) => `#${x.id} ${x.fullName}`}
              name="userId"
              label="Пользователь"
              required
            />
          </DataTableEx>
        )
      }
    </FormTab>
  );
}
