import { colorToHex } from '@kirz/mui-admin';
import { Avatar, Box, Tooltip, useTheme } from '@mui/material';
import type { Property } from 'csstype';
import { RobotOutline } from 'mdi-material-ui';
import React from 'react';

type Props = {
  fullName: string;
  color: Property.Color;
  tooltip?: string | false;
  size?: number;
  fontSize?: string;
};

const getFirstChars = (fullName: string) => {
  const [firstName, lastName] = fullName.split(' ').filter((x) => !!x.length);

  return `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`
    .toUpperCase()
    .trim();
};

export function UserAvatar({
  fullName,
  color,
  size = 40,
  tooltip,
  fontSize,
}: Props) {
  const theme = useTheme();

  const bgcolor = colorToHex(color);

  const content =
    fullName === 'bot' ? (
      <Box
        sx={{
          bgcolor,
          color: theme.palette.getContrastText(bgcolor),
          width: size,
          height: size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100%',
          border: 'thin solid rgb(230, 232, 240)',
        }}
      >
        <RobotOutline
          sx={{
            fontSize: '16px',
            mt: '-2px',
          }}
        />
      </Box>
    ) : (
      <Avatar
        sx={{
          bgcolor,
          color: theme.palette.getContrastText(bgcolor),
          width: size,
          height: size,
          fontSize: fontSize || '1.1rem',
          fontWeight: 'normal',
        }}
      >
        {getFirstChars(fullName)}
      </Avatar>
    );

  if (tooltip === false) {
    return content;
  }

  return <Tooltip title={tooltip ?? fullName}>{content}</Tooltip>;
}
