import { Box, Link, SxProps, Typography } from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import React from 'react';

type Props = {
  title: string;
  tab?: string;
  dealId?: number;
  sx?: SxProps;
};

export function TabTitle({ title, tab, dealId, sx }: Props) {
  return (
    <Box sx={[{ display: 'flex', mb: 2, alignItems: 'center' }, sx as any]}>
      <Typography variant="subtitle1">{title}</Typography>
      {dealId && (
        <Link href={`/deals/${dealId}?t=${tab}`} sx={{ pb: '2px' }}>
          <Typography variant="caption" sx={{ ml: 2 }} color="#121828">
            <OpenInNew sx={{ fontSize: 12, mr: 0.5, mb: '-2px' }} />
            Сделка #{dealId}
          </Typography>
        </Link>
      )}
    </Box>
  );
}
