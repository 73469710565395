import {
  FormGetter,
  FormInput,
  FormInputProps,
  FormSetter,
  HiddenInput,
} from '@kirz/mui-admin';
import { Edit } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { Lock } from 'mdi-material-ui';
import React from 'react';

import { AmountInput } from 'shared/inputs';

export function ManualWithComputedField({
  name,
  computedField,
  InputProps,
  selectors,
  ...props
}: FormInputProps<any> & {
  computedField: string | ((item: any) => any);
  selectors?: string[];
}) {
  return (
    <>
      <HiddenInput name={name} />
      <FormSetter
        render={(setValue) => (
          <FormGetter
            names={[
              name,
              ...(typeof computedField === 'string' ? [computedField] : []),
              ...(selectors ?? []),
            ]}
            render={(x) => {
              return (
                <>
                  <FormInput
                    name={name}
                    readOnly={x[name] == null}
                    disabled={false}
                    clearable={false}
                    {...(x[name] == null && {
                      value:
                        typeof computedField === 'string'
                          ? x[computedField]
                          : computedField(x),
                    })}
                    InputProps={{
                      ...AmountInput.InputProps,
                      startAdornment: (
                        <Tooltip
                          title={
                            x[name] == null
                              ? 'Ввести вручную'
                              : 'Рассчитать автоматически'
                          }
                        >
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => {
                                const setValueProps = {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                  shouldTouch: true,
                                };

                                if (x[name] == null) {
                                  setValue(name, 0, setValueProps);
                                  return;
                                }

                                setValue(name, null, setValueProps);
                                setTimeout(() => {
                                  setValue(name, null, setValueProps);
                                });
                              }}
                            >
                              {x[name] == null ? <Lock /> : <Edit />}
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      ),
                      ...InputProps,
                    }}
                    {...props}
                  />
                </>
              );
            }}
          />
        )}
      />
    </>
  );
}
