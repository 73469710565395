import {
  AutocompleteInput,
  ConfigurationContext,
  FormTab,
  NotificationsContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
  FormGetter,
  CheckboxInput,
  ConditionalInput,
  SelectInput,
  HasuraSelector,
  FormInput,
} from '@kirz/mui-admin';
import { Add, InfoOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Tooltip } from '@mui/material';
import { Close } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { RoleCommissionType } from 'constants/other';

export default function ParticipantsTab(props: { dealId: number }) {
  const { dealId } = props;
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'userId',
        headerName: 'ID',
        width: 70,
        valueGetter: ({ row }) => row.user?.id,
      },
      {
        field: 'fullName',
        headerName: 'ФИО',
        flex: 1,
        valueGetter: ({ row }) => row.user?.fullName,
        onSort: (sort) => [{ user: { fullName: sort } }],
      },
      {
        field: 'phone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
        valueGetter: ({ row }) => row.user?.phone,
        onSort: (sort) => [{ user: { phone: sort } }],
      },
      {
        field: 'role',
        headerName: 'Роль',
        flex: 1,
        valueGetter: ({ row }) => row.user?.role?.name,
        onSort: (sort) => [{ user: { role: { name: sort } } }],
      },
      {
        field: 'commission',
        headerName: 'Комиссия',
        sortable: false,
        flex: 1,
        renderCell({ value, row }) {
          if (!row.isSalaryActive) {
            return <Close sx={{ opacity: 0.5 }} fontSize="small" />;
          }

          if (value == null) {
            return '–';
          }

          return `${value.toLocaleString()}₽`;
        },
      },
    ],
    [],
  );

  return (
    <FormTab
      tab="participants"
      grid={false}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {(isVisible) => (
        <>
          <HasuraSelector selection="fixedSalaries" />
          {!isVisible ? null : (
            <>
              <FormGetter
                names={['fixedSalaries']}
                render={({
                  fixedSalaries,
                }: {
                  fixedSalaries: Record<
                    string,
                    {
                      dealParticipantId: number;
                      commission: number;
                      isSalaryActive: boolean;
                    }
                  > | null;
                }) => {
                  const isFixed = !!fixedSalaries;
                  return (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mr: !isFixed ? 1 : 0,
                          mb: 2,
                        }}
                      >
                        {isFixed ? (
                          <Alert color="info" sx={{ mr: 0 }}>
                            Сделка завершена, сотрудники и их комиссии
                            зафиксированы и недоступны для редактирования
                          </Alert>
                        ) : (
                          <Button
                            sx={{ ml: 'auto', flexShrink: 0 }}
                            startIcon={<Add />}
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              tableRef.current?.openFormDialog();
                            }}
                          >
                            Добавить
                          </Button>
                        )}
                      </Box>
                      <DataTableEx
                        id="deal-participants-table"
                        ref={tableRef}
                        source="dealParticipant"
                        columns={
                          isFixed
                            ? columns.map((x) =>
                                x.field === 'commission'
                                  ? {
                                      ...x,
                                      renderCell({ row }) {
                                        const value = fixedSalaries[row.id];

                                        if (value?.commission == null) {
                                          return '–';
                                        }

                                        return `${value.commission.toLocaleString()}₽`;
                                      },
                                    }
                                  : x,
                              )
                            : columns
                        }
                        getRowId={(row) =>
                          row.id || Math.floor(Math.random() * 100)
                        }
                        selectProps={{
                          source: 'dealParticipant',
                          filter: { dealId: { _eq: dealId } },
                          onSelection: () => [
                            'id isSalaryActive commission systemType deal { funnelId } user { id fullName phone email role { name commissions { type value funnelId values { name description value type } } } } selectedCommissionValue { name description value type }',
                          ],
                        }}
                        deletable={{
                          isEnabled: (row) => !isFixed && !row.systemType,
                        }}
                        {...(isFixed && {
                          editable: false,
                        })}
                        formTitle={(isNew) =>
                          isNew
                            ? 'Добавить cотрудника'
                            : 'Редактировать сотрудника'
                        }
                        skeletonRowsCount={1}
                        sx={{ mb: -2 }}
                        disableRemovedFilter
                        sortBy={{ field: 'fullName', sort: 'asc' }}
                        formDialogProps={{
                          formProps: {
                            shouldUnregister: false,
                          },
                          formFetcherProps: {
                            onSelection(selections) {
                              return [
                                ...selections,
                                'deal { funnelId } user { id fullName phone email role { name commissions { type value funnelId values { id name description value type } } } } roleCommissionValueId',
                              ];
                            },
                          },
                          formSubmitterProps: {
                            preSubmit: async ({
                              _new,
                              userId,
                              isSalaryActive,
                              roleCommissionValueId,
                              customCommission,
                            }) => {
                              if (_new) {
                                const [existingItem] = await hasura.request({
                                  source: 'dealParticipant',
                                  selection: '__typename',
                                  limit: 1,
                                  where: {
                                    userId: { _eq: userId },
                                    dealId: { _eq: dealId },
                                  },
                                });

                                if (existingItem) {
                                  showAlert(
                                    'Сотрудник уже существует',
                                    'error',
                                  );
                                  return null;
                                }

                                return { userId, dealId };
                              }

                              return {
                                isSalaryActive,
                                roleCommissionValueId,
                                customCommission,
                              };
                            },
                          },
                        }}
                      >
                        <ConditionalInput
                          deps={['id']}
                          variants={[
                            {
                              if: (y) => !y.id,
                              input: (y) => (
                                <AutocompleteInput
                                  mode="hasura"
                                  source="user"
                                  selection="id fullName"
                                  itemText={(x) => `#${x.id} ${x.fullName}`}
                                  name="userId"
                                  label="Пользователь"
                                  required
                                />
                              ),
                            },
                            {
                              if: (y) => !!y.id,
                              input: (y) => (
                                <CheckboxInput
                                  name="isSalaryActive"
                                  label="Учитывать в расчете зарплаты?"
                                />
                              ),
                            },
                          ]}
                        />
                        <ConditionalInput
                          deps={['id']}
                          variants={[
                            {
                              if: (y) => !!y.id,
                              input: (y) => (
                                <FormInput
                                  name="customCommission"
                                  label="Комиссия (произвольное значение)"
                                  type="number"
                                />
                              ),
                            },
                          ]}
                        />
                        <FormGetter
                          names={[
                            'id',
                            'user',
                            'deal',
                            'roleCommissionValueId',
                          ]}
                          render={(y: {
                            id: number | null;
                            deal: { funnelId: number } | null;
                            user: {
                              role: {
                                commissions: {
                                  type: string;
                                  funnelId: number;
                                  values: {
                                    id: number;
                                    name: string | null;
                                    description: string | null;
                                    value: number;
                                    type: string;
                                  }[];
                                }[];
                              } | null;
                            } | null;
                            selectedCommissionValue: {
                              id: number;
                              name: string | null;
                              description: string | null;
                              value: number;
                              type: string;
                            } | null;
                          }) => {
                            if (!y.id || !y?.user?.role || !y.deal) {
                              return null;
                            }

                            const commission = y.user.role.commissions.find(
                              (x: any) => x.funnelId === y.deal!.funnelId,
                            );

                            if (
                              !commission ||
                              commission.type !== RoleCommissionType.MULTIPLE
                            ) {
                              return null;
                            }

                            return (
                              <SelectInput
                                name="roleCommissionValueId"
                                items={commission.values.map((x) => ({
                                  value: x.id,
                                  children: (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                      }}
                                    >
                                      {x.name
                                        ? `${x.name} [${x.value}${
                                            x.type === RoleCommissionType.FIXED
                                              ? '₽'
                                              : '%'
                                          }]`
                                        : `${x.value}${
                                            x.type === RoleCommissionType.FIXED
                                              ? '₽'
                                              : '%'
                                          }`}
                                      {x.description && (
                                        <Tooltip title={x.description}>
                                          <InfoOutlined
                                            sx={{
                                              ml: 'auto',
                                              cursor: 'pointer',
                                            }}
                                            color="info"
                                          />
                                        </Tooltip>
                                      )}
                                    </Box>
                                  ),
                                }))}
                                label="Комиссия"
                              />
                            );
                          }}
                        />
                      </DataTableEx>
                    </>
                  );
                }}
              />
            </>
          )}
        </>
      )}
    </FormTab>
  );
}
