import { BasePageLayout, Form, FormGetter, FormSetter } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';

import DealsFilter from 'components/DealsBoard/DealsFilter';
import DealsReportTable from 'components/DealsBoard/DealsReport';
import { DealsTable } from 'components/DealsBoard/DealsTable';
import { FunnelStageType, FunnelStageTypes } from 'constants/funnels';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export function DealsList() {
  const [displayMode, setDisplayMode] = useState<'table' | 'report'>('table');
  const [, setStagesFilter] = useState<Record<string, any>>();
  const [dealsFilter, setDealsFilter] = useState<Record<string, any> | null>(
    null,
  );
  const { hasPermission } = useContext(UserContext);

  const filtersControlRef = useRef<any>();

  return (
    <BasePageLayout
      title="Сделки"
      actionContent={
        <>
          <Form
            sx={{ width: { xs: 200, md: 500 } }}
            defaultValues={{
              displayMode: 'table',
            }}
            grid={false}
            dense
            persistStateMode={{
              type: 'query',
              queryPrefix: 'dm_',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormSetter
                render={(setValue) => (
                  <FormGetter
                    onChange={(item) => {
                      setDisplayMode(item.displayMode);
                    }}
                    render={(item) => (
                      <RadioGroup
                        name="displayMode"
                        value={item.displayMode}
                        onChange={(x) => {
                          setValue('displayMode', x.target.value);
                          setDisplayMode(x.target.value as any);
                        }}
                        row
                        sx={{ ml: 3, mt: 0.5 }}
                      >
                        <FormControlLabel
                          value="table"
                          control={<Radio />}
                          label="Таблица"
                        />
                        {hasPermission(Permissions.DEALS_REPORT_VIEW) && (
                          <FormControlLabel
                            value="report"
                            control={<Radio />}
                            label="Отчет"
                          />
                        )}
                      </RadioGroup>
                    )}
                  />
                )}
              />
            </Box>
          </Form>

          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              filtersControlRef.current?.openNewDealDialog();
            }}
          >
            Добавить
          </Button>
        </>
      }
    >
      <Box sx={{ ml: 2, mt: 1 }}>
        <DealsFilter
          mode="funnel"
          controlRef={filtersControlRef}
          hideFunnelFilter
          onStagesFilterChange={(value) => {
            setStagesFilter(value);
          }}
          onDealsFilterChange={(value) => {
            setDealsFilter(value);
          }}
          additionalFilters={useMemo(
            () => [
              {
                type: 'autocomplete',
                options: [
                  { value: '_in_work_', text: 'В работе' },
                  ...FunnelStageTypes,
                ],
                name: 'Тип этапа воронки',
                field: 'funnelStageType',
                filter: (value) => ({
                  ...(value !== '_in_work_'
                    ? { funnelStage: { type: { _eq: value } } }
                    : {
                        funnelStage: {
                          type: {
                            _nin: [
                              FunnelStageType.FAILURE,
                              FunnelStageType.WRONG,
                              FunnelStageType.DEAL,
                            ],
                          },
                        },
                      }),
                }),
              },
            ],
            [],
          )}
        />
      </Box>
      {dealsFilter && (
        <>
          {displayMode === 'table' ? (
            <DealsTable
              id="all-deals-table"
              selectProps={{
                filter: dealsFilter,
              }}
            />
          ) : (
            <DealsReportTable dealsFilter={dealsFilter} />
          )}
        </>
      )}
    </BasePageLayout>
  );
}
