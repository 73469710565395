import {
  AutocompleteInput,
  CheckboxInput,
  FormInput,
  FormTab,
} from '@kirz/mui-admin';
import React, { useContext } from 'react';

import { Finishing, RoomsNumbers } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export default function ApartmentTab() {
  const { hasPermission } = useContext(UserContext);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  return (
    <FormTab tab="flat">
      <AutocompleteInput
        name="roomsNumber"
        label="Кол. комнат"
        options={RoomsNumbers}
        md={3}
      />
      <AutocompleteInput
        name="finishing"
        label="Отделка"
        options={Finishing}
        md={3}
      />
      <FormInput name="floor" label="Этаж" type="number" md={2} />
      <FormInput
        name="ceilingHeight"
        label="Высота потолка"
        type="number"
        md={2}
      />
      <FormInput name="totalArea" label="Общая площадь" type="number" md={2} />
      <FormInput
        name="kitchenArea"
        label="Площадь кухни"
        type="number"
        md={3}
      />
      <FormInput name="liveArea" label="Жилая площадь" type="number" md={3} />
      <AutocompleteInput
        name="corridorAreas"
        label="Площадь коридоров"
        mode="memory"
        options={[]}
        multiple
        freeSolo
        md={3}
        inputProps={{ type: 'number', inputProps: { min: 0 } }}
      />
      <AutocompleteInput
        name="hallAreas"
        label="Площадь прихожей"
        mode="memory"
        options={[]}
        multiple
        freeSolo
        md={3}
        inputProps={{ type: 'number', inputProps: { min: 0 } }}
      />
      <AutocompleteInput
        name="roomAreas"
        label="Площадь комнат"
        mode="memory"
        options={[]}
        multiple
        freeSolo
        md={6}
        inputProps={{ type: 'number', inputProps: { min: 0 } }}
      />
      <AutocompleteInput
        name="balconyAreas"
        label="Площадь балконов"
        mode="memory"
        options={[]}
        multiple
        freeSolo
        md={6}
        inputProps={{ type: 'number', inputProps: { min: 0 } }}
      />
      <FormInput
        name="cadastralNumber"
        label="Кадастровый номер"
        sm={6}
        md={4}
      />
      <FormInput name="apartmentNumber" label="Номер квартиры" sm={6} md={4} />
      <FormInput name="buildingNumber" label="Корпус" sm={6} md={4} />
      <FormInput name="section" label="Секция" sm={6} md={4} />
      {!isClient && (
        <>
          <AutocompleteInput
            name="siteParameterAsterisk"
            label={`Параметр "*" для сайта`}
            mode="memory"
            options={[
              { value: 'Э', text: 'Э' },
              { value: 'Д', text: 'Д' },
              { value: 'П', text: 'П' },
            ]}
            sm={6}
            md={4}
          />
          <FormInput
            name="sitePrice"
            label="Цена на сайте"
            type="number"
            sm={6}
            md={4}
          />
        </>
      )}

      <CheckboxInput name="isApartment" label="Апартаменты?" sm={6} md={4} />
      {!isClient && (
        <>
          <FormInput
            name="siteDescription"
            label="Описание для сайта"
            multiline
            rows={3}
            md={8}
          />
        </>
      )}
    </FormTab>
  );
}
