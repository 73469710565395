import {
  FormGetter,
  FormInput,
  FormInputProps,
  FormSetter,
} from '@kirz/mui-admin';
import React from 'react';

import { ComputedField } from 'components/ComputedField';
import { AmountInput, ComputedFieldAdornment } from 'shared/inputs';

export type CommissionComputedFieldProps = FormInputProps<any>;

export function CommissionComputedField({
  name,
  InputProps,
  ...props
}: CommissionComputedFieldProps) {
  return (
    <>
      <FormSetter
        render={(setValue) => (
          <FormGetter
            names={[name, `${name}Fixed`, 'dealPrice']}
            onChange={(x) => {
              if (!x.dealPrice) {
                return null;
              }

              setValue(
                `${name}Sum`,
                x.dealPrice == null
                  ? null
                  : Math.floor(
                      (x.dealPrice * x[name]) / 100 + x[`${name}Fixed`],
                    ),
              );
            }}
          />
        )}
      />

      <ComputedField
        name={`${name}Sum`}
        readOnly
        disabled={false}
        {...AmountInput}
        placeholder="—"
        md={4}
        lg={3}
        sx={{}}
        InputProps={{
          ...AmountInput.InputProps,
          startAdornment: <ComputedFieldAdornment />,
          ...InputProps,
        }}
        {...props}
      />
    </>
  );
}
