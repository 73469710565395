import { FormGetter, FormInput } from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext } from 'react';

import { ComputedField } from 'components/ComputedField';
import { RoleGuard } from 'components/RoleGuard';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { AmountInput, PercentInput } from 'shared/inputs';

import { computedInputProps } from './shared/computedInputProps';

type PriceTabProfitSectionProps = {
  estateId: number;
};

export default function PriceTabProfitSection({
  estateId,
}: PriceTabProfitSectionProps) {
  const { hasPermission } = useContext(UserContext);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  return (
    <FormGetter
      names={['isSeller']}
      render={({ isSeller }) => {
        return (
          <Grid container>
            <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
              <FormGetter
                names={['adsPrice']}
                render={(x) => (
                  <FormInput
                    label="Сумма сделки"
                    name="estimatedDealPrice"
                    type="number"
                    md={3}
                    lg={3}
                    xl={2}
                    {...AmountInput}
                    placeholder={(x.adsPrice || 0).toLocaleString()}
                  />
                )}
              />
              <FormGetter
                names={['adsPrice', 'estimatedDealPrice']}
                render={(x) => (
                  <FormInput
                    label="Сумма по договору"
                    name="contractPrice"
                    type="number"
                    md={3}
                    lg={3}
                    xl={2}
                    {...AmountInput}
                    placeholder={(
                      x.estimatedDealPrice ||
                      x.adsPrice ||
                      0
                    ).toLocaleString()}
                  />
                )}
              />
              <FormGetter
                names={['defaultCommission']}
                render={(x) => (
                  <FormInput
                    label="Комиссия от продавца"
                    name="sellerCommission"
                    type="number"
                    md={3}
                    lg={3}
                    xl={2}
                    {...AmountInput}
                    {...(x.defaultCommission && {
                      placeholder: x.defaultCommission.toLocaleString(),
                      InputLabelProps: { shrink: true },
                    })}
                  />
                )}
              />

              <ComputedField {...computedInputProps('tax', 'Налог')} />
            </RoleGuard>
            {(!isClient || isSeller) && (
              <>
                <ComputedField
                  {...computedInputProps('totalIncome', 'Общий доход')}
                />
                <ComputedField
                  {...computedInputProps(
                    'yearlyIncome',
                    'Годовой доход',
                    PercentInput,
                  )}
                />
              </>
            )}
          </Grid>
        );
      }}
    />
  );
}
