import {
  ConfigurationContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const UserSalariesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { locale } = useContext(ConfigurationContext);

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            headerName: 'ID',
            selector: false,
            field: 'id',
            width: 70,
            valueGetter(params) {
              return params.row?.user?.id;
            },
            onSort: (sort) => [{ user: { id: sort } }],
          },
          {
            headerName: 'ФИО',
            field: 'fullname',
            selector: 'user { id fullName role { name }}',
            width: 170,
            valueGetter(params) {
              return params.row?.user?.fullName;
            },
            onSort: (sort) => [{ user: { fullName: sort } }],
          },
          {
            headerName: 'Роль',
            field: 'role',
            width: 150,
            selector: false,
            valueGetter(params) {
              return params.row?.user?.role?.name;
            },
            onSort: (sort) => [{ user: { role: { name: sort } } }],
          },
          {
            headerName: 'Комиссия по завершенным сделкам (руб.)',
            field: 'salarySum',
            minWidth: 300,
          },
          {
            headerName: 'Число завершенных сделок',
            field: 'finishedDealsCount',
            minWidth: 300,
          },
        ]),
      [locale, filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="getUserSalaryAggregation"
        argsSource="userSalaryAggregation"
        columns={columns}
        selectProps={{
          ...rest.selectProps,
          onSelection(selections) {
            return selections.filter((x) => x !== 'id');
          },
          onFetch(items) {
            // (rest.selectProps?.onFetch ?? ((x: any[]) => x)).(items);
            return (rest.selectProps?.onFetch ?? ((x: any[]) => x))(
              items.map((x) => ({ ...x, id: x.user?.id ?? 0 })),
            );
          },
        }}
        editable={false}
        disableRemovedFilter
        skeletonRowsCount={rest.skeletonRowsCount ?? 3}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        deletable={false}
      />
    );
  },
);
