import {
  AutocompleteInput,
  CheckboxInput,
  DateInput,
  FormInput,
} from '@kirz/mui-admin';
import React from 'react';

import { DatabaseQueryParameterType as ParameterType } from 'constants/other';

export type QueryParameterInputType = {
  id: number;
  inputName: string;
  name: string;
  multiple: boolean;
  remoteItemTextColumns: string[] | null;
  remoteItemValueColumn: string | null;
  remoteTable: string | null;
  required: boolean;
  type: string;
};

export default function QueryParameterInput(props: QueryParameterInputType) {
  const { type, name, inputName: key, required, multiple, ...rest } = props;

  const inputName = `parameter_${key}`;

  if ([ParameterType.NUMBER, ParameterType.STRING].includes(type)) {
    if (!multiple) {
      return (
        <FormInput
          label={name}
          name={inputName}
          required={required}
          type={type === ParameterType.NUMBER ? 'number' : 'text'}
          md={4}
        />
      );
    }

    return (
      <AutocompleteInput
        label={name}
        name={inputName}
        required={required}
        multiple
        freeSolo
        options={[]}
        md={12}
        inputProps={{ type: type === ParameterType.NUMBER ? 'number' : 'text' }}
      />
    );
  }

  if ([ParameterType.BOOLEAN].includes(type)) {
    return (
      <CheckboxInput label={name} name={inputName} required={required} md={4} />
    );
  }

  if ([ParameterType.DATE].includes(type)) {
    return (
      <DateInput label={name} name={inputName} required={required} md={4} />
    );
  }

  if ([ParameterType.REMOTE_AUTOCOMPLETE].includes(type)) {
    return (
      <AutocompleteInput
        mode="hasura"
        source={rest.remoteTable!}
        itemValue={rest.remoteItemValueColumn!}
        itemText={(item) =>
          rest.remoteItemTextColumns!.map((c) => item[c]).join(' ')
        }
        selection={`${rest.remoteItemTextColumns!.join(
          ' ',
        )} ${rest.remoteItemValueColumn!}`}
        label={name}
        name={inputName}
        required={required}
        md={multiple ? 12 : 4}
        fetchAll={
          ![
            'call',
            'client',
            'change_log',
            'client_phone',
            'deal',
            'file',
            'task',
          ].includes(rest.remoteTable!)
        }
        skipFetchContextCheck
        multiple={multiple}
      />
    );
  }

  return null;
}
