import { ConfigurationContext, useNavigate } from '@kirz/mui-admin';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Statuses } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { api } from 'services/api';
import store from 'services/store';
import { User } from 'types/user';

import { SettingsContext, SettingsContextType } from './SettingsContext';

type InitialUserContextType = {
  user?: User;
  isUserLoading: boolean;
  initialize?: () => Promise<void>;
  logout?: () => Promise<void>;
};

type UserContextType = {
  user: User;
  isUserLoading: boolean;
  latestReleaseId: string | null;
  setLatestReleaseId: (value: string | null) => void;
  latestViewedReleaseId: string | null;
  setLatestViewedReleaseId: (value: string | null) => void;
  initialize: () => Promise<void>;
  logout: () => Promise<void>;
  hasPermission: (permission: string) => boolean;
};

export const UserContext = createContext<UserContextType>({
  isUserLoading: true,
} as any);

type Props = {
  children: React.ReactNode;
};

export function UserContextProvider({ children }: Props) {
  const { hasura } = useContext(ConfigurationContext);
  const [isUserLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [latestViewedReleaseId, setLatestViewedReleaseId] = useState<
    string | null
  >(localStorage.getItem('latest-release') || null);
  const [latestReleaseId, setLatestReleaseId] = useState<string | null>(null);
  const [settings, setSettings] = useState<SettingsContextType>({} as any);

  const navigate = useNavigate();

  const fetchSettings = useCallback(async () => {
    try {
      const settingsData = await api.getSettings();
      setSettings(settingsData);

      const [latestRelease] = await hasura.request({
        type: 'query',
        source: 'releaseNote',
        selection: 'id',
        limit: 1,
        orderBy: { date: 'DESC' },
      });

      if (latestRelease) {
        setLatestReleaseId(latestRelease.id);
      }
    } catch {
      // no op
    }
  }, []);

  const initialize = useCallback(async () => {
    const isLoginPage = window.location.href.includes('/login');

    const [loggedUser] = await Promise.all([
      api.getMe().then(async (x) => {
        if ('error' in x) {
          if (!isLoginPage) {
            navigate('/login');
          }

          return;
        }

        if (x?.roleId !== -1) {
          await fetchSettings();
        } else {
          setSettings({
            tags: [],
            leadSources: [],
            nonTargetedLeadType: [],
            estateStatuses: [
              { text: 'Забронирован', value: Statuses.BOOKED },
              { text: 'Внесен залог', value: Statuses.DEPOSIT_MADE },
              { text: 'Куплен', value: Statuses.BOUGHT },
              { text: 'В продаже', value: Statuses.FOR_SALE },
              { text: 'Продан', value: Statuses.SOLD_OUT },
            ],
          } as any);
        }

        return x;
      }),
      new Promise((resolve) => {
        setTimeout(resolve, 400);
      }),
    ]);

    setUser(loggedUser);
    setIsLoading(false);

    store.user = loggedUser ?? null;
    store.preferences = {
      ...(loggedUser?.preferences &&
        Object.assign(
          {},
          ...Object.keys(loggedUser.preferences)
            .map((key) => key.replaceAll('_', '').toLowerCase())
            .map((key) => ({
              [key]: loggedUser.preferences[key],
            })),
        )),
      ...loggedUser?.preferences,
    };

    if (loggedUser?.roleId !== -1) {
      api.initializeWsConnection();
    }

    if (loggedUser && isLoginPage) {
      navigate('/');
      return;
    }

    if (!loggedUser) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    await api.logout();

    setUser(undefined);
  }, []);

  const hasPermission = useCallback(
    (permission: string) => {
      if (permission === Permissions.IS_CLIENT) {
        return user?.roleId === -1;
      }

      return !!user?.role.permissions[permission];
    },
    [user],
  );

  const contextData = useMemo<InitialUserContextType>(
    () => ({
      user,
      isUserLoading,
      latestReleaseId,
      latestViewedReleaseId,
      logout,
      initialize,
      hasPermission,
      setLatestReleaseId,
      setLatestViewedReleaseId,
    }),
    [
      user,
      isUserLoading,
      latestViewedReleaseId,
      latestReleaseId,
      logout,
      initialize,
      hasPermission,
      setLatestReleaseId,
      setLatestViewedReleaseId,
    ],
  );

  const settingsContextData = useMemo<SettingsContextType>(
    () => ({
      ...settings,
      reload: fetchSettings,
    }),
    [settings, fetchSettings],
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <UserContext.Provider value={contextData as UserContextType}>
      <SettingsContext.Provider value={settingsContextData!}>
        {children}
      </SettingsContext.Provider>
    </UserContext.Provider>
  );
}
