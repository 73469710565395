export type LoginPayload = {
  phone: string;
  companyId?: string;
  identityId?: string | number;
  validationCode?: string;
  password?: string;
};

export enum LoginError {
  IDENTITY_NOT_FOUND = 1,
  PHONE_VALIDATION_REQUIRED,
  WRONG_VALIDATION_CODE,
  COMPANY_SELECTION_REQUIRED,
  IDENTITY_SELECTION_REQUIRED,
  PASSWORD_VALIDATION_REQUIRED,
  WRONG_PASSWORD,
}

export type LoginResponse = {
  errorCode?: LoginError;
  errorData?: any;
  success?: boolean;
};

export type UserIdentity = {
  name: string;
  userId: number;
  roleId: number;
  roleName: string;
  companyId: string;
};

export type UserCompany = {
  id: string;
  name: string;
};
