import {
  FormGetter,
  FormInput,
  FormInputProps,
  FormSetter,
} from '@kirz/mui-admin';
import React from 'react';

import { AmountInput, ComputedFieldAdornment } from 'shared/inputs';

export function AgencyCommissionComputedField({
  name,
  InputProps,
  ...props
}: FormInputProps<any>) {
  const fields = [
    'sellerCommissionSum',
    'sellingCommissionSum',
    'developerCommissionSum',
    'partnerCommissionSum',
  ];

  return (
    <>
      <FormSetter
        render={(setValue) => (
          <FormGetter
            names={['dealPrice', ...fields]}
            onChange={(x) => {
              if (!x.dealPrice) {
                return;
              }

              const value = fields.reduce(
                (acc, key) =>
                  acc +
                  (key === 'partnerCommissionSum'
                    ? -(x[key] || 0)
                    : x[key] || 0),
                0,
              );

              if (Number.isNaN(value)) {
                return;
              }

              setValue(name, value);
            }}
          />
        )}
      />

      <FormInput
        name={name}
        readOnly
        disabled={false}
        {...AmountInput}
        formFetcherValueResolver={null}
        formSubmitterValueResolver={null}
        placeholder="—"
        md={4}
        lg={3}
        sx={{}}
        InputProps={{
          ...AmountInput.InputProps,
          startAdornment: <ComputedFieldAdornment />,
          ...InputProps,
        }}
        {...props}
      />
    </>
  );
}
