import {
  ConfigurationContext,
  FormTab,
  HasuraDataTableColumnDef,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Add, AddLink } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { EstatesTable } from 'components/EstatesTable';
import { LinkEstateToClientDialog } from 'components/LinkEstateToClientDialog';
import { Statuses } from 'constants/estate';
import { FunnelStageType } from 'constants/funnels';

export default function EstatesTab(props: { clientId: number }) {
  const { clientId } = props;
  const { hasura } = useContext(ConfigurationContext);
  const [selectedTab, setSelectedTab] = useState<string>('all');
  const [addMode, setAddMode] = useState<'new' | 'link'>('new');
  const tableRef = useRef<DataTableExRef>(null);

  const filterColumns = useCallback(
    (columns: HasuraDataTableColumnDef[]) => {
      const deleteColumns = [
        'client',
        'referrer',
        'user',
        'contractSigningDate',
        'sellingCommissionSum',
      ];
      const filteredColumns = columns.filter(
        (x) => !deleteColumns.includes(x.field),
      );
      return filteredColumns.flatMap((column) => {
        if (column.field === 'residentialComplex') {
          return [
            column,
            {
              flex: 1,
              minWidth: 100,
              headerName: 'Адрес',
              field: 'realAddress',
            },
          ];
        }

        if (column.field === 'type') {
          return [
            column,
            {
              width: 120,
              headerName: 'Доходность',
              field: 'yearlyIncome',
              selector: 'price { yearlyIncome }',
              onSort: (sort) => [{ price: { yearlyIncome: sort } }],
              valueGetter: ({ row }) =>
                row.price?.yearlyIncome &&
                `${row.price?.yearlyIncome.toFixed(2)}%`,
            },
          ];
        }

        if (column.field === 'saleQueue' && selectedTab === 'sold') {
          return {
            headerName: 'Дата продажи',
            field: 'sellingDate',
            width: 120,
            selector: 'dealsInfo { saleDeal { *dealDate* } }',
            valueGetter: ({ row }) =>
              row.dealsInfo?.saleDeal?.dealDate &&
              dayjs(row.dealsInfo?.saleDeal?.dealDate).format('MM/YYYY'),
          };
        }

        return column;
      }) as HasuraDataTableColumnDef[];
    },
    [selectedTab],
  );

  const buildTab = (filter: Record<string, any>) => {
    return {
      sx: { height: '48px', minHeight: 'auto', pr: 1 },
      iconPosition: 'end' as const,
      icon: (
        <Box
          component="span"
          sx={{ color: 'transparent', width: 0, marginLeft: -1 }}
        >
          I
        </Box>
      ),
      badge: {
        subscribe: {
          source: 'estate',
          selection: 'aggregate {count}',
          extractCount: (data: any) => data.aggregate.count,
          aggregation: true,
          filter: { ...filter, isRemoved: { _eq: false } },
        },
        badgeProps: {
          sx: {
            '.MuiBadge-badge': { backgroundColor: 'rgba(255,255,255,0.75)' },
          },
        },
      },
      filter: { ...filter, isRemoved: { _eq: false } },
    };
  };

  return (
    <FormTab
      tab="estates"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <Box
            sx={{
              overflow: 'hidden',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EstatesTable
              id="client-estates-table"
              ref={tableRef}
              persistStateMode="query"
              queryPrefix="et"
              filterColumns={filterColumns}
              sx={{ mx: -2, borderColor: 'transparent' }}
              {...(addMode === 'link' && {
                components: {
                  FormDialog: LinkEstateToClientDialog,
                },
                automaticallyOpenEditPage: false,
              })}
              skeletonRowsCount={1}
              disableRemovedFilter
              tabsFilter={{
                tabs: [
                  {
                    id: 'bought',
                    label: 'Купленные',
                    ...buildTab({
                      clients: {
                        clientId: { _eq: clientId },
                        isOwner: { _eq: true },
                      },
                    }),
                  },
                  {
                    id: 'sold',
                    label: 'Проданные',
                    ...buildTab({
                      clients: {
                        clientId: { _eq: clientId },
                        isOwner: { _eq: false },
                      },
                    }),
                  },
                  {
                    id: 'all',
                    label: 'Все',
                    ...buildTab({
                      clients: {
                        clientId: { _eq: clientId },
                      },
                    }),
                  },
                ],
                tabsProps: {
                  onChange(e, value) {
                    setSelectedTab(value);
                  },
                },
                leftSlot: (
                  <Typography variant="subtitle1" sx={{ mr: 2 }}>
                    Объекты:{' '}
                  </Typography>
                ),
              }}
              formDialogProps={{
                ...({ clientId } as any),
                formProps: {
                  defaultValues: { clientId },
                },
                formSubmitterProps: {
                  onSubmit: () => {
                    setAddMode('new');
                  },
                },
              }}
              deleteProps={{
                async deleteAction(row) {
                  await hasura.request({
                    type: 'mutation',
                    action: 'update',
                    source: 'clientEstate',
                    where: {
                      estateId: { _eq: row.id },
                      clientId: { _eq: clientId },
                    },
                    set: hasura.removeUpdate,
                  });

                  return false;
                },
              }}
              deletable={false}
            />
          </Box>
        )
      }
    </FormTab>
  );
}
