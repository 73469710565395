import { NotificationsContext } from '@kirz/mui-admin';
import Timeline from '@mui/lab/Timeline';
import React, {
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { api } from 'services/api';

import DealTimelineItem, { DealTimelineItemProps } from './DealTimelineItem';
import { dealLogs } from './dealLogs';

type Props = {
  dealId: number;
  filter?: any | null;
  controlRef?: Ref<{
    reload: () => Promise<void>;
  }>;
};

const skeletonItems = [
  {
    list: [
      {
        value: 'Skeleton',
      },
    ],
  },
  {
    from: {
      name: 'Skeleton',
    },
    to: {
      name: 'Skeleton',
      url: '/skeleton',
    },
  },
];

export default function DealTimeline({ dealId, filter, controlRef }: Props) {
  const { showPrompt } = useContext(NotificationsContext);

  const [timeline, setTimeline] = useState<DealTimelineItemProps[]>([
    ...skeletonItems,
    ...skeletonItems,
  ] as any);

  useImperativeHandle(
    controlRef,
    () => ({
      reload: async () => {},
    }),
    [],
  );

  useEffect(() => {
    const onNext = ({ data: { items } }: any) => {
      const logs = items.map((item: any) => {
        const processor = dealLogs[item.type];
        if (!processor) {
          throw new Error(`"${item.type}" is not implemented`);
        }

        return {
          ...processor(item, { showPrompt }),
          id: item.id,
          date: item.createdAt,
        };
      });

      setTimeline(logs);
    };

    const unsubscribe = api.hasuraWsClientInstance!.subscribe(
      {
        query: `subscription GetDeals ($where: DealLogBoolExp!) {
          items: dealLog(where: $where, orderBy: {createdAt: DESC}) {
            ${[
              'id',
              'createdAt',
              'payload',
              'type',
              'creator { firstName secondName avatarColor }',
              'task { isFinished }',
              'call { recordLink extractedText }',
            ].join(' ')}
          }
        }`,
        variables: {
          where: {
            dealId: { _eq: dealId },
            ...(filter || {}),
          },
        },
      },
      {
        next: onNext,
        error: () => {},
        complete: () => {},
      },
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId, filter]);

  return (
    <Timeline
      sx={{
        mt: -0.2,
      }}
    >
      {timeline.map((x, idx) => (
        <DealTimelineItem
          {...x}
          key={x.id || idx}
          last={idx === timeline.length - 1}
        />
      ))}
    </Timeline>
  );
}
