import { BaseDataTable, ConfigurationContext } from '@kirz/mui-admin';
import { Box, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FunnelStage } from 'types/entities';

type Deal = {
  id: number;
  user: { id: number; shortFullName: string };
  funnelStageId: number;
  price: { agencyCommissionSum: number | null };
  estate: object;
};

function StageInfoCell(props: { deals: Deal[] }) {
  const { deals } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{ color: 'black' }}>
        {deals.length}
      </Button>
      <Menu
        MenuListProps={{}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '300px',
            width: '20ch',
          },
        }}
      >
        {deals.map((deal) => (
          <MenuItem
            key={deal.id}
            onClick={handleClose}
            href={`/deals/${deal.id}`}
            component="a"
          >
            Сделка #{deal.id}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default function DealsReport(props: {
  dealsFilter?: Record<string, any>;
  spacing?: number;
}) {
  const { dealsFilter } = props;
  const { hasura } = useContext(ConfigurationContext);
  const [stages, setStages] = useState<FunnelStage[] | null>(null);
  const [deals, setDeals] = useState<Deal[] | null>(null);
  const rows = useMemo(() => {
    if (!stages || !deals) {
      return [];
    }

    const groups = deals.reduce((acc, item) => {
      if (!item.user) {
        return acc;
      }

      acc[item.user.id] = acc[item.user.id] || [];
      acc[item.user.id].push(item);

      return acc;
    }, {} as Record<number, Deal[]>);

    return [
      ...Object.values(groups).map((x) => ({
        id: x[0].user.id,
        userName: x[0].user.shortFullName,
        deals: x,
      })),
      {
        id: -1,
        userName: 'Всего',
        deals,
      },
    ];
  }, [stages, deals]);
  const debouncedLoadDealsTimeout = useRef<any>();

  const selectedFunnelId = props.dealsFilter?.['_and'].find(
    (x: any) => !!x.funnelId,
  )?.funnelId?._eq;

  const filteredStages = useMemo(() => {
    if (!selectedFunnelId || !stages) {
      return stages;
    }

    return stages.filter((x) => x.funnelId === selectedFunnelId);
  }, [stages, selectedFunnelId]);

  const headers = useMemo(() => {
    if (!filteredStages) {
      return [];
    }

    return [
      {
        field: 'expert',
        headerName: 'Эксперт',
        width: 200,
        valueGetter({ row }: { row: { userName: string } }) {
          return row.userName;
        },
      },
      {
        field: 'count',
        headerName: 'Обработано',
        valueGetter({ row }: { row: { deals: Deal[] } }) {
          return row.deals.length;
        },
        renderCell({ row }: { row: { deals: Deal[] }; value: number }) {
          return <StageInfoCell deals={row.deals} />;
        },
      },
      ...filteredStages.map((stage) => ({
        field: `stage_${stage.id}`,
        headerName: stage.name,
        minWidth: 100,
        flex: 1,
        valueGetter({ row: { deals } }: { row: { deals: Deal[] } }) {
          return deals.filter((x) => x.funnelStageId === stage.id).length;
        },
        renderCell({ row }: { row: { deals: Deal[] }; value: number }) {
          return (
            <StageInfoCell
              deals={row.deals.filter((x) => x.funnelStageId === stage.id)}
            />
          );
        },
      })),
      {
        field: 'income',
        headerName: 'Валовый доход',
        minWidth: 150,
        flex: 1,
        valueGetter({ row }: { row: { deals: Deal[] } }) {
          return row.deals.reduce(
            (acc, item) => acc + (item.price?.agencyCommissionSum ?? 0),
            0,
          ) as number;
        },
        valueFormatter({ value }: { value: number }) {
          return `${value.toLocaleString()}₽`;
        },
      },
    ];
  }, [filteredStages]);

  const loadDeals = useCallback(async () => {
    if (debouncedLoadDealsTimeout.current) {
      clearTimeout(debouncedLoadDealsTimeout.current);
      debouncedLoadDealsTimeout.current = null;
    }

    if (!dealsFilter) {
      return;
    }

    debouncedLoadDealsTimeout.current = setTimeout(async () => {
      setDeals(null);
      setStages(null);

      const [stages, deals]: [FunnelStage[], any[]] = await Promise.all([
        hasura.request({
          type: 'query',
          source: 'funnelStage',
          selection: 'id name type funnelId',
          where: { isRemoved: { _eq: false } },
          orderBy: { serialNumber: 'ASC' },
        }),
        hasura.request({
          type: 'query',
          source: 'deal',
          selection:
            'id funnelStageId user { id shortFullName } price { agencyCommissionSum }',
          where: dealsFilter,
        }),
      ]);

      setStages(stages);
      setDeals(deals);
    }, 400);
  }, [hasura, dealsFilter]);

  useEffect(() => {
    loadDeals();
  }, [loadDeals]);

  if (!deals || !stages) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <BaseDataTable
      id="deals-report-table"
      columns={headers as any}
      rows={rows}
    />
  );
}
