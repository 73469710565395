import { AttachmentsZone, FormTab } from '@kirz/mui-admin';
import React from 'react';

export default function FilesTab(props: { estateId: number }) {
  const { estateId } = props;

  return (
    <FormTab tab="files">
      <AttachmentsZone
        name="estateId"
        entityId={estateId}
        title="Файлы"
        attachmentsTypes={[
          { value: 'photo', text: 'Фото' },
          { value: 'layout', text: 'Планировка' },
          { value: 'floor-layout', text: 'Планировка на этаже' },
          { value: 'ddu', text: 'Договоры' },
          { value: 'window-view', text: 'Вид из окна' },
          { value: 'registration-documents', text: 'Документы по регистрации' },
          { value: 'main-photo', text: 'Главное фото' },
          { value: 'other', text: 'Другие файлы' },
        ]}
      />
    </FormTab>
  );
}
