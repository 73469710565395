import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { UserContextProvider } from 'contexts';

import App from './App';
import theme from './theme';
import 'assets/css/site.css';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <UserContextProvider>
        <CssBaseline />
        <App />
      </UserContextProvider>
    </BrowserRouter>
  </ThemeProvider>,
);
//
