import { ConfigurationContext, NotificationsContext } from '@kirz/mui-admin';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';

import { FunnelStageType } from 'constants/funnels';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';
import { useDealStageManager } from 'hooks/useDealStageManager';

type Props = {
  dealId: number;
  funnelStageId: number | null;
  funnelId: number | null;
  onStageChanged: (newStageId: number) => void;
};

export default function DealStagesLine({
  dealId,
  funnelId,
  funnelStageId,
  onStageChanged,
}: Props) {
  const theme = useTheme();

  const { hasPermission } = useContext(UserContext);
  const { showAlert } = useContext(NotificationsContext);
  const { funnelStages } = useContext(SettingsContext);
  const { hasura } = useContext(ConfigurationContext);
  const { validateDealStageChange } = useDealStageManager();

  const selectedStage = funnelStages.find((x) => x.id === funnelStageId);

  const content =
    funnelId == null || funnelStageId == null
      ? [...new Array(10).keys()].map((idx) => (
          <Skeleton
            key={idx}
            variant="rectangular"
            sx={{ flex: 1, marginRight: 1 }}
            height="100%"
          />
        ))
      : [...funnelStages]
          .map((x) => ({
            ...x,
            isFinal: [FunnelStageType.FAILURE, FunnelStageType.WRONG].includes(
              x.type,
            )
              ? 1
              : 0,
          }))
          .sort((a, b) => (a.isFinal ? 1 : 0) - (b.isFinal ? 1 : 0))
          .filter((x) => x.funnelId === funnelId)
          .map((stage) => {
            const isSelected =
              selectedStage &&
              selectedStage.serialNumber === stage.serialNumber;
            const isClosed =
              selectedStage &&
              [FunnelStageType.FAILURE, FunnelStageType.WRONG].includes(
                selectedStage.type,
              );
            const isFinished =
              selectedStage &&
              [FunnelStageType.DEAL].includes(selectedStage.type);
            const isHighlighted =
              selectedStage &&
              selectedStage.serialNumber >= stage.serialNumber &&
              !stage.isFinal;

            return (
              <Box
                key={stage.id}
                component="button"
                sx={{
                  flex: 1,
                  border: 'thin solid #e6e8f0',
                  pl: 1.4,
                  mr: 1,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  background: 'none',
                  borderRadius: 0.5,
                  ...(() => {
                    if (isClosed) {
                      return isSelected
                        ? {
                            backgroundColor: 'rgba(255,0,0,0.1)',
                            opacity: 0.5,
                            cursor: 'no-drop',
                          }
                        : {
                            backgroundColor: 'rgba(0,0,0,0.05)',
                            opacity: 0.5,
                            cursor: 'no-drop',
                          };
                    }

                    return isHighlighted
                      ? {
                          backgroundColor: '#f2fff1',
                          color: 'rgba(0,0,0,0.5)',
                        }
                      : {
                          ...(!isFinished
                            ? {
                                ':hover': {
                                  borderColor: theme.palette.primary.main,
                                },
                              }
                            : {
                                opacity: 0.3,
                              }),
                          color: 'rgba(0, 0, 0, 0.9)', // 'rgb(82 101 131)',
                          cursor: 'pointer',
                        };
                  })(),
                }}
                type="button"
                onClick={async () => {
                  if (
                    isClosed &&
                    !hasPermission(Permissions.DEALS_CANCEL_FINAL_STAGE)
                  ) {
                    return;
                  }

                  if (isHighlighted) {
                    if (
                      !(
                        hasPermission(Permissions.DEALS_CANCEL_FINAL_STAGE) &&
                        (selectedStage!.type === FunnelStageType.DEAL ||
                          selectedStage!.type === FunnelStageType.FAILURE ||
                          selectedStage!.type === FunnelStageType.WRONG)
                      )
                    ) {
                      showAlert('Данный этап уже пройден', 'error');
                      return;
                    }
                  }

                  const validationResult = await validateDealStageChange(
                    dealId,
                    selectedStage!.type,
                    stage.type,
                  );

                  if (!validationResult) {
                    return;
                  }

                  await Promise.all(
                    validationResult.updates.map((x) => hasura.request(x)),
                  );

                  await hasura.request({
                    type: 'mutation',
                    action: 'update',
                    source: 'deal',
                    where: { id: { _eq: dealId } },
                    set: {
                      sort: -new Date().valueOf(),
                      funnelStageId: stage.id,
                    },
                  });

                  onStageChanged(stage.id);
                  showAlert('Этап изменен');
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: 1.1,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textAlign: 'left',
                  }}
                >
                  {stage.name}
                </Typography>
              </Box>
            );
          });

  return (
    <Box sx={{ display: 'flex', overflowX: 'auto', height: '42px' }}>
      {content}
    </Box>
  );
}
