import { FormInput, FormDialog, FormDialogProps } from '@kirz/mui-admin';
import React from 'react';

export function NewDealCommentDialog(props: Partial<FormDialogProps>) {
  const { ...rest } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source={rest.source ?? 'dealNote'}
      title={rest.title ?? 'Новый комментарий'}
      maxWidth="sm"
      autoFocus={false}
    >
      <FormInput name="text" label="Примечание" multiline rows={6} required />
    </FormDialog>
  );
}
