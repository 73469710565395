import {
  FormTabs,
  FormGetter,
  HasuraSelector,
  FormPageLayout,
} from '@kirz/mui-admin';
import { CurrencyRuble } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  Text,
  HomeVariantOutline,
  RulerSquareCompass,
  Attachment,
  Bank,
  CalendarMultiselect,
} from 'mdi-material-ui';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { RoleGuard } from 'components/RoleGuard';
import { TagsGroup } from 'components/TagsGroup';
import { RoomsNumbers } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

import { MainTab, ApartmentTab, FilesTab, PriceTab } from './tabs';

export default function Estate() {
  const { hasPermission } = useContext(UserContext);
  const routeParams = useParams() as Record<string, string>;
  const estateId = parseInt(routeParams.id, 10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isClient = hasPermission(Permissions.IS_CLIENT);

  return (
    <FormPageLayout
      source="estate"
      readOnly={isClient}
      allowUnsavedExit={isClient}
      breadcrumbs={[
        {
          text: 'Объекты',
          icon: HomeVariantOutline,
          href: isClient ? '#' : '/estates',
        },
        (item) => {
          const name = [
            item.residentialComplex?.name || item.address,
            // eslint-disable-next-line max-len
            item.roomsNumber &&
              RoomsNumbers.find(
                (x) => x.value === item.roomsNumber,
              )?.text?.toLowerCase(),
            item.totalArea && `${item.totalArea}м²`,
          ]
            .filter((x) => !!x)
            .join(', ');

          return {
            text: isSmallScreen
              ? `Объект #${item.id}`.trim()
              : `#${item.id} ${name}`.trim(),
            copyOnClick: true,
          };
        },
      ]}
      formSubmitterProps={{
        preSubmit(item) {
          return Object.fromEntries(
            Object.entries(item).filter(
              ([key, value]) => !key.startsWith('old_'),
            ),
          );
        },
      }}
      defaultRoute="/estates"
      tagsSlot={
        <Box sx={{ ml: 1 }}>
          <FormGetter
            names={['systemTags', 'tags']}
            render={({ systemTags, tags }) =>
              systemTags &&
              tags && (
                <TagsGroup
                  ids={[...systemTags, ...tags]}
                  size="small"
                  placeholder={false}
                />
              )
            }
          />
        </Box>
      }
    >
      <RoleGuard permission={{ not: Permissions.IS_CLIENT }}>
        <HasuraSelector
          name="systemTags"
          selection="systemTags: tags(where: {tag: {systemType: {_isNull: false}}}) {tagId}"
          resolveValue={(item) => ({
            systemTags: item.systemTags.map((x: any) => x.tagId),
          })}
        />
      </RoleGuard>
      <HasuraSelector
        name="residentialComplex"
        selection="residentialComplex {id name developer houseType floorsNumberFrom floorsNumberTo}"
      />
      <FormGetter
        names={['paymentForm']}
        render={({ paymentForm }) => (
          <FormTabs
            persistStateMode="query"
            tabs={[
              ...(isClient
                ? [{ id: 'price', label: 'Стоимость', icon: <CurrencyRuble /> }]
                : [{ id: 'main', label: 'Информация', icon: <Text /> }]),
              { id: 'flat', label: 'Квартира', icon: <RulerSquareCompass /> },
              ...(isClient
                ? [{ id: 'main', label: 'Информация', icon: <Text /> }]
                : [
                    {
                      id: 'price',
                      label: 'Стоимость',
                      icon: <CurrencyRuble />,
                    },
                  ]),
              ...(paymentForm === 'mortgage'
                ? [{ id: 'mortgage', label: 'Ипотека', icon: <Bank /> }]
                : []),
              ...(paymentForm === 'delayed'
                ? [
                    {
                      id: 'delayed',
                      label: 'Рассрочка',
                      icon: <CalendarMultiselect />,
                    },
                  ]
                : []),
              { id: 'files', label: 'Файлы', icon: <Attachment /> },
            ]}
            tabsWrapperStyle={{
              mx: { xs: 0, md: 0 },
            }}
          >
            <MainTab estateId={estateId} />
            <ApartmentTab />
            <PriceTab estateId={estateId} />
            <FilesTab estateId={estateId} />
          </FormTabs>
        )}
      />
    </FormPageLayout>
  );
}
