import { CheckboxInput, FormGetter } from '@kirz/mui-admin';
import React from 'react';

export default function RoleBooleanPermission(props: {
  name: string;
  label: string | ((value: boolean) => string);
  when?: (string | { name: string; condition: (value: any) => boolean })[];
}) {
  const { name: permission, label, when: enableWhen } = props;

  const content = (disabled: boolean) => (
    <FormGetter
      names={[permission]}
      // @ts-ignore
      render={({ [permission]: value }) => (
        <CheckboxInput
          name={permission}
          label={typeof label === 'function' ? label(value) : label}
          variant="switch"
          size="small"
          disabled={disabled}
          formFetcherValueResolver={{
            selection: 'permissions',
            resolveValue: (x) => ({
              [permission]: x.permissions[permission],
            }),
          }}
          formSubmitterValueResolver={{
            resolveValue: (x) => ({
              ...x,
              permissions: {
                ...(x.permissions || {}),
                [permission]: x[permission],
              },
            }),
          }}
        />
      )}
    />
  );

  if (!enableWhen?.length) {
    return content(false);
  }

  return (
    <FormGetter
      names={enableWhen.map((x) => (typeof x === 'string' ? x : x.name))}
      render={(values) => {
        const isDisabled =
          enableWhen.filter((condition) => {
            if (typeof condition === 'string') {
              return !values[condition];
            }

            return !condition.condition(values[condition.name]);
          }).length > 0;

        return content(isDisabled);
      }}
    />
  );
}
