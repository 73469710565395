import React, { useContext } from 'react';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

import ClientEstates from './ClientEstates';
import UserEstates from './UserEstates';

export default function Estates({ type }: { type?: 'bought' | 'sold' }) {
  const { hasPermission } = useContext(UserContext);

  if (hasPermission(Permissions.IS_CLIENT)) {
    return <ClientEstates type={type} />;
  }

  return <UserEstates />;
}
