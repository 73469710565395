import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';

export default function RoleSection(props: {
  title: string;
  children: React.ReactNode;
}) {
  const { title, children } = props;
  return (
    <Box sx={{ border: 'thin solid #e6e8f0', borderRadius: 1, p: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Grid container>{children}</Grid>
    </Box>
  );
}
