import {
  ConfigurationProvider as MuiAdminConfigurationProvider,
  NavigationContextProvider,
} from '@kirz/mui-admin';
import { Box, Typography } from '@mui/material';
import { AlertOutline } from 'mdi-material-ui';
import React, { useContext, useEffect } from 'react';
import {
  Routes as DomRoutes,
  Route,
  useNavigate,
  Navigate,
  Outlet,
} from 'react-router-dom';

import { SplashLoader } from 'components/SplashLoader';
import { Configuration } from 'constants/configuration';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { DefaultLayout } from 'layouts/DefaultLayout';
import { Call } from 'pages/Call';
import { Calls } from 'pages/Calls';
import { Client } from 'pages/Client';
import { Clients } from 'pages/Clients';
import { Deal } from 'pages/Deal';
import { Deals } from 'pages/Deals';
import { DealsList } from 'pages/DealsList';
import { DealsReport } from 'pages/DealsReport';
import { Documents } from 'pages/Documents';
import { Estate } from 'pages/Estate';
import { Estates } from 'pages/Estates';
import { Formules } from 'pages/Formules';
import { Funnels } from 'pages/Funnels';
import { Leads } from 'pages/Leads';
import { Login } from 'pages/Login';
import { Queries } from 'pages/Queries';
import { Query } from 'pages/Query';
import { QueryResult } from 'pages/QueryResult';
import { ResidentialComplex } from 'pages/ResidentialComplex';
import { ResidentialComplexes } from 'pages/ResidentialComplexes';
import { Role } from 'pages/Role';
import { Roles } from 'pages/Roles';
import { Salaries } from 'pages/Salaries';
import { Settings } from 'pages/Settings';
import { Statistics } from 'pages/Statistics';
import { Task } from 'pages/Task';
import { Tasks } from 'pages/Tasks';
import { Users } from 'pages/Users';
import { Version } from 'pages/Version';

function Guard(props: { permission?: string | string[] }) {
  const { permission } = props;

  const { hasPermission } = useContext(UserContext);

  if (
    permission &&
    (typeof permission === 'string'
      ? !hasPermission(permission)
      : !permission.find((x) => hasPermission(x)))
  ) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          pb: 10,
        }}
      >
        <AlertOutline color="error" sx={{ fontSize: '100px', mb: 2 }} />
        <Typography variant="h5">Доступ запрещен</Typography>
      </Box>
    );
  }

  return <Outlet />;
}

export default function App() {
  const { isUserLoading, user, hasPermission } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoading || user) {
      return;
    }

    navigate('/login');
  }, [navigate, isUserLoading, user]);

  const routes = [
    {
      path: 'tasks',
      permission: Permissions.TASKS_ENABLED,
      children: (
        <>
          <Route index element={<Tasks />} />
          <Route path=":id" element={<Task />} />
        </>
      ),
    },
    {
      path: 'bought-estates',
      permission: [Permissions.IS_CLIENT],
      element: <Estates type="bought" />,
    },
    {
      path: 'sold-estates',
      permission: [Permissions.IS_CLIENT],
      element: <Estates type="sold" />,
    },
    {
      path: 'estates',
      permission: [Permissions.ESTATES_ENABLED, Permissions.IS_CLIENT],
      children: (
        <>
          <Route index element={<Estates />} />
          <Route path=":id" element={<Estate />} />
        </>
      ),
    },
    {
      path: 'deals',
      permission: [Permissions.DEALS_ENABLED, Permissions.IS_CLIENT],
      children: (
        <>
          <Route index element={<Deals />} />
          <Route
            path="report"
            element={<Guard permission={Permissions.DEALS_REPORT_VIEW} />}
          >
            <Route index element={<DealsReport />} />
          </Route>
          <Route path=":id" element={<Deal />} />
        </>
      ),
    },
    {
      path: 'deals-list',
      permission: Permissions.DEALS_ENABLED,
      children: (
        <>
          <Route index element={<DealsList />} />
        </>
      ),
    },
    {
      path: 'leads',
      permission: Permissions.LEADS_ENABLED,
      element: <Leads />,
    },
    {
      path: 'residential-complexes',
      permission: Permissions.RESIDENTIAL_COMPLEXES_ENABLED,
      children: (
        <>
          <Route index element={<ResidentialComplexes />} />
          <Route path=":id" element={<ResidentialComplex />} />
        </>
      ),
    },
    {
      path: 'clients',
      permission: Permissions.CLIENTS_ENABLED,
      children: (
        <>
          <Route index element={<Clients />} />
          <Route path=":id" element={<Client />} />
        </>
      ),
    },
    {
      path: 'calls',
      permission: Permissions.CALLS_ENABLED,
      children: (
        <>
          <Route index element={<Calls />} />
          <Route path=":id" element={<Call />} />
        </>
      ),
    },
    {
      path: 'statistics',
      permission: Permissions.ANALYZE_STATISTICS,
      element: <Statistics />,
    },
    {
      path: 'queries',
      permission: Permissions.ANALYZE_DATABASE_QUERY,
      children: (
        <>
          <Route index element={<Queries />} />
          <Route
            path=":id"
            element={
              <Guard
                permission={Permissions.ANALYZE_DATABASE_QUERY_MANAGEMENT}
              />
            }
          >
            <Route index element={<Query />} />
          </Route>
          <Route path=":id/execute" element={<QueryResult />} />
        </>
      ),
    },
    {
      path: 'roles',
      permission: Permissions.SETTINGS_ROLES_FULL,
      children: (
        <>
          <Route index element={<Roles />} />
          <Route path=":id" element={<Role />} />
        </>
      ),
    },
    {
      path: 'users',
      permission: Permissions.SETTINGS_USERS_FULL,
      element: <Users />,
    },
    {
      path: 'funnels',
      permission: Permissions.SETTINGS_FUNNELS_FULL,
      element: <Funnels />,
    },
    {
      path: 'formules',
      permission: Permissions.SETTINGS_FORMULES_FULL,
      element: <Formules />,
    },
    {
      path: 'settings',
      permission: Permissions.SETTINGS_PARAMETERS_FULL,
      element: <Settings />,
    },
    {
      path: 'version',
      element: <Version />,
    },
    {
      path: 'documents',
      permission: Permissions.SETTINGS_DOCUMENTS_FULL,
      element: <Documents />,
    },
    ...(!hasPermission(Permissions.IS_CLIENT)
      ? [
          {
            path: 'salaries',
            element: <Salaries />,
            permission: Permissions.ANALYZE_VIEW_SALARIES,
          },
        ]
      : []),
  ] as {
    path: string;
    permission?: string | string[];
    children?: React.ReactNode;
    element?: React.ReactNode;
  }[];

  const defaultRoute =
    routes.find(
      (x) =>
        !x.permission ||
        (typeof x.permission === 'string'
          ? hasPermission(x.permission)
          : !!x.permission.find((y) => hasPermission(y))),
    )?.path || '/';

  return (
    <MuiAdminConfigurationProvider {...Configuration(user).muiAdmin}>
      <NavigationContextProvider>
        <DomRoutes>
          <Route path="login" element={<Login />} />
          {user && (
            <>
              <Route path="/" element={<DefaultLayout />}>
                {routes.map((route) => {
                  const content = route.children || (
                    <Route index element={route.element} />
                  );

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<Guard permission={route.permission} />}
                    >
                      {content}
                    </Route>
                  );
                })}
                <Route index element={<Navigate to={defaultRoute} />} />
              </Route>
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </>
          )}
        </DomRoutes>
        <SplashLoader visible={isUserLoading} />
      </NavigationContextProvider>
    </MuiAdminConfigurationProvider>
  );
}
