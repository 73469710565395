export const YearQuarters = [
  { text: 'I квартал', value: 1 },
  { text: 'II квартал', value: 2 },
  { text: 'III квартал', value: 3 },
  { text: 'IV квартал', value: 4 },
];

export const numberToQuarter = (number: number) => {
  switch (number) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    default:
      return 'IV';
  }
};

export const saleQueueToString = (item: any) => {
  return item
    ? `№${item.serialNumber}: ${numberToQuarter(item.releaseQuarter)}/${
        item.releaseYear
      }-${numberToQuarter(item.keysQuarter)}/${item.keysYear}`
    : null;
};

export const DatabaseQueryParameterType = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  REMOTE_AUTOCOMPLETE: 'REMOTE_AUTOCOMPLETE',
  // AUTOCOMPLETE: 'AUTOCOMPLETE',
};

export const DatabaseQueryParameterTypeList = [
  { text: 'Строка', value: DatabaseQueryParameterType.STRING },
  { text: 'Число', value: DatabaseQueryParameterType.NUMBER },
  { text: 'Чекбокс', value: DatabaseQueryParameterType.BOOLEAN },
  { text: 'Дата', value: DatabaseQueryParameterType.DATE },
  {
    text: 'Автокомплит из БД',
    value: DatabaseQueryParameterType.REMOTE_AUTOCOMPLETE,
  },
  // { text: 'Автокомплит', value: DatabaseQueryParameterType.AUTOCOMPLETE },
];

export const RoleCommissionType = {
  PERCENT: 'percent',
  FIXED: 'fixed',
  MULTIPLE: 'multiple',
};

export const RoleCommissionTypes = [
  { text: 'Процент', value: 'percent' },
  { text: 'Фиксированное', value: 'fixed' },
  { text: 'Несколько', value: 'multiple' },
];

export const DealClientTypeValue = {
  SELLER: 'seller',
  BUYER: 'buyer',
};

export const DealClientType = [
  { text: 'Продавец', value: DealClientTypeValue.SELLER },
  { text: 'Покупатель', value: DealClientTypeValue.BUYER },
];
