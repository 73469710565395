import {
  HasuraDataTableColumnDef,
  useNavigate,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  SelectInput,
  FormInput,
  ConditionalInput,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { RoleCommissionType, RoleCommissionTypes } from 'constants/other';
import { AmountInput, PercentInput } from 'shared/inputs';

export const RoleCommissionValuesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'name',
            headerName: 'Название',
            width: 200,
          },
          {
            field: 'comission',
            headerName: 'Значение',
            selector: 'type value',
            sortable: false,
            valueGetter({
              row,
            }: {
              row: { type: string; value: number | null };
            }) {
              return `${row.value}${
                row.type === RoleCommissionType.PERCENT ? '%' : '₽'
              }`;
            },
            width: 200,
          },
          {
            field: 'description',
            headerName: 'Описание',
            flex: 1,
          },
        ]),
      [filterColumns, navigate],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="roleCommissionValue"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 0}
        persistStateMode="none"
        persistScrollBar={false}
        title="Значения"
        formDialogProps={{
          ...rest.formDialogProps,
          formProps: {
            ...rest.formDialogProps?.formProps,
            defaultValues: {
              ...rest.formDialogProps?.formProps?.defaultValues,
              type: RoleCommissionType.PERCENT,
              value: 0,
            },
          },
        }}
        formTitle={(isNew) =>
          isNew ? 'Добавление значения' : 'Редактирование значения'
        }
      >
        <FormInput name="name" label="Название" />
        <FormInput name="description" label="Описание" rows={3} multiline />
        <SelectInput
          name="type"
          label="Тип"
          md={6}
          items={RoleCommissionTypes.filter(
            (x) => x.value !== RoleCommissionType.MULTIPLE,
          )}
        />
        <ConditionalInput
          deps={['type']}
          variants={[
            {
              if: (y) => true,
              input: (y) => (
                <FormInput
                  {...(y.type === RoleCommissionType.FIXED
                    ? AmountInput
                    : PercentInput)}
                  name="value"
                  label="Значение"
                  required
                  md={6}
                  lg={6}
                  sx={{}}
                />
              ),
            },
          ]}
        />
      </DataTableEx>
    );
  },
);
