import {
  AutocompleteInput,
  Form,
  FormElementRef,
  FormFetcher,
  FormInput,
  FormSubmitter,
  SelectInput,
  SubmitButton,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, InputAdornment, Tab, Tabs } from '@mui/material';
import parseHtml from 'html-react-parser';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { DefaultTagIcon } from 'constants/icons';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

const PercentInputProps = {
  InputProps: {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  },
};

export function Settings() {
  const { user } = useContext(UserContext);
  const { reload: reloadSettings } = useContext(SettingsContext);
  const [selectedTab, setSelectedTab] = useState<'tags' | 'options'>('tags');
  const [selectedTagsTab, setSelectedTagsTab] = useState('client');
  const [selectedOptionsTab, setSelectedOptionsTab] = useState('estate-status');

  const tableRef = useRef<DataTableExRef>(null);
  const formRef = useRef<FormElementRef>(null);

  const tagsColumns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'dateTime' },
      {
        field: 'name',
        headerName: 'Название',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'icon',
        headerName: 'Иконка',
        sortable: false,
        selector: 'icon color',
        renderCell: (params) => (
          <Box component="span" sx={{ color: params.row.color }}>
            {parseHtml(params.row.icon)}
          </Box>
        ),
      },
      {
        field: 'systemType',
        headerName: 'Автоматический?',
        type: 'boolean',
        sortable: false,
        width: 250,
      },
    ],
    [],
  );

  const estateStatusColumns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'dateTime' },
      {
        field: 'text',
        headerName: 'Название',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'value',
        headerName: 'Ключ',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'isSystem',
        headerName: 'Системный?',
        type: 'boolean',
        sortable: false,
        width: 250,
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Параметры системы"
      actionContent={
        <SubmitButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          formRef={formRef}
          activateOnDirty
        >
          Сохранить
        </SubmitButton>
      }
    >
      <FormSubmitter
        source="settings"
        entityId={{ companyId: { _eq: user.companyId } }}
        onSubmit={reloadSettings}
      >
        <FormFetcher
          source="settings"
          entityId={{ companyId: { _eq: user.companyId } }}
        >
          <Form ref={formRef} sx={{ pt: 1 }}>
            <FormInput
              name="minActualPriceCorrectionPercent"
              type="number"
              md={3}
              label="Мин. процент корр. стоимости"
              required
              {...PercentInputProps}
            />
            <FormInput
              name="maxActualPriceCorrectionPercent"
              type="number"
              md={3}
              label="Макс. процент корр. стоимости"
              required
              {...PercentInputProps}
            />
            <FormInput
              name="minMortgageActualPriceCorrectionPercent"
              type="number"
              md={3}
              label="Мин. процент корр. стоимости (ипотека)"
              required
              {...PercentInputProps}
            />
            <FormInput
              name="maxMortgageActualPriceCorrectionPercent"
              type="number"
              md={3}
              label="Макс. процент корр. стоимости (ипотека)"
              required
              {...PercentInputProps}
            />
            <AutocompleteInput
              name="expertCommissionValues"
              label="Комиссии эксперту"
              mode="memory"
              options={[]}
              multiple
              freeSolo
              md={6}
              inputProps={{ type: 'number', inputProps: { min: 0, max: 100 } }}
            />
            <AutocompleteInput
              name="agencyCommissionValues"
              label="Комиссии агенству"
              mode="memory"
              options={[]}
              multiple
              freeSolo
              md={6}
              inputProps={{ type: 'number', inputProps: { min: 0, max: 100 } }}
            />
            <FormInput
              name="taskNotifyBefore"
              type="number"
              md={3}
              label="Уведомлять о задаче за"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">мин.</InputAdornment>
                ),
              }}
            />
            <FormInput
              name="estateDefaultCommission"
              type="number"
              md={3}
              label="Комиссия для объекта по умолчанию"
              required
              {...PercentInputProps}
            />
          </Form>
        </FormFetcher>
      </FormSubmitter>
      <Tabs
        value={selectedTab}
        onChange={(e, v) => setSelectedTab(v)}
        sx={{
          mt: { xs: 2, md: 4, lg: 4 },
        }}
      >
        <Tab label="Теги" value="tags" sx={{ fontSize: '16px' }} />
        <Tab label="Константы" value="options" sx={{ fontSize: '16px' }} />
      </Tabs>

      <Box
        sx={{
          minHeight: 400,
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {selectedTab === 'tags' ? (
          <DataTableEx
            key="tags-table"
            id="tags-table"
            ref={tableRef}
            source="tag"
            columns={tagsColumns}
            deletable={{
              isEnabled: (row) => !row.systemType,
            }}
            tabsFilter={{
              tabs: [
                {
                  id: 'client',
                  label: 'Теги клиентов',
                  filter: { entity: { _eq: 'client' } },
                },
                {
                  id: 'estate',
                  label: 'Теги объектов',
                  filter: { entity: { _eq: 'estate' } },
                },
                {
                  id: 'call',
                  label: 'Теги звонков',
                  filter: { entity: { _eq: 'call' } },
                },
                {
                  id: 'task',
                  label: 'Теги задач',
                  filter: { entity: { _eq: 'task' } },
                },
              ],
              tabsProps: {
                onChange: (event, value) => {
                  setSelectedTagsTab(value);
                },
              },
              actionButton: (
                <Button
                  sx={{ ml: 'auto' }}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    tableRef.current?.openFormDialog();
                  }}
                >
                  Добавить
                </Button>
              ),
            }}
            sortBy={{ field: 'createdAt', sort: 'desc' }}
            formTitle={(isNew) => (isNew ? 'Новый тег' : 'Редактирование тега')}
            formDialogProps={{
              formProps: {
                defaultValues: {
                  entity: selectedTagsTab,
                  icon: DefaultTagIcon,
                },
              },
              formSubmitterProps: {
                onSubmit: reloadSettings,
              },
            }}
          >
            <FormInput name="name" label="Название" required />
            <SelectInput
              name="entity"
              label="Тип"
              items={[
                { text: 'Клиент', value: 'client' },
                { text: 'Объект', value: 'estate' },
                { text: 'Звонок', value: 'call' },
                { text: 'Задача', value: 'task' },
              ]}
              required
            />
            <FormInput name="color" label="Цвет" required />
            <FormInput
              name="icon"
              label="Иконка (svg)"
              multiline
              required
              clearable={false}
              minRows={3}
            />
          </DataTableEx>
        ) : selectedTab === 'options' ? (
          <DataTableEx
            key="estate-statuses-table"
            id="estate-statuses-table"
            ref={tableRef}
            source="selectOption"
            columns={estateStatusColumns}
            deletable={{
              isEnabled: (row) => !row.isSystem,
            }}
            sortBy={{ field: 'createdAt', sort: 'desc' }}
            formTitle={(isNew) =>
              isNew ? 'Новое значение' : 'Редактирование значения'
            }
            tabsFilter={{
              tabs: [
                {
                  id: 'estate-status',
                  label: 'Статусы объектов',
                  filter: { entity: { _eq: 'estate-status' } },
                },
                {
                  id: 'deal-client-role',
                  label: 'Роли участников сделки',
                  filter: { entity: { _eq: 'deal-client-role' } },
                },
              ],
              tabsProps: {
                onChange: (event, value) => {
                  setSelectedOptionsTab(value);
                },
              },
              actionButton: (
                <Button
                  sx={{ ml: 'auto' }}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    tableRef.current?.openFormDialog();
                  }}
                >
                  Добавить
                </Button>
              ),
            }}
            formDialogProps={{
              formProps: {
                shouldUnregister: false,
                defaultValues: {
                  entity: selectedOptionsTab,
                  isSystem: false,
                },
              },
              formSubmitterProps: {
                preSubmit({ _new, ...item }) {
                  return item;
                },
                onSubmit: reloadSettings,
              },
            }}
          >
            <FormInput name="text" label="Название" required />
            <FormInput name="value" label="Ключ" required />
          </DataTableEx>
        ) : null}
      </Box>
    </TablePageLayout>
  );
}
