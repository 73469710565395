import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormTab,
} from '@kirz/mui-admin';
import { InputAdornment } from '@mui/material';
import React from 'react';

export default function MortgageTab() {
  return (
    <FormGetter
      names={['paymentForm']}
      render={(x) => {
        const required = x.paymentForm === 'mortgage';
        const readOnly = !!x.isFixed;

        const inputProps = {
          required,
          readOnly,
        };

        return (
          <FormTab tab="mortgage">
            <FormInput
              name="mortgageRate"
              label="Ставка по ипотеке за 1-й год"
              md={6}
              {...inputProps}
              type="number"
            />
            <FormInput
              name="mortgageRate2"
              label="Ставка по ипотеке за 2-й и последующие года"
              md={6}
              type="number"
              readOnly={readOnly}
            />
            <FormInput
              name="mortgagePayment"
              label="Платеж по ипотеке за 1-й год"
              md={6}
              {...inputProps}
              type="number"
            />
            <FormInput
              name="mortgagePayment2"
              label="Платеж по ипотеке за 2-й и последующие года"
              md={6}
              type="number"
              readOnly={readOnly}
            />
            <FormInput
              name="mortgageRatio"
              label="Коэффициент выплаты тела кредита за 1-й год"
              md={6}
              {...inputProps}
              type="number"
            />
            <FormInput
              name="mortgageRatio2"
              label="Коэффициент выплаты тела кредита 2-й и последующие года"
              md={6}
              type="number"
              readOnly={readOnly}
            />
            <FormInput
              name="mortgageDuration"
              label="Длительность ипотеки"
              md={6}
              {...inputProps}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">мес.</InputAdornment>
                ),
              }}
            />
            <FormInput
              name="mortgageRate1Duration"
              label="Длительность ставки 1 ипотеки"
              md={6}
              type="number"
              readOnly={readOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">мес.</InputAdornment>
                ),
              }}
            />
            <FormInput
              name="mortgagePaymentDay"
              label="Дата платежа (день)"
              md={6}
              type="number"
              readOnly={readOnly}
            />
            <FormInput
              name="mortgageFirstPayment"
              label="Сумма первого взноса по ипотеке"
              md={6}
              {...inputProps}
              type="number"
            />
            <AutocompleteInput
              name="mortgageBankName"
              label="Банк"
              md={6}
              mode="hasura"
              source="deal"
              preset="suggestion"
              readOnly={readOnly}
            />
          </FormTab>
        );
      }}
    />
  );
}
