import {
  AttachmentsZone,
  DateInput,
  FormInput,
  FormTab,
} from '@kirz/mui-admin';
import React from 'react';

export default function DocumentsTab(props: { clientId: number }) {
  const { clientId } = props;
  return (
    <FormTab tab="documents">
      <FormInput name="passportSeries" label="Серия паспорта" sm={4} md={3} />
      <FormInput name="passportNumber" label="Номер паспорта" sm={4} md={3} />
      <DateInput
        name="passportIssueDate"
        label="Дата выдачи паспорта"
        sm={4}
        md={2}
      />
      <FormInput
        name="passportIssuerCode"
        label="Код подразделения, выдавшего паспорт"
        sm={4}
        md={4}
      />
      <FormInput
        name="passportIssuer"
        label="Кем выдан паспорт"
        sm={6}
        md={6}
      />
      <FormInput
        name="passportRegistrationAddress"
        label="Адрес регистрации"
        sm={6}
        md={6}
      />
      <FormInput name="placeOfBirth" label="Место рождения" sm={6} md={6} />
      <FormInput
        name="fullNameGenitiveCase"
        label="ФИО в родительном падеже"
        sm={6}
        md={6}
      />
      <AttachmentsZone
        name="clientId"
        entityId={clientId}
        title="Документы"
        attachmentsTypes={[
          { value: 'files', text: 'Файлы' },
          { value: 'snils', text: 'СНИЛС' },
          { value: 'passport', text: 'Паспорт' },
        ]}
      />
    </FormTab>
  );
}
