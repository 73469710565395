import { NavigationContext } from '@kirz/mui-admin';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';

import { Permissions } from 'constants/permissions';
import { TOOLBAR_HEIGHT } from 'constants/theme';
import { UserContext } from 'contexts';

import { AppBarAccount } from './AppBarAccount';

type Props = {
  onDrawerToggleClick: () => void;
};

export function AppBar({ onDrawerToggleClick }: Props) {
  const { setNavbarContainer } = useContext(NavigationContext);
  const { user, hasPermission } = useContext(UserContext);

  const isClient = hasPermission(Permissions.IS_CLIENT);
  // @ts-ignore
  const clientUser = user.expert as {
    firstName: string;
    phone: string;
  };

  if (isClient) {
    return (
      <MuiAppBar
        position="absolute"
        sx={{
          left: { xs: 0 },
          width: { xs: '100%' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: 'background.paper',
          height: TOOLBAR_HEIGHT,
          color: (theme) => theme.palette.text.primary,
          // display: { xs: 'auto', lg: 'none' },
        }}
      >
        <Toolbar sx={{ position: 'relative', display: 'flex' }}>
          <AppBarAccount />
          <Box sx={{ flex: 1, pl: 1.5 }}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.primary',
                fontWeight: '500',
                lineHeight: 1.1,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            >
              {user.fullName}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'text.primary',
                fontWeight: '500',
                lineHeight: 1.1,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            >
              Ваш менеджер:{' '}
              <a href={`tel://+${clientUser.phone}`}>{clientUser.firstName}</a>{' '}
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ display: { xs: 'flex', lg: 'none' } }}
            onClick={onDrawerToggleClick}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
    );
  }

  return (
    <MuiAppBar
      position="absolute"
      sx={{
        left: { xs: 0 },
        width: { xs: '100%' },
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'background.paper',
        height: TOOLBAR_HEIGHT,
        color: (theme) => theme.palette.text.primary,
        // display: { xs: 'auto', lg: 'none' },
      }}
    >
      <Toolbar sx={{ position: 'relative', display: 'flex', pr: 2 }}>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{ mr: 2, display: { xs: 'flex', lg: 'none' } }}
          onClick={onDrawerToggleClick}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            pl: 0,
            pr: 5,
            display: 'flex',
            alignItems: 'center',
          }}
          ref={(ref) => {
            setNavbarContainer(ref as any);
          }}
        />
        <AppBarAccount />
      </Toolbar>
    </MuiAppBar>
  );
}
