import { Edit } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { ReleaseNote as ReleaseNoteType } from 'types/entities';

export function ReleaseNote(
  props: ReleaseNoteType & {
    isLatest: boolean;
    onEdit: () => void;
    sx?: SxProps;
  },
) {
  const { version, content, date, isLatest, sx, onEdit } = props;
  const { hasPermission } = useContext(UserContext);

  return (
    <Box
      sx={{
        border: '1px solid #e6e8f0',
        borderRadius: 1,
        backgroundColor: 'white',
        py: 2,
        px: { xs: 1, sm: 3 },
        ...(sx as any),
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h3">v{version}</Typography>
        {isLatest && (
          <Chip
            label="Последняя"
            sx={{ ml: 3 }}
            variant="outlined"
            color="primary"
          />
        )}
        {hasPermission(Permissions.RELEASE_NOTES_MANAGEMENT) && (
          <>
            <IconButton onClick={onEdit} sx={{ ml: 1 }}>
              <Edit />
            </IconButton>
          </>
        )}
        <Typography sx={{ ml: 'auto' }} variant="body2">
          {(dayjs(date) as any).format('DD.MM.YYYY')}
        </Typography>
      </Box>
      <Divider sx={{ mt: 1.5, mb: 0 }} />
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  );
}
//
