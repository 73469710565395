import {
  BasePageLayout,
  Form,
  FormElementRef,
  FormFetcher,
  FormInput,
  FormSubmitter,
  SubmitButton,
} from '@kirz/mui-admin';
import SaveIcon from '@mui/icons-material/Save';
import React, { useContext, useRef } from 'react';

import { UserContext } from 'contexts';

export function Formules() {
  const { user } = useContext(UserContext);

  const formRef = useRef<FormElementRef>(null);

  const defaultProps = {
    xs: 12,
    md: 6,
    lg: 6,
    xl: 6,
    multiline: true,
    rows: 6,
    required: true,
    clearable: false,
    readOnly: false, // user.id !== 16,
  };

  return (
    <BasePageLayout
      title="Формулы"
      paperProps={{
        sx: {
          p: 2,
        },
      }}
      actionContent={
        <SubmitButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          formRef={formRef}
          activateOnDirty
        >
          Сохранить
        </SubmitButton>
      }
    >
      <FormSubmitter
        source="settings"
        entityId={{ companyId: { _eq: user.companyId } }}
      >
        <FormFetcher
          source="settings"
          entityId={{ companyId: { _eq: user.companyId } }}
        >
          <Form ref={formRef}>
            <FormInput
              name="sellingCommissionSumFunc"
              label="Комиссия от покупателя"
              {...defaultProps}
              // readOnly
            />
            <FormInput
              name="sellerCommissionSumFunc"
              label="Комиссия от продавца"
              {...defaultProps}
              // readOnly
            />
            <FormInput
              name="partnerCommissionSumFunc"
              label="Комиссия партнеру"
              {...defaultProps}
              // readOnly
            />
            <FormInput
              name="developerCommissionSumFunc"
              label="Комиссия от застройщика"
              {...defaultProps}
              // readOnly
            />
            <FormInput
              name="receivedCommissionSumFunc"
              label="Итоговая комиссия агентства"
              {...defaultProps}
              // readOnly
            />
            <FormInput
              name="sellerReceivedSumFunc"
              label="Продавец получил на руки"
              {...defaultProps}
              // readOnly
            />
            {/* <FormInput
              name="agencyCommissionSumFunc"
              label="Комиссия агенству"
              {...defaultProps}
              readOnly
            />
            <FormInput
              name="expertCommissionSumFunc"
              label="Комиссия эксперту"
              {...defaultProps}
            /> */}
            <FormInput
              name="totalIncomeFunc"
              label="Общий доход"
              {...defaultProps}
            />
            <FormInput name="taxFunc" label="Налог" {...defaultProps} />
            <FormInput
              name="adsPriceFunc"
              label="Рекламная стоимость"
              {...defaultProps}
              md={12}
            />
            <FormInput
              name="yearlyIncomeFunc"
              label="Годовой доход (%)"
              {...defaultProps}
              md={12}
              lg={12}
              xl={12}
              rows={12}
            />
          </Form>
        </FormFetcher>
      </FormSubmitter>
    </BasePageLayout>
  );
}
