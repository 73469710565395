import {
  ConfigurationContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { PaymentForms, Statuses as EstateStatuses } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { priceFormatter } from 'utils/format';

export const DealsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { locale } = useContext(ConfigurationContext);
    const { hasPermission } = useContext(UserContext);

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          { headerName: 'ID', field: 'id', width: 70 },
          { headerName: 'Название', field: 'name', width: 100 },
          {
            headerName: 'Продавец',
            field: 'seller { *shortFullName*  }',
            width: 110,
            type: 'relationship',
          },
          {
            headerName: 'Продавец (тел.)',
            field: 'sellerPhone',
            type: 'phone',
            hidden: true,
            sortable: false,
            selector: 'seller { favouritePhone }',
            valueGetter({ row }) {
              return row.seller?.favouritePhone;
            },
          },
          {
            headerName: 'Покупатель',
            field: 'client { *shortFullName* }',
            width: 110,
            type: 'relationship',
          },
          {
            headerName: 'Покупатель (тел.)',
            field: 'clientPhone',
            type: 'phone',
            hidden: true,
            sortable: false,
            selector: 'client { favouritePhone }',
            valueGetter({ row }) {
              return row.client?.favouritePhone;
            },
          },
          {
            headerName: 'Эксперт',
            field: 'user { *shortFullName* }',
            width: 110,
            type: 'relationship',
          },
          {
            headerName: 'Объект',
            field: 'estate { *realAddress*  }',
            type: 'relationship',
            width: 100,
          },
          {
            headerName: 'Объект (ID)',
            field: 'estate { *id* }',
            type: 'relationship',
            width: 100,
            hidden: true,
          },
          {
            headerName: 'ЖК',
            field: 'estate { residentialComplex { *name* } }',
            type: 'relationship',
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Банк',
            field: 'mortgageBankName',
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Дата сделки',
            field: 'dealDate',
            type: 'date',
            width: 100,
          },
          {
            headerName: 'Сумма сделки',
            field: 'dealPrice',
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Воронка',
            field: 'funnelStage { funnel { *name* } }',
            type: 'relationship',
            width: 100,
          },
          {
            headerName: 'Этап',
            field: 'funnelStage { *name* }',
            type: 'relationship',
            width: 100,
          },
          {
            headerName: 'Цена рекламная',
            field: 'estate { price { *adsPrice* } }',
            type: 'relationship',
            fetchRemoved: false,
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Цена продажи',
            field: 'price { *sellingPrice* }',
            type: 'relationship',
            fetchRemoved: false,
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Цена продажи (по дог.)',
            field: 'price { *contractSellingPrice* }',
            type: 'relationship',
            fetchRemoved: false,
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Цена покупки',
            field: 'estate { price { *purchasePrice* } }',
            type: 'relationship',
            fetchRemoved: false,
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Цена покупки (по дог.)',
            field: 'estate { price { *contractPurchasePrice* } }',
            type: 'relationship',
            fetchRemoved: false,
            valueFormatter: priceFormatter,
            width: 100,
            hidden: true,
          },
          {
            headerName: 'Тип',
            field: 'estate { status type }',
            sortable: false,
            hidden: true,
            valueGetter({ row }) {
              if (
                [EstateStatuses.FOR_SALE, EstateStatuses.DEPOSIT_MADE].includes(
                  row.estate?.status,
                ) &&
                row.estate?.type !== 'secondary'
              ) {
                return 'переуступка';
              }

              if (
                [EstateStatuses.FOR_SALE].includes(row.estate?.status) &&
                row.estate?.type === 'secondary'
              ) {
                return 'вторичка';
              }

              return '—';
            },
            width: 100,
          },
          {
            headerName: 'Форма оплаты',
            field: 'paymentForm',
            type: 'select',
            items: PaymentForms,
            width: 100,
            hidden: true,
          },
        ]),
      [locale, filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="deal"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 3}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        deletable={hasPermission(Permissions.DEALS_DELETE)}
        editPageUrl={rest.editPageUrl ?? '/deals'}
        sx={{
          ...rest.sx,
        }}
      />
    );
  },
);
