import { Add, ArrowRightAlt, Edit } from '@mui/icons-material';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Box,
  IconButton,
  Link,
  Skeleton,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Check, Delete, InformationVariant, OpenInNew } from 'mdi-material-ui';
import React from 'react';

import { UserAvatar } from 'components/UserAvatar';
import { colors } from 'theme/colors';
const EmptySetIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeWidth="24" stroke="#000">
      <circle cx="200" cy="200" r="158" />
      <path d="M31,369 369,31" />
    </g>
  </svg>
);

export type DealTimelineItemProps = {
  id?: any;
  color?: string;
  nameColumnWidth?: number;
  icon?: 'info' | 'add' | 'complete' | 'delete' | 'edit';
  name: string;
  date?: string;
  list?: {
    name?: string;
    value: string;
    url?: string;
    valueSx?: SxProps;
  }[];
  from?: { name: string; url?: string } | null;
  to?: { name: string; url?: string } | null;
  fromToAlignment?: 'start' | 'end';
  user?:
    | {
        name: string;
        color: string;
      }
    | false;
  last?: boolean;
  result?: {
    text: string;
  };
  action?: {
    text: string;
    onClick: () => void;
  };
  content?: React.ReactNode;
  contentOnly?: boolean;
  sx?: SxProps;
};

export default function DealTimelineItem({
  icon,
  name,
  date,
  list,
  user,
  last,
  from,
  to,
  nameColumnWidth,
  color,
  result,
  action,
  fromToAlignment,
  content,
  contentOnly,
  sx,
}: DealTimelineItemProps) {
  const isSkeleton = !name;

  const fromToElement = (from || to) && (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
        '.from-to-element': {
          borderRadius: 1,
          py: 0.3,
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      {isSkeleton ? (
        <Skeleton width={100} variant="rectangular" />
      ) : (
        <Link
          className="from-to-element"
          sx={{
            ...(from && {
              backgroundColor: 'white',
              border: `thin solid ${colors.border}`,
              px: 1,
              cursor: 'pointer',
            }),
          }}
          href={from?.url || '#'}
        >
          {from ? (
            <Typography sx={{ fontSize: 'inherit' }}>{from.name}</Typography>
          ) : (
            EmptySetIcon
          )}
        </Link>
      )}
      <ArrowRightAlt sx={{ mx: 1, opacity: isSkeleton ? 0.1 : 0.3 }} />
      {isSkeleton ? (
        <Skeleton width={100} variant="rectangular" />
      ) : (
        <Link
          className="from-to-element"
          sx={{
            ...(to && {
              backgroundColor: 'white',
              border: `thin solid ${colors.border}`,
              px: 1,
              cursor: 'pointer',
            }),
          }}
          href={to?.url || '#'}
        >
          {to ? (
            <Typography sx={{ fontSize: 'inherit' }}>{to.name}</Typography>
          ) : (
            EmptySetIcon
          )}
        </Link>
      )}
    </Box>
  );

  const renderedContent = (
    <Box
      sx={[
        {
          borderRadius: 1,
          border: `thin solid ${colors.border}`,
          p: 2,
          backgroundColor: color ?? '#fbfbfe',
        },
        sx as any,
      ]}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', opacity: 0.5 }}>
        <Typography variant="body1" sx={{ fontWeight: '600' }}>
          {isSkeleton ? <Skeleton width={120} /> : name}
        </Typography>
        {date && (
          <>
            <Typography sx={{ mx: 1, opacity: isSkeleton ? 0.4 : 1 }}>
              •
            </Typography>
            <Tooltip title={dayjs(date).format('DD.MM.YYYY HH:mm')}>
              <Typography variant="body1">
                {isSkeleton ? (
                  <Skeleton width={40} />
                ) : Math.abs(dayjs(date).diff(dayjs(), 'hour')) > 24 ? (
                  dayjs(date).format('DD MMM')
                ) : (
                  dayjs(date).format('HH:mm')
                )}
              </Typography>
            </Tooltip>
          </>
        )}
      </Box>
      <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
        {isSkeleton ? (
          <Skeleton width={25} height={25} variant="circular" />
        ) : user ? (
          <UserAvatar
            fullName={user.name}
            color={user.color}
            size={25}
            fontSize="11px"
          />
        ) : (
          user !== false && (
            <UserAvatar
              fullName="bot"
              color="white"
              size={25}
              fontSize="11px"
              tooltip="Проставлено автоматически"
            />
          )
        )}
      </Box>
      <Box>
        {fromToAlignment === 'start' && fromToElement}
        {list && (
          <Box component="ul" padding={0} sx={{ listStyle: 'none', mt: 1 }}>
            {list.map((listItem, idx) => (
              <Box component="li" key={idx} sx={{ mt: 0.5 }}>
                {listItem.name && (
                  <>
                    <Box
                      sx={{
                        fontWeight: '400',
                        opacity: 0.5,
                        width: nameColumnWidth ?? 80,
                        display: 'inline-block',
                      }}
                    >
                      {listItem.name}:
                    </Box>{' '}
                  </>
                )}
                <Box
                  sx={[{ fontWeight: '500' }, listItem.valueSx as any]}
                  component="span"
                >
                  {isSkeleton ? (
                    <>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width="60%" />
                    </>
                  ) : (
                    listItem.value
                  )}
                </Box>
                {listItem.url && (
                  <Tooltip title="Открыть страницу">
                    <IconButton href={listItem.url} sx={{ p: 0, ml: 1 }}>
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ))}
          </Box>
        )}
        {content}
        {(fromToAlignment ?? 'end') === 'end' && fromToElement}
        {action && (
          <Box sx={{ mt: 1 }}>
            <Box
              component="a"
              onClick={action.onClick}
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                textUnderlineOffset: '4px',
                cursor: 'pointer',
                ':hover': {
                  textDecorationStyle: 'solid',
                },
              }}
            >
              {action.text}
            </Box>
          </Box>
        )}
        {result && (
          <Box
            sx={{
              textAlign: 'right',
              opacity: 0.5,
              mb: -1,
              fontSize: '12px',
            }}
          >
            {result.text}
          </Box>
        )}
      </Box>
    </Box>
  );

  if (contentOnly) {
    return renderedContent;
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ display: 'none' }} />

      <TimelineSeparator sx={{ pt: 0 }}>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              border: `thin solid ${colors.border}`,
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: color ?? '#fbfbfe',
            }}
          >
            {isSkeleton ? (
              <Skeleton variant="circular" width="100%" height="100%" />
            ) : !icon ? (
              <InformationVariant />
            ) : icon === 'add' ? (
              <Add />
            ) : icon === 'complete' ? (
              <Check />
            ) : icon === 'delete' ? (
              <Delete />
            ) : icon === 'edit' ? (
              <Edit />
            ) : null}
          </Box>
        </Box>
        {!last && <TimelineConnector sx={{ backgroundColor: colors.border }} />}
      </TimelineSeparator>
      <TimelineContent sx={[{ mt: -1, pb: 4, pr: 0 }]}>
        {renderedContent}
      </TimelineContent>
    </TimelineItem>
  );
}
