import { Box, IconButton, Typography } from '@mui/material';
import downloadFile from 'js-file-download';
import { Download, Pause, Play } from 'mdi-material-ui';
import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';

export function AudioPlayer(props: {
  callId: number;
  url?: string;
  size?: 'large' | 'small';
  autoplay?: boolean;
}) {
  const { url: recordUrl, callId, size, autoplay } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformContainerRef = useRef<any>();
  const wavesurferRef = useRef<any>();

  const url = recordUrl ? `/api/v1/megafon/${callId}` : null;

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: waveformContainerRef.current,
      responsive: true,
      barWidth: 2,
      barHeight: 1,
      barGap: 1,
      height: size === 'small' ? 30 : 64,
      normalize: true,
      plugins: [
        WaveSurferCursor.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': 'rgba(0,0,0,0.4)',
            color: '#fff',
            padding: '2px',
            'font-size': '12px',
          },
          formatTimeCallback: (cursorTime: number) =>
            [cursorTime].map((time) =>
              [
                Math.floor((time % 3600) / 60),
                `00${Math.floor(time % 60)}`.slice(-2),
              ].join(':'),
            ),
        }),
      ],
    });

    let timeout: any = null;
    wavesurfer.on('ready', () => {
      if (autoplay ?? true) {
        wavesurfer.play();
      }
    });

    wavesurfer.on('play', () => {
      setIsPlaying(true);
      timeout = setTimeout(() => {
        wavesurfer.pause();
      }, 100);
    });
    wavesurfer.on('pause', () => {
      setIsPlaying(false);
    });
    wavesurfer.on('finish', () => {
      setIsPlaying(false);
      wavesurfer.seekTo(0);
    });
    wavesurfer.on('audioprocess', () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    });

    wavesurferRef.current = wavesurfer;

    return () => {
      wavesurferRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!url) {
      return;
    }

    wavesurferRef.current.load(url);
  }, [url]);

  const Icon = isPlaying ? Pause : Play;
  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <IconButton
        size={size === 'small' ? 'small' : 'large'}
        sx={{ mr: 0.4 }}
        disabled={!url}
        onClick={() => {
          wavesurferRef.current.playPause();
        }}
      >
        <Icon fontSize={size === 'small' ? 'small' : 'large'} />
      </IconButton>
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflow: 'hidden',
          '& wave': {
            overflow: 'hidden !important',
          },
        }}
        ref={waveformContainerRef}
      >
        <Box
          position="absolute"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: '100%', borderBottom: 'thin solid #ccc' }} />
          {!url && (
            <Typography variant="body1" sx={{ opacity: 0.5 }}>
              Запись звонка отсутствует
            </Typography>
          )}
        </Box>
      </Box>
      {url && size !== 'small' && (
        <IconButton
          size="large"
          sx={{ mr: 0.4 }}
          disabled={!url}
          onClick={async () => {
            const response = await fetch(url);
            downloadFile(await response.blob(), `Звонок #${callId}.mp3`);
          }}
        >
          <Download />
        </IconButton>
      )}
    </Box>
  );
}
