import { DateInput, FormInput, DataTableEx } from '@kirz/mui-admin';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Chart,
  TimeScale,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import 'chartjs-adapter-dayjs-3';

Chart.register(BarElement, TimeScale, LinearScale, CategoryScale, Tooltip);

type BarChartProps = React.ComponentProps<typeof Bar>;

export function EstateActualPriceHistoryDialog(props: {
  open: boolean;
  onClose: (actualPrice?: number) => void;
  estateId: number;
}) {
  const { open, onClose, estateId } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { hasPermission } = useContext(UserContext);

  const [prices, setPrices] = useState<
    {
      date: string;
      actualPrice: number;
    }[]
  >([]);

  const tableSelectProps = useMemo(
    () => ({
      filter: { estateId: { _eq: estateId } },
      onFetch(items: any[]) {
        setPrices(items);
        return items;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [estateId],
  );

  const handleClose = () => {
    const actualPrice = [...prices].sort(
      (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
    )[0];
    onClose(actualPrice?.actualPrice);
  };

  const { data, options } = useMemo<{
    data: BarChartProps['data'];
    options: BarChartProps['options'];
  }>(() => {
    const enumerateDaysBetweenDates = (startDate: Dayjs, endDate: Dayjs) => {
      let current = startDate;
      const dates: Date[] = [];

      while (current.isSame(endDate) || current.isBefore(endDate)) {
        dates.push(current.toDate());
        current = current.add(1, 'days');
      }
      return dates;
    };

    const sortedPrices = prices.sort(
      (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
    );
    const start = sortedPrices.length && sortedPrices[0].date;
    const end =
      sortedPrices.length && sortedPrices[sortedPrices.length - 1].date;

    const reversed = [...sortedPrices].reverse();
    const filledPrices = sortedPrices.length
      ? enumerateDaysBetweenDates(
          dayjs(start).add(-7, 'days'),
          dayjs(end).add(7, 'days'),
        ).map((x) => {
          const price = reversed.find((y) => x >= dayjs(y.date).toDate());
          return {
            date: x.toISOString(),
            // eslint-disable-next-line no-nested-ternary
            price: price
              ? price.actualPrice
              : x < dayjs(sortedPrices[0].date).toDate()
              ? sortedPrices[0].actualPrice
              : reversed[0].actualPrice,
          };
        })
      : [];

    const minPrice = Math.min(...sortedPrices.map((x) => x.actualPrice));
    const maxPrice = Math.max(...sortedPrices.map((x) => x.actualPrice));
    const delta = maxPrice - minPrice;

    const getStepSize = () => {
      if (delta > 2000000) {
        return 500000;
      }
      if (delta > 1500000) {
        return 400000;
      }
      if (delta > 1000000) {
        return 300000;
      }
      if (delta > 500000) {
        return 200000;
      }
      return 100000;
    };

    return {
      data: {
        datasets: [
          {
            data: filledPrices.map((x) => ({ x: x.date, y: x.price })),
            backgroundColor: 'rgba(0, 0, 255, 0.3)',
            borderWidth: 0,
            barThickness: 'flex',
            borderSkipped: true,
            barPercentage: 1,
            categoryPercentage: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            type: 'time',
            time: {
              unit: 'day',
            },
          },
          yAxis: {
            suggestedMin: 0,
            max: maxPrice + maxPrice / 5,
            ticks: {
              stepSize: getStepSize(),
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            displayColors: false,
            callbacks: {
              title([context]) {
                // @ts-ignore
                if (!context.raw) {
                  return;
                }
                // @ts-ignore
                return `${dayjs(new Date(context.raw.x)).format('DD.MM.YYYY')}`;
              },
              label(context) {
                // @ts-ignore
                if (!context.raw) {
                  return;
                }
                // @ts-ignore
                return `${context.raw.y.toLocaleString()}₽`;
              },
            },
          },
        },
      },
    };
  }, [prices]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={() => {
        handleClose();
      }}
      fullScreen={isSmallScreen}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        График актуальной стоимости
        <IconButton onClick={() => handleClose()} sx={{ ml: 'auto', mr: -1 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            xs={12}
            md={6}
            sx={{
              alignItems: 'center',
            }}
          >
            {prices.length < 2 ? (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 8,
                  minHeight: 200,
                }}
              >
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  Недостаточно данных для построения графика
                </Typography>
              </Box>
            ) : (
              <Bar data={data} options={options} />
            )}
          </Grid>
          <Grid xs={12} md={6} sx={{ pl: 1 }}>
            <DataTableEx
              id="estate-actual-price-history"
              title="Стоимость объекта"
              source="estateActualPrice"
              columns={[
                {
                  headerName: 'Дата',
                  field: 'date',
                  type: 'date',
                  width: 100,
                },
                {
                  headerName: 'Стоимость',
                  field: 'actualPrice',
                  flex: 1,
                  type: 'number',
                },
              ]}
              sortBy={{ field: 'date', sort: 'desc' }}
              inline
              fetchAll
              {...(hasPermission(Permissions.IS_CLIENT) && {
                deletable: false,
                editable: false,
                addButton: false,
              })}
              selectProps={tableSelectProps}
              formTitle={(isNew) =>
                isNew ? 'Добавить cтоимость' : 'Редактирование cтоимости'
              }
              formDialogProps={{
                formProps: {
                  defaultValues: {
                    date: dayjs().format('YYYY-MM-DD'),
                  },
                },
                formSubmitterProps: {
                  preSubmit: (row) => ({ ...row, estateId }),
                },
              }}
            >
              <DateInput name="date" label="Дата" required />
              <FormInput
                name="actualPrice"
                label="Стоимость"
                type="number"
                required
              />
            </DataTableEx>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => handleClose()}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}
