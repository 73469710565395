import {
  AutocompleteInput,
  ConfigurationContext,
  Form,
  FormGetter,
  FormSetter,
  HasuraDataTableCustomFilter,
  RowsFilter,
  useNavigate,
} from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import React, {
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { NewDealDialog } from 'components/NewDealDialog';
import { PaymentForms, Types } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export default function DealsFilter(props: {
  mode: 'funnel' | 'report';
  controlRef: Ref<any>;
  hideFunnelFilter?: boolean;
  additionalFilters?: HasuraDataTableCustomFilter[];
  onStagesFilterChange: (filter: Record<string, any>) => void;
  onDealsFilterChange: (filter: Record<string, any>) => void;
}) {
  const navigate = useNavigate();
  const { hasura, onSearch } = useContext(ConfigurationContext);
  const { user, hasPermission } = useContext(UserContext);
  const [isNewDealDialogOpened, setIsNewDealDialogOpened] = useState(false);
  const [firstStageId, setFirstStageId] = useState<number>();
  const [lastAddedDealDate, setLastAddedDealDate] = useState<number>();
  const {
    mode,
    controlRef,
    hideFunnelFilter,
    additionalFilters = [],
    onStagesFilterChange,
    onDealsFilterChange,
  } = props;
  const [filtersState, setFiltersState] = useState<{
    funnelId?: number;
    search?: string;
    filters?: Record<string, any>;
  }>({});

  const fetchedFunnelsRef = useRef<
    { id: number; name: string; stages: { id: number }[] }[]
  >([]);

  const showAllDeals =
    (mode === 'funnel' && hasPermission(Permissions.DEALS_VIEW_ALL)) ||
    (mode === 'report' && hasPermission(Permissions.DEALS_REPORT_VIEW));

  const handleFunnelIdChange = (
    funnelId: number,
    setValue: (name: string, v: any) => void,
  ) => {
    if (!fetchedFunnelsRef.current.length) {
      return;
    }

    if (!funnelId) {
      setValue('funnelId', fetchedFunnelsRef.current[0].id);
      setFirstStageId(fetchedFunnelsRef.current[0].stages?.[0].id);
    } else {
      setFirstStageId(
        fetchedFunnelsRef.current.find((x) => x.id === funnelId)?.stages?.[0]
          .id,
      );
    }
  };

  const filters = useMemo<HasuraDataTableCustomFilter[]>(
    () => [
      {
        type: 'date',
        name: 'Дата создания (от)',
        field: 'from',
        filter: (value) => ({ createdAt: { _gte: value } }),
      },
      {
        type: 'date',
        name: 'Дата создания (до)',
        field: 'to',
        filter: (value) => ({ createdAt: { _lt: value } }),
      },
      {
        type: 'date',
        name: 'Дата завершения (от)',
        field: 'from',
        filter: (value) => ({ dealDate: { _gte: value } }),
      },
      {
        type: 'date',
        name: 'Дата завершения (до)',
        field: 'to',
        filter: (value) => ({ dealDate: { _lt: value } }),
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Жилой комплекс',
        field: 'residentialComplexId',
        source: 'residentialComplex',
        selection: 'id name',
        itemText: 'name',
        filter: (value) => ({
          estate: { residentialComplexId: { _eq: value } },
        }),
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Объект',
        field: 'estateId',
        source: 'estate',
        selection: 'id realAddress residentialComplex { id name }',
        itemText: (item) =>
          `#${item.id} ${
            item.residentialComplex
              ? item.residentialComplex.name
              : item.realAddress || ''
          }`.trim(),
      },
      {
        type: 'autocomplete',
        options: Types,
        name: 'Тип объекта',
        field: 'estateType',
        filter: (value) => ({
          estate: { type: { _eq: value } },
        }),
      },
      {
        type: 'autocomplete',
        name: 'Банк',
        field: 'mortgageBankName',
        mode: 'hasura',
        source: 'deal',
        preset: 'suggestion',
      },
      {
        type: 'autocomplete',
        options: PaymentForms,
        name: 'Форма оплаты',
        field: 'paymentForm',
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Воронка',
        field: 'funnelId',
        source: 'funnel',
        selection: 'id name isRemoved',
        itemText: 'name',
        filterOptions(options, state) {
          return options.filter((x) => !x.item?.isRemoved);
        },
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Этап',
        field: 'funnelStageId',
        source: 'funnelStage',
        selection: 'id name isRemoved funnel { name isRemoved }',
        filterOptions(options, state) {
          return options.filter(
            (x) => !x.item?.isRemoved && !x.item?.funnel?.isRemoved,
          );
        },
        itemText: (x) => `${x.name} [${x.funnel?.name}]`,
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Эксперт',
        field: 'userId',
        source: 'user',
        selection: 'id fullName',
        itemText: 'fullName',
      },
      {
        type: 'autocomplete',
        mode: 'hasura',
        name: 'Клиент',
        field: 'clientId',
        source: 'client',
        selection: 'id fullName',
        itemText: 'fullName',
        filter: (clientId) => ({
          _or: [
            { sellerId: { _eq: clientId } },
            { clientId: { _eq: clientId } },
          ],
        }),
        fetchAll: false,
      },
      ...additionalFilters,
    ],
    [],
  );

  const stagesFilter = useMemo(() => {
    if (!filtersState.funnelId) {
      return null;
    }

    return { funnelId: { _eq: filtersState.funnelId } };
  }, [filtersState.funnelId]);

  const dealsFilter = useMemo(() => {
    if (!filtersState.funnelId && !hideFunnelFilter) {
      return null;
    }

    const searchFilters =
      filtersState.search && filtersState.search.length > 1
        ? (() => {
            const searches = onSearch(filtersState.search!);
            return {
              _or: searches.map((search) => ({
                _or: [
                  ...(Number.isInteger(parseInt(search, 10)) &&
                  search.length < 7
                    ? [{ id: { _eq: parseInt(search, 10) } }]
                    : []),
                  { name: { _ilike: `%${search}%` } },
                  { client: { fullName: { _ilike: `%${search}%` } } },
                  { user: { fullName: { _ilike: `%${search}%` } } },
                  {
                    estate: {
                      realAddress: { _ilike: `%${search}%` },
                    },
                  },
                ],
              })),
            };
          })()
        : null;

    const customFilters =
      filtersState.filters &&
      Object.entries(filtersState.filters).map(([key, value]) => {
        const [filterIdx] = key.split('_').map((x) => parseInt(x, 10));
        const filter = filters[filterIdx];

        if (filter?.filter) {
          return filter?.filter(value);
        }

        if (value === null || value === undefined || value === '') {
          return {};
        }

        return {
          [filter.field]: {
            _eq: typeof value === 'string' ? value.trim() : value,
          },
        };
      });

    return {
      _and: [
        ...(!showAllDeals
          ? [
              {
                participants: { userId: { _eq: user.id } },
              },
            ]
          : []),
        ...(!hideFunnelFilter
          ? [{ funnelId: { _eq: filtersState.funnelId } }]
          : []),
        ...(searchFilters ? [searchFilters] : []),
        ...(customFilters || []),
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hideFunnelFilter,
    filtersState,
    onSearch,
    filters,
    lastAddedDealDate,
    showAllDeals,
    user.id,
  ]);

  useEffect(() => {
    if (!stagesFilter) {
      return;
    }

    onStagesFilterChange(stagesFilter);
  }, [stagesFilter, onStagesFilterChange]);

  useEffect(() => {
    if (!dealsFilter) {
      return;
    }

    onDealsFilterChange(dealsFilter);
  }, [dealsFilter, onDealsFilterChange]);

  useImperativeHandle(
    controlRef,
    () => ({
      openNewDealDialog: () => {
        setIsNewDealDialogOpened(true);
      },
    }),
    [],
  );

  return (
    <Form
      dense
      persistStateMode={{ type: 'query', queryPrefix: 'df_', json: true }}
      formProps={
        {
          component: 'div',
        } as any
      }
    >
      <Grid xs={12}>
        <FormSetter
          render={(setValue) => (
            <>
              <FormGetter
                names={['funnelId']}
                onChange={(x) => {
                  if (x.funnelId) {
                    handleFunnelIdChange(x.funnelId, setValue);
                  }
                }}
              />
              <FormGetter
                names={['funnelId', 'search', 'filters']}
                onChange={(values) => {
                  setFiltersState(values);
                }}
                render={(values) => (
                  <RowsFilter
                    initialState={{
                      search: values.search || '',
                      filters: values.filters || {},
                      tab: '0',
                    }}
                    onChange={(state) => {
                      setValue('search', state.search);
                      setValue('filters', state.filters);
                    }}
                    searchFilter={{
                      inputProps: {
                        placeholder:
                          'Поиск по ID, названию, клиенту, экспертуили адресу объекта',
                      },
                    }}
                    customFilter={{
                      filters,
                    }}
                    slots={{
                      beforeSearch: !hideFunnelFilter && (
                        <AutocompleteInput
                          label="Воронка"
                          name="funnelId"
                          mode="hasura"
                          source="funnel"
                          selection="id name stages(orderBy:{serialNumber: ASC}, limit: 1) {id}"
                          itemText="name"
                          disableClearable
                          grid={false}
                          orderBy={{
                            isDefault: 'DESC',
                          }}
                          sx={{ minWidth: '340px', width: '340px', mr: 2 }}
                          onFetch={(items) => {
                            fetchedFunnelsRef.current = items;

                            if (!values.funnelId && items?.length) {
                              setValue('funnelId', items[0].id);
                            }

                            return items;
                          }}
                        />
                      ),
                    }}
                  />
                )}
              />
            </>
          )}
        />
        <FormGetter
          names={['funnelId']}
          render={({ funnelId }) => (
            <NewDealDialog
              open={isNewDealDialogOpened}
              onClose={() => {
                setIsNewDealDialogOpened(false);
              }}
              formProps={{
                ...(!hideFunnelFilter && {
                  defaultValues: {
                    funnelId: funnelId ?? null,
                  },
                }),
              }}
              formSubmitterProps={{
                selection: ['funnelId', 'id'],
                onSubmit: (item) => {
                  if (item.funnelId === funnelId) {
                    setLastAddedDealDate(new Date().valueOf());
                  }

                  navigate(`/deals/${item.id}`);
                },
                preSubmit: async (item) => {
                  const [firstDeal] = await hasura.request({
                    source: 'deal',
                    where: {
                      funnelStageId: { _eq: item.funnelStageId },
                      funnelId: { _eq: item.funnelId },
                    },
                    selection: 'sort',
                    orderBy: { sort: 'ASC' },
                    limit: 1,
                  });

                  return {
                    ...item,
                    sort: firstDeal ? firstDeal.sort - 1 : 1,
                  };
                },
              }}
            />
          )}
        />
      </Grid>
    </Form>
  );
}
