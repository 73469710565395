export const FunnelStageType = {
  START: 'start',
  FAILURE: 'failure',
  WRONG: 'wrong',
  CALL_STAGE: 'callStage',
  DIFFICULT_CALL_STAGE: 'difficultCallStage',
  CONTACT: 'contact',
  DEAL: 'deal',
  BOOKING: 'booking',
  OTHER: 'other',
};

export const FunnelStageTypes = [
  { value: FunnelStageType.START, text: 'Входящий ЛИД' },
  { value: FunnelStageType.FAILURE, text: 'Неудача' },
  { value: FunnelStageType.WRONG, text: 'Нецелевой' },
  { value: FunnelStageType.CALL_STAGE, text: 'Этап дозвона' },
  {
    value: FunnelStageType.DIFFICULT_CALL_STAGE,
    text: 'Этап сложного дозвона',
  },
  { value: FunnelStageType.CONTACT, text: 'Первый контакт' },
  { value: FunnelStageType.BOOKING, text: 'Бронь' },
  { value: FunnelStageType.DEAL, text: 'Успех' },
  { value: FunnelStageType.OTHER, text: 'Другое' },
];

export const FunnelType = {
  DEFAULT: 'default',
  RENT: 'rent',
  TECHNICAL: 'technical',
};

export const FunnelTypes = [
  { value: FunnelType.DEFAULT, text: 'Купля/Продажа' },
  { value: FunnelType.RENT, text: 'Аренда' },
  { value: FunnelType.TECHNICAL, text: 'Техническая' },
];
