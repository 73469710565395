import {
  HasuraDataTableColumnDef,
  useNavigate,
  DataTableExRef,
  TablePageLayout,
} from '@kirz/mui-admin';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useMemo, useRef } from 'react';

import { EstatesTable } from 'components/EstatesTable';
import { UserContext } from 'contexts';

export default function ClientEstates({ type }: { type?: 'bought' | 'sold' }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const clientId = user.id;

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        selector:
          'id residentialComplex { name } realAddress roomsNumber price { actualPrice purchasePrice purchaseDate }',
        flex: 1,
        onSort: (sort) => [{ id: sort }],
        renderCell({ row }) {
          return (
            <Box
              sx={{
                borderRadius: 2,
                width: '100%',
                px: '13px',
                pb: 3,
                pt: 3,
                overflow: 'visible',
                position: 'relative',
                backgroundColor: 'white',
              }}
              boxShadow={10}
              onClick={() => {
                navigate(`/estates/${row.id}`);
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 4,
                  left: 8,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                >
                  #{row.id}
                </Typography>
              </Box>

              {row.roomsNumber && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 8,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                  >
                    {row.roomsNumber}
                  </Typography>
                </Box>
              )}

              {row.price.purchaseDate && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 2,
                    left: 8,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                  >
                    {dayjs(row.price.purchaseDate).format('DD.MM.YYYY')}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  height: 50,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {row.residentialComplex?.name ?? row.realAddress ?? ' '}
                </Typography>
              </Box>

              <Typography
                variant="caption"
                sx={{
                  textAlign: 'right',
                  fontWeight: 'bolder',
                  fontSize: '0.8rem',
                  position: 'absolute',
                  bottom: 2,
                  right: 8,
                  margin: '5px',
                }}
              >
                {type === 'bought'
                  ? row.price?.actualPrice?.toLocaleString()
                  : row.price?.purchasePrice?.toLocaleString()}
                ₽
              </Typography>
            </Box>
          );
        },
      },
    ],
    [navigate],
  );

  return (
    <TablePageLayout
      title={type === 'bought' ? 'Купленные объекты' : 'Проданные объекты'}
      paperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <EstatesTable
        id="client-estates-table"
        ref={tableRef}
        pageSizeOptions={[1000]}
        skeletonRowsCount={3}
        columns={columns}
        getRowHeight={() => 'auto'}
        editable={false}
        deletable={false}
        slots={{
          columnHeaders: () => null,
          footer: () => null,
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        getRowSpacing={() => ({ top: 10, bottom: 20 })}
        sx={{
          borderColor: 'transparent',
          mx: -1,
          marginRight: '-32px !important',
          '.MuiDataGrid-cell:first-child': {
            display: 'none',
          },
        }}
        selectProps={{
          filter:
            type === 'bought'
              ? {
                  price: {
                    purchaseDeal: {
                      isRemoved: { _eq: false },
                      _or: [
                        { clientId: { _eq: clientId } },
                        {
                          clients: {
                            type: { _eq: 'buyer' },
                            clientId: { _eq: clientId },
                            isRemoved: { _eq: false },
                          },
                        },
                      ],
                    },
                  },
                }
              : {
                  deals: {
                    _or: [
                      { sellerId: { _eq: clientId } },
                      {
                        clients: {
                          type: { _eq: 'seller' },
                          clientId: { _eq: clientId },
                          isRemoved: { _eq: false },
                        },
                      },
                    ],
                  },
                },
        }}
      />
    </TablePageLayout>
  );
}
