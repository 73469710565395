const enToRu = {
  q: 'й',
  w: 'ц',
  e: 'у',
  r: 'к',
  t: 'е',
  y: 'н',
  u: 'г',
  i: 'ш',
  o: 'щ',
  p: 'з',
  '[': 'х',
  ']': 'ъ',
  a: 'ф',
  s: 'ы',
  d: 'в',
  f: 'а',
  g: 'п',
  h: 'р',
  j: 'о',
  k: 'л',
  l: 'д',
  ';': 'ж',
  "'": 'э',
  '\\': 'ё',
  z: 'я',
  x: 'ч',
  c: 'с',
  v: 'м',
  b: 'и',
  n: 'т',
  m: 'ь',
  ',': 'б',
  '.': 'ю',
};

const ruToEn = Object.fromEntries(
  Object.entries(enToRu).map(([key, value]) => [[value], key]),
);

const processString = (str: string, dict: Record<string, string>) =>
  str
    .split('')
    .map((x) => dict[x] || x)
    .join('');

export function getKeyboardInvariantStrings(value: string) {
  const results = [
    ...new Set([
      value,
      processString(value, enToRu),
      processString(value, ruToEn),
    ]).keys(),
  ];

  return results;
}
