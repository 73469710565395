import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ActiveCallHandler } from 'components/ActiveCallHandler';
import { AppBar } from 'components/AppBar';
import { NavigationDrawer } from 'components/NavigationDrawer';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export function DefaultLayout() {
  const { hasPermission } = useContext(UserContext);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const isClient = hasPermission(Permissions.IS_CLIENT);

  return (
    <Box sx={{ display: 'flex' }}>
      {!hasPermission(Permissions.IS_CLIENT) && <ActiveCallHandler />}
      <NavigationDrawer
        isDrawerOpened={isDrawerOpened}
        setIsDrawerOpened={setIsDrawerOpened}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          paddingTop: { xs: '56px', md: '64px' },
          maxHeight: '100vh',
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <AppBar
          onDrawerToggleClick={() => setIsDrawerOpened(!isDrawerOpened)}
        />
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          {isClient && (
            <Box
              sx={{
                position: 'absolute',
                pointerEvents: 'none',
                opacity: 0.2,
                left: 0,
                right: 0,
                bottom: 0,
                top: '110px',
                overflow: 'hidden',
                zIndex: -1,
              }}
            >
              {[...new Array(20).keys()].map((id) => (
                <Box
                  key={id}
                  sx={{
                    backgroundImage: `url(/icons/logo.png)`,
                    backgroundRepeat: 'repeat-x',
                    backgroundPositionX: id % 2 === 1 ? -30 : 100,
                    backgroundSize: '80px',
                    width: '100%',
                    height: '60px',
                    ...(id > 0 && {
                      marginTop: '7px',
                    }),
                  }}
                />
              ))}
            </Box>
          )}
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              p: { xs: 2, lg: 3 },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
