import {
  HasuraDataTableColumnDef,
  useNavigate,
  DataTableExRef,
  TablePageLayout,
} from '@kirz/mui-admin';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useMemo, useRef } from 'react';

import { DealsTable } from 'components/DealsTable';
import { DealClientTypeValue } from 'constants/other';
import { UserContext } from 'contexts';

export function ClientDeals() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const clientId = user.id;

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        selector:
          'id dealDate dealPrice price { sellerReceivedSum } estate { realAddress roomsNumber residentialComplex { name } } client { id } seller { id } clients { clientId type }',
        flex: 1,
        onSort: (sort) => [{ id: sort }],
        renderCell({ row }) {
          const getPriceValue = (key: string) => {
            return row.price?.[key] ?? null;
          };

          const isBuyer =
            row.client?.id === user.id ||
            !!row.clients.find(
              (x: any) =>
                [DealClientTypeValue.BUYER].includes(x.type) &&
                x.clientId === user.id,
            );

          const price = isBuyer
            ? row.dealPrice ?? null
            : getPriceValue('sellerReceivedSum');

          return (
            <Box
              sx={{
                borderRadius: 2,
                width: '100%',
                px: '13px',
                pb: 3,
                pt: 3,
                overflow: 'visible',
                position: 'relative',
                backgroundColor: 'white',
              }}
              boxShadow={10}
              onClick={() => {
                navigate(`/deals/${row.id}`);
              }}
            >
              {row.id && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    left: 8,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                  >
                    #{row.id}
                  </Typography>
                </Box>
              )}

              {row.estate?.roomsNumber && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 8,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                  >
                    {row.estate?.roomsNumber}
                  </Typography>
                </Box>
              )}

              {row.dealDate && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 2,
                    left: 8,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: 'block', lineHeight: 1.5, margin: '5px' }}
                  >
                    {dayjs(row.dealDate).format('DD.MM.YYYY')}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  height: 50,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6">
                  {row.estate?.residentialComplex?.name ??
                    row?.estate?.realAddress ??
                    ' '}
                </Typography>
              </Box>

              {price && (
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontWeight: 'bolder',
                    fontSize: '0.8rem',
                    position: 'absolute',
                    bottom: 2,
                    right: 8,
                    margin: '5px',
                  }}
                >
                  {price.toLocaleString()}₽
                </Typography>
              )}
            </Box>
          );
        },
      },
    ],
    [navigate],
  );

  return (
    <TablePageLayout
      title="Архив сделок"
      paperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DealsTable
        id="client-deals-table"
        ref={tableRef}
        pageSizeOptions={[1000]}
        skeletonRowsCount={3}
        filterColumns={() => columns}
        getRowHeight={() => 'auto'}
        editable={false}
        deletable={false}
        tabsFilter={{
          tabs: [
            {
              id: 'all',
              label: 'Все',
              filter: {},
            },
            {
              id: 'purchase',
              label: 'Покупка',
              filter: {
                _or: [
                  { clientId: { _eq: clientId } },
                  {
                    clients: {
                      _and: [
                        { clientId: { _eq: clientId } },
                        { type: { _eq: 'buyer' } },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: 'sale',
              label: 'Продажа',
              filter: {
                _or: [
                  { sellerId: { _eq: clientId } },
                  {
                    clients: {
                      _and: [
                        { clientId: { _eq: clientId } },
                        { type: { _eq: 'seller' } },
                      ],
                    },
                  },
                ],
              },
            },
          ],
          wrapperProps: {
            sx: { mt: -2 },
          },
        }}
        slots={{
          columnHeaders: () => null,
          footer: () => null,
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        getRowSpacing={() => ({ top: 10, bottom: 20 })}
        sx={{
          borderColor: 'transparent',
          mx: -1,
          mt: -1,
          marginRight: '-32px !important',
          '.MuiDataGrid-cell:first-child': {
            display: 'none',
          },
        }}
      />
    </TablePageLayout>
  );
}
