import { FormGetter, FormInput, FormInputProps } from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { Equal, Plus } from 'mdi-material-ui';
import React from 'react';

import { ManualWithComputedField } from 'components/ManualWithComputedField';
import { AmountInput, PercentInput, SignSeparator } from 'shared/inputs';

import { AgencyCommissionComputedField } from './shared/AgencyCommissionComputedField';
import { CommissionComputedField } from './shared/CommissionComputedField';

export type PriceTabCommissionsSectionProps = {
  inputProps?: Partial<FormInputProps<any>>;
};

export default function PriceTabCommissionsSection({
  inputProps: customInputProps,
}: PriceTabCommissionsSectionProps) {
  const inputProps = (
    field: string,
    type: 'percent' | 'fixed',
    label: string,
    props?: any,
  ) => {
    const inputName = type === 'fixed' ? `${field}Fixed` : field;

    return {
      name: inputName,
      label,
      type: 'number' as const,
      xs: 12,
      md: 3.5,
      lg: 3.5,
      ...(type === 'percent' ? PercentInput : AmountInput),
      ...props,
      ...customInputProps,
    };
  };

  return (
    <FormGetter
      names={['isFixed']}
      render={({ isFixed }) => (
        <Grid container>
          {[
            { value: 'sellerCommission', label: 'Комиссия от продавца' },
            { value: 'sellingCommission', label: 'Комиссия от покупателя' },
            {
              value: 'developerCommission',
              label: 'Комиссия от застройщика',
            },
            { value: 'partnerCommission', label: 'Комиссия партнеру' },
          ].map((x) => (
            <Grid container xs={12}>
              <FormInput
                {...inputProps(x.value, 'percent', x.label, {
                  readOnly: !!isFixed,
                })}
              />
              <SignSeparator sign={Plus} />
              <FormInput
                {...inputProps(x.value, 'fixed', x.label, {
                  readOnly: !!isFixed,
                })}
              />
              <SignSeparator sign={Equal} />
              <CommissionComputedField
                {...inputProps('', 'fixed', '')}
                name={x.value}
                label={x.label}
              />
            </Grid>
          ))}
          <Grid container xs={12}>
            <AgencyCommissionComputedField
              xs={12}
              md={4}
              lg={3.5}
              name="agencyCommissionSum"
              label="Итоговая комиссия агентства"
              {...customInputProps}
            />

            <ManualWithComputedField
              xs={12}
              md={4}
              lg={3.5}
              name="sellerReceivedSum"
              label="Продавец получил на руки"
              computedField="computedSellerReceivedSum"
              {...AmountInput}
              placeholder="—"
              type="number"
              sx={{}}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
