import { CSSObject } from '@emotion/react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Drawer,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { NAVIGATION_DRAWER_WIDTH } from 'constants/theme';

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAVIGATION_DRAWER_WIDTH.lg,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '68px',
});

const Styled = {
  Divider: styled(Divider)(() => ({
    margin: '24px 0px',
    flexShrink: '0',
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    borderColor: 'rgb(45, 55, 72)',
  })),
  List: styled(List)(() => ({
    listStyle: 'none',
    margin: '2px 0px 0px',
    padding: '0px 0px 8px',
    position: 'relative',
  })),
  ListItem: styled(ListItem)(() => ({
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    display: 'flex',
    marginBottom: '4px',
    // padding: '0px 16px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: '8px',
  })),
  ListSubheader: styled(ListSubheader)(() => ({
    boxSizing: 'border-box',
    listStyle: 'none',
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    color: 'rgb(107, 114, 128)',
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '2.5',
    marginLeft: '16px',
    textTransform: 'uppercase',
  })),
  Button: styled(Button)(() => ({
    display: 'inline-flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontWeight: '600',
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    minWidth: '64px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: 'none',
    padding: '9px 24px',
    paddingRight: 0,
    // paddingLeft: '14px',
    borderRadius: '8px',
    color: 'rgb(209, 213, 219)',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    '&:hover, &.active': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    '&.active': {
      color: 'rgb(16, 185, 129)',
    },
  })) as typeof Button,
  Drawer: styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: NAVIGATION_DRAWER_WIDTH.lg,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  ),
};

export { Styled };
