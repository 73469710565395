import {
  FormGetter,
  FormInput,
  FormInputProps,
  HiddenInput,
} from '@kirz/mui-admin';
import React from 'react';

export type ComputedFieldProps = FormInputProps<any>;

export function ComputedField({ name, ...props }: ComputedFieldProps) {
  return (
    <>
      <HiddenInput
        name={name}
        formSubmitterValueResolver={null}
        formFetcherValueResolver={null}
      />
      <FormGetter
        names={[name]}
        render={(x) => (
          <FormInput
            name={name}
            {...props}
            value={x[name] ?? null}
            formSubmitterValueResolver={null}
            formFetcherValueResolver={null}
          />
        )}
      />
    </>
  );
}
