import {
  FormTab,
  DataTableExRef,
  DataTableEx,
  HasuraDataTableColumnDef,
  AutocompleteInput,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

import { DealClientType } from 'constants/other';
import { useSettings } from 'hooks/useSettings';

export default function ClientsTab(props: { dealId: number }) {
  const { dealId } = props;
  const { dealClientRoles } = useSettings();

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'clientId',
        headerName: 'ID',
        width: 100,
        valueGetter: ({ row }) => row.client?.id,
        renderCell: ({ row }) => (
          <Box>
            <OpenInNewInputAdorement
              field="clientId"
              baseUrl="/clients"
              entityId={row.client?.id}
            />
            {row.client?.id}
          </Box>
        ),
      },
      {
        field: 'type',
        headerName: 'Сторона',
        type: 'select',
        width: 150,
        items: DealClientType,
        onSort: (sort) => [{ type: sort }],
      },
      {
        field: 'role',
        headerName: 'Роль',
        type: 'select',
        width: 150,
        items: dealClientRoles,
        onSort: (sort) => [{ role: sort }],
      },
      {
        field: 'fullName',
        headerName: 'ФИО',
        flex: 1,
        valueGetter: ({ row }) => row.client?.fullName,
        onSort: (sort) => [{ client: { fullName: sort } }],
      },
      {
        field: 'phone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
        valueGetter: ({ row }) => row.client?.favouritePhone,
        onSort: (sort) => [{ user: { favouritePhone: sort } }],
      },
      { field: 'createdAt', headerName: 'Дата создания', type: 'date' },
    ],
    [],
  );

  return (
    <FormTab
      tab="clients"
      grid={false}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                ml: 'auto',
                mb: 2,
              }}
            >
              <Button
                startIcon={<Add />}
                variant="outlined"
                size="small"
                onClick={() => {
                  tableRef.current?.openFormDialog();
                }}
              >
                Добавить
              </Button>
            </Box>
            <DataTableEx
              id="deal-clients-table"
              ref={tableRef}
              source="dealClient"
              columns={columns}
              getRowId={(row) => row.id || Math.floor(Math.random() * 100)}
              selectProps={{
                source: 'dealClient',
                filter: { dealId: { _eq: dealId } },
                onSelection: () => [
                  'id type role createdAt client { id favouritePhone fullName }',
                ],
              }}
              formTitle={(isNew) =>
                isNew ? 'Добавить участника' : 'Редактировать участника'
              }
              formDialogProps={{
                formSubmitterProps: {
                  preSubmit(item) {
                    return {
                      ...item,
                      dealId,
                    };
                  },
                },
              }}
              skeletonRowsCount={1}
              sx={{ mb: -2 }}
              disableRemovedFilter
              sortBy={{ field: 'createdAt', sort: 'desc' }}
            >
              <AutocompleteInput
                name="clientId"
                mode="hasura"
                source="client"
                fetchAll={false}
                label="Клиент"
                selection="id fullName"
                itemText={(x) => `#${x.id} ${x.fullName}`}
                filter={(search) => {
                  console.log(search);

                  return {
                    _or: [
                      ...(Number.isInteger(parseInt(search, 10)) &&
                      search.length < 7
                        ? [{ id: { _eq: parseInt(search, 10) } }]
                        : []),
                      { fullName: { _ilike: `%${search}%` } },
                    ],
                  };
                }}
                inputProps={{
                  InputProps: {
                    startAdornment: (
                      <OpenInNewInputAdorement
                        field="clientId"
                        baseUrl="/clients"
                      />
                    ),
                  },
                }}
              />
              <AutocompleteInput
                name="type"
                label="Сторона"
                options={DealClientType}
                required
              />
              <AutocompleteInput
                name="role"
                label="Роль"
                options={dealClientRoles}
                required
              />
            </DataTableEx>
          </>
        )
      }
    </FormTab>
  );
}
