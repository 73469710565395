import { User } from 'types/user';

const store = {
  user: null,
  preferences: {},
} as {
  user: User | null;
  preferences: Record<string, any>;
};

export default store;
