import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  HasuraSelector,
  HasuraSelectorRef,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { PhoneMessageOutline } from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AudioPlayer } from 'components/AudioPlayer';
import { NewClientDialog } from 'components/NewClientDialog';
import { TagsGroup } from 'components/TagsGroup';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Call() {
  const routeParams = useParams() as Record<string, string>;
  const callId = parseInt(routeParams.id, 10);
  const { tags } = useContext(SettingsContext);
  const { user } = useContext(UserContext);
  const clientSelectorRef = useRef<HasuraSelectorRef>(null);
  const [isNewClientDialogOpened, setIsNewClientDialogOpened] = useState(false);

  return (
    <>
      <FormPageLayout
        source="call"
        breadcrumbs={[
          { text: 'Звонки', icon: PhoneMessageOutline, href: '/calls' },
          (item) => ({
            text: `Звонок #${item.id} от ${dayjs(item.createdAt).format(
              'DD.MM.YYYY HH:mm',
            )}`,
            copyOnClick: true,
          }),
        ]}
        breadcrumbsDeps={['createdAt']}
        defaultRoute="/calls"
        tagsSlot={
          <Box sx={{ ml: 1 }}>
            <FormGetter
              names={['tags']}
              render={({ tags: callTags }) =>
                callTags && (
                  <TagsGroup ids={callTags} size="small" placeholder={false} />
                )
              }
            />
          </Box>
        }
      >
        <HasuraSelector selection="clientPhone" />
        <HasuraSelector selection="createdAt" />
        <HasuraSelector selection="recordLink" />
        <HasuraSelector
          ref={clientSelectorRef}
          name="clientByPhone"
          selection="clientsByPhone {clientId}"
          resolveValue={({ clientsByPhone }) => {
            return {
              clientByPhone: clientsByPhone?.[0],
            };
          }}
        />
        <Grid container>
          <FormGetter
            names={['recordLink']}
            render={(x) => (
              <Grid xs={12} sx={{ mb: 3, height: '68px', overflow: 'hidden' }}>
                {x.recordLink && (
                  <AudioPlayer callId={callId} url={x.recordLink} />
                )}
                {x.recordLink === null && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    Запись звонка отсутствует
                  </Box>
                )}
              </Grid>
            )}
          />
          <FormGetter
            names={['clientByPhone']}
            render={(x) =>
              x?.clientByPhone?.clientId && (
                <AutocompleteInput
                  value={x.clientByPhone.clientId}
                  name="clientId"
                  mode="hasura"
                  source="client"
                  label="Клиент"
                  selection="id fullName"
                  itemText="fullName"
                  md={4}
                  fetchAll={false}
                  required
                  readOnly
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="clientId"
                          baseUrl="/clients"
                          entityId={x.clientByPhone.clientId}
                        />
                      ),
                    },
                  }}
                />
              )
            }
          />
          <AutocompleteInput
            name="userId"
            mode="hasura"
            source="user"
            label="Сотрудник"
            selection="id fullName"
            itemText="fullName"
            md={4}
            readOnly
          />
          <AutocompleteInput
            name="tags"
            label="Теги"
            multiple
            md={4}
            options={tags
              .filter((x) => !x.systemType && x.entity === 'call')
              .map((x) => ({ value: x.id, text: x.name }))}
            formFetcherValueResolver={{
              source: 'callTag',
              selection: 'tagId',
              filter: { callId: { _eq: callId } },
              resolveItems: (items) => items.map((x) => x.tagId),
            }}
            formSubmitterValueResolver={{
              type: 'hasura-many-to-many',
              source: 'callTag',
              foreignKey: 'tagId',
              entityId: { callId, companyId: user.companyId },
            }}
          />
          <FormGetter
            names={['clientByPhone']}
            render={(x) =>
              !x?.clientByPhone?.clientId && (
                <Grid md={2} sx={{ alignItems: 'center', display: 'flex' }}>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setIsNewClientDialogOpened(true)}
                  >
                    Создать клиента
                  </Button>
                </Grid>
              )
            }
          />
          <FormInput name="comment" label="Комментарий" multiline rows={4} />
          <FormInput
            name="extractedText"
            label="Распознанный текст"
            multiline
            rows={20}
            readOnly
          />

          <FormGetter
            names={['clientPhone']}
            render={(x) => (
              <NewClientDialog
                open={isNewClientDialogOpened}
                onClose={() => setIsNewClientDialogOpened(false)}
                formProps={{
                  defaultValues: {
                    phone: x.clientPhone,
                  },
                }}
                onSubmit={(item) => {
                  clientSelectorRef.current?.refetch();
                }}
              />
            )}
          />
        </Grid>
      </FormPageLayout>
    </>
  );
}
