import {
  ConfigurationContext,
  BaseDataTable,
  BaseDataTableColumnDef,
  BasePageLayout,
  FormDialog,
  FormGetter,
} from '@kirz/mui-admin';
import { Info, WhatsApp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  SxProps,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, InformationVariant } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Statuses } from 'constants/estate';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

type Estate = {
  id: number;
  status: string;
  purchasePrice: number;
  actualPrice: number;
  yearlyIncome: number;
};

type EstateStat = {
  count: number;
  purchasePrice: {
    short: string;
    full: number;
  };
  actualPrice: {
    short: string;
    full: number;
  };
  averageYearlyIncome: number;
};

type UserEstateStat = {
  id: number;
  fullName: string;
  estates: Estate[];
  inWork: EstateStat;
  forSale: EstateStat;
  sold: EstateStat;
  averageYearlyIncome: number;
};

const sum = (items: number[]) => items.reduce((acc, item) => acc + item, 0);
const toMillions = (price: number) => `${(price / 1000000).toFixed(2)}М`;

const calculateStats = (items: Estate[]) => {
  const inWorkEstates = items.filter((x) =>
    [
      Statuses.BOOKED,
      Statuses.DEPOSIT_MADE,
      Statuses.BOUGHT,
      Statuses.FOR_SALE,
    ].includes(x.status),
  );
  const forSaleEstates = items.filter((x) =>
    [Statuses.BOOKED, Statuses.DEPOSIT_MADE, Statuses.FOR_SALE].includes(
      x.status,
    ),
  );
  const soldEstates = items.filter((x) =>
    [Statuses.SOLD_OUT].includes(x.status),
  );

  const inWorkEstatesWithIncome = inWorkEstates.filter(
    (x) => x.yearlyIncome != null,
  );
  const forSaleEstatesWithIncome = forSaleEstates.filter(
    (x) => x.yearlyIncome != null,
  );
  const soldEstatesWithIncome = soldEstates.filter(
    (x) => x.yearlyIncome != null,
  );
  const estatesWithIncome = items.filter((x) => x.yearlyIncome != null);

  return {
    inWork: {
      count: inWorkEstates.length,
      purchasePrice: {
        short: toMillions(sum(inWorkEstates.map((x) => x.purchasePrice || 0))),
        full: sum(inWorkEstates.map((x: any) => x.purchasePrice || 0)),
      },
      actualPrice: {
        short: toMillions(
          sum(inWorkEstates.map((x) => x.actualPrice || x.purchasePrice || 0)),
        ),
        full: sum(
          inWorkEstates.map((x) => x.actualPrice || x.purchasePrice || 0),
        ),
      },
      averageYearlyIncome:
        inWorkEstatesWithIncome.length === 0
          ? 0
          : sum(inWorkEstatesWithIncome.map((x) => x.yearlyIncome)) /
            inWorkEstatesWithIncome.length,
    },
    forSale: {
      count: forSaleEstates.length,
      purchasePrice: {
        short: toMillions(sum(forSaleEstates.map((x) => x.purchasePrice || 0))),
        full: sum(forSaleEstates.map((x) => x.purchasePrice || 0)),
      },
      actualPrice: {
        short: toMillions(
          sum(forSaleEstates.map((x) => x.actualPrice || x.purchasePrice || 0)),
        ),
        full: sum(
          forSaleEstates.map((x) => x.actualPrice || x.purchasePrice || 0),
        ),
      },
      averageYearlyIncome:
        forSaleEstatesWithIncome.length === 0
          ? 0
          : sum(forSaleEstatesWithIncome.map((x) => x.yearlyIncome)) /
            forSaleEstatesWithIncome.length,
    },
    sold: {
      count: soldEstates.length,
      purchasePrice: {
        short: toMillions(sum(soldEstates.map((x) => x.purchasePrice || 0))),
        full: sum(soldEstates.map((x) => x.purchasePrice || 0)),
      },
      actualPrice: {
        short: toMillions(
          sum(soldEstates.map((x) => x.actualPrice || x.purchasePrice || 0)),
        ),
        full: sum(
          soldEstates.map((x) => x.actualPrice || x.purchasePrice || 0),
        ),
      },
      averageYearlyIncome:
        soldEstatesWithIncome.length === 0
          ? 0
          : sum(soldEstatesWithIncome.map((x) => x.yearlyIncome)) /
            soldEstatesWithIncome.length,
    },
    averageYearlyIncome:
      estatesWithIncome.length === 0
        ? 0
        : sum(estatesWithIncome.map((x) => x.yearlyIncome)) /
          estatesWithIncome.length,
    estatesWithIncome,
  };
};

// eslint-disable-next-line max-len
const statSortComparator = (v1: EstateStat, v2: EstateStat) =>
  v1.actualPrice.full - v2.actualPrice.full;

function StatCell(props: { stat: EstateStat; sx?: SxProps }) {
  const { stat, sx } = props;

  return (
    <Box sx={sx}>
      {stat.count}
      {' ('}
      <Tooltip title={`${stat.purchasePrice.full.toLocaleString()}₽`}>
        <span>{stat.purchasePrice.short}</span>
      </Tooltip>
      {' - '}
      <Tooltip title={`${stat.actualPrice.full.toLocaleString()}₽`}>
        <span>{stat.actualPrice.short}</span>
      </Tooltip>
      {') '}
    </Box>
  );
}

const StatName = styled('span')(() => ({
  width: '100px',
  display: 'inline-block',
}));

export function Statistics() {
  const { user, hasPermission } = useContext(UserContext);
  const { hasura } = useContext(ConfigurationContext);
  const [estates, setEstates] = useState<Estate[]>();
  const [users, setUsers] = useState<UserEstateStat[]>();
  const [selectedUser, setSelectedUser] = useState<UserEstateStat | null>(null);
  const showAll = hasPermission(Permissions.ANALYZE_STATISTICS_VIEW_ALL);
  const myStat = useMemo<UserEstateStat | undefined>(() => {
    if (!users) {
      return;
    }

    return users.find((x) => x.id === user.id);
  }, [user, users]);

  const totalStat = useMemo<UserEstateStat | undefined>(() => {
    if (!estates) {
      return;
    }

    return calculateStats(estates) as any;
  }, [estates]);

  const columns = useMemo<BaseDataTableColumnDef[]>(
    () => [
      {
        headerName: 'Сотрудник',
        field: 'fullName',
        flex: 1,
        width: 200,
        renderCell: ({ value, row }) => (
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 'bolder',
              cursor: 'pointer',
              color: 'black',
            }}
            onClick={() => {
              setSelectedUser(row);
            }}
          >
            {value}
          </Button>
        ),
      },
      {
        headerName: 'Доходность',
        field: 'averageYearlyIncome',
        flex: 1,
        valueFormatter: ({ value }) => `${value.toFixed(1)}%`,
      },
      {
        headerName: 'В работе',
        field: 'inWork',
        flex: 1,
        sortComparator: statSortComparator,
        renderCell: ({ value }) => <StatCell stat={value} />,
      },
      {
        headerName: 'В продаже',
        field: 'forSale',
        flex: 1,
        sortComparator: statSortComparator,
        renderCell: ({ value }) => <StatCell stat={value} />,
      },
      {
        headerName: 'В архиве',
        field: 'sold',
        flex: 1,
        sortComparator: statSortComparator,
        renderCell: ({ value }) => <StatCell stat={value} />,
      },
    ],
    [],
  );

  useEffect(() => {
    (async () => {
      const [items, allUsers] = await Promise.all([
        hasura.request({
          source: 'estate',
          selection: `
          id
          status
          price {
            purchasePrice
            actualPrice
            yearlyIncome
          }
          user {
            userId
          }
        `,
          ...(!showAll && {
            where: {
              user: {
                userId: { _eq: user.id },
              },
            },
          }),
        }),
        hasura.request({
          source: 'user',
          selection: 'id fullName:shortFullName',
          ...(!showAll && {
            id: { _eq: user.id },
          }),
        }),
      ]);

      const usersInfo = Object.values(
        items.reduce((acc: any, { price, ...item }: any) => {
          if (
            !item.user?.userId ||
            !item.status ||
            price.purchasePrice == null ||
            price.actualPrice == null
          ) {
            return acc;
          }

          acc[item.user.userId] = acc[item.user.userId] || {
            id: item.user.userId,
            estates: [],
          };

          acc[item.user.userId].estates.push({
            ...item,
            purchasePrice: price?.purchasePrice,
            actualPrice: price?.actualPrice,
            yearlyIncome: price?.yearlyIncome,
          });

          return acc;
        }, {} as any),
      ) as any[];

      const aggregatedUserInfo = usersInfo.map((x) => ({
        ...x,
        ...calculateStats(x.estates),
      }));

      const defaultStat: EstateStat = {
        count: 0,
        actualPrice: { short: '0M', full: 0 },
        purchasePrice: { short: '0M', full: 0 },
        averageYearlyIncome: 0,
      };

      setEstates(usersInfo.flatMap((x: any) => x.estates));
      setUsers(
        allUsers.map((x: any) => ({
          estates: [],
          inWork: defaultStat,
          forSale: defaultStat,
          sold: defaultStat,
          averageYearlyIncome: 0,
          ...x,
          ...aggregatedUserInfo.find((y) => y.id === x.id),
        })),
      );
    })();
  }, [hasura, user, showAll]);

  return (
    <BasePageLayout
      title="Статистика"
      paperProps={{
        sx: {
          p: 2,
        },
      }}
    >
      {!users ? (
        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Grid container sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ borderRadius: 1, border: 'thin solid #e6e8f0', p: 2 }}>
                <Typography variant="subtitle1">Моя статистика</Typography>
                {myStat && (
                  <Typography variant="body2">
                    <StatName>Доходность: </StatName>{' '}
                    {myStat.averageYearlyIncome.toFixed(1)}
                    %
                    <br />
                    <StatName>В работе: </StatName>{' '}
                    <StatCell stat={myStat.inWork} sx={{ display: 'inline' }} />
                    <br />
                    <StatName>В продаже: </StatName>{' '}
                    <StatCell
                      stat={myStat.forSale}
                      sx={{ display: 'inline' }}
                    />
                    <br />
                    <StatName>В архиве: </StatName>{' '}
                    <StatCell stat={myStat.sold} sx={{ display: 'inline' }} />
                  </Typography>
                )}
              </Box>
            </Grid>
            {showAll && (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{ borderRadius: 1, border: 'thin solid #e6e8f0', p: 2 }}
                >
                  <Typography variant="subtitle1">
                    Итоговая статистика
                  </Typography>
                  {totalStat && (
                    <Typography variant="body2">
                      <StatName>Доходность: </StatName>{' '}
                      {totalStat.averageYearlyIncome.toFixed(1)}
                      %
                      <br />
                      <StatName>В работе: </StatName>{' '}
                      <StatCell
                        stat={totalStat.inWork}
                        sx={{ display: 'inline' }}
                      />
                      <br />
                      <StatName>В продаже: </StatName>{' '}
                      <StatCell
                        stat={totalStat.forSale}
                        sx={{ display: 'inline' }}
                      />
                      <br />
                      <StatName>В архиве: </StatName>{' '}
                      <StatCell
                        stat={totalStat.sold}
                        sx={{ display: 'inline' }}
                      />
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          {showAll && (
            <>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Статистика по сотрудникам
              </Typography>
              <BaseDataTable
                id="users-statistics-table"
                rows={users}
                columns={columns}
                sortBy={{ field: 'averageYearlyIncome', sort: 'desc' }}
                sx={{ minHeight: 200, mx: -2, borderColor: 'transparent' }}
              />
            </>
          )}
        </Box>
      )}
      {selectedUser && (
        <Dialog
          open
          onClose={() => {
            setSelectedUser(null);
          }}
          // maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 0 }}>
            {selectedUser.fullName}
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={() => {
                setSelectedUser(null);
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Box sx={{}}>
            {[
              { name: 'В работе', key: 'inWork' },
              { name: 'В продаже', key: 'forSale' },
              { name: 'В архиве', key: 'sold' },
            ].map((x) => {
              // @ts-ignore
              const info = selectedUser[x.key] as EstateStat;

              return (
                <Box sx={{ pl: 3 }} key={x.key}>
                  <Typography variant="subtitle1">{x.name}</Typography>
                  <ul>
                    <Box
                      component="li"
                      sx={{ display: 'flex', whiteSpace: 'pre-line', mt: -2 }}
                    >
                      Количество:
                      <Typography sx={{ fontWeight: '500', ml: 1 }}>
                        {info.count}
                      </Typography>
                    </Box>
                    <Box component="li" sx={{ display: 'flex' }}>
                      Стоимость покупки:
                      <Typography sx={{ fontWeight: '500', ml: 1 }}>
                        {info.purchasePrice.full.toLocaleString()}₽
                      </Typography>
                    </Box>
                    <Box component="li" sx={{ display: 'flex' }}>
                      Стоимость продажи:
                      <Typography sx={{ fontWeight: '500', ml: 1 }}>
                        {info.actualPrice.full.toLocaleString()}₽
                      </Typography>
                    </Box>
                    <Box component="li" sx={{ display: 'flex' }}>
                      Средняя годовая доходность:
                      <Typography sx={{ fontWeight: '500', ml: 1 }}>
                        {info.averageYearlyIncome.toFixed(1)}%
                      </Typography>
                    </Box>
                  </ul>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ mb: 2, pl: 3 }}>
            <Typography variant="subtitle1">По всем объектам</Typography>
            <ul>
              <Box
                component="li"
                sx={{ display: 'flex', whiteSpace: 'pre-line', mt: -2 }}
              >
                Количество:
                <Typography sx={{ fontWeight: '500', ml: 1 }}>
                  {selectedUser.estates.length}
                </Typography>
              </Box>
              <Box component="li" sx={{ display: 'flex' }}>
                Стоимость покупки:
                <Typography sx={{ fontWeight: '500', ml: 1 }}>
                  {sum(
                    selectedUser.estates.map((x) => x.purchasePrice),
                  ).toLocaleString()}
                  ₽
                </Typography>
              </Box>
              <Box component="li" sx={{ display: 'flex' }}>
                Стоимость продажи:
                <Typography sx={{ fontWeight: '500', ml: 1 }}>
                  {sum(
                    selectedUser.estates.map((x) => x.actualPrice),
                  ).toLocaleString()}
                  ₽
                </Typography>
              </Box>
              <Box component="li" sx={{ display: 'flex' }}>
                Средняя годовая доходность:
                <Typography sx={{ fontWeight: '500', ml: 1 }}>
                  {selectedUser.averageYearlyIncome.toFixed(1)}%
                </Typography>
              </Box>
            </ul>
          </Box>
        </Dialog>
      )}
    </BasePageLayout>
  );
}
