import {
  AutocompleteInput,
  FormInput,
  FormTab,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useMemo, useRef } from 'react';

import { UserContext } from 'contexts';

export default function AdditionalTab(props: { clientId: number }) {
  const { clientId } = props;
  const { user } = useContext(UserContext);
  const presentsTableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'date' },
      { field: 'text', headerName: 'Текст', flex: 1 },
      {
        field: 'creator',
        headerName: 'Отправитель',
        flex: 1,
        selector: 'creator { *fullName* }',
        valueGetter(params) {
          return params.value?.fullName;
        },
      },
    ],
    [],
  );

  return (
    <FormTab tab="additional">
      {(isVisible) => (
        <>
          <FormInput name="workPlace" label="Место работы" md={6} />
          <FormInput name="workPosition" label="Должность" md={6} />
          <FormInput name="education" label="Образование" md={6} />
          <FormInput name="hobby" label="Хобби и увлечения" md={6} />
          <FormInput
            name="sportPreferences"
            label="Каким спортом занимается"
            md={6}
          />
          <FormInput name="autoOwned" label="Какой автомобиль" md={6} />
          <FormInput name="maritalStatus" label="Семейное положение" md={6} />
          <FormInput name="children" label="Дети" md={6} />
          <FormInput
            name="communicationFeatures"
            label="Особенности, которые необходимо учесть при работе и свойства характера"
            multiline
            minRows={2}
          />
          <Grid xs={12}>
            {isVisible && (
              <DataTableEx
                id="client-presents-table"
                ref={presentsTableRef}
                title="Подарки"
                source="clientPresent"
                columns={columns}
                formTitle={(isNew) =>
                  isNew ? 'Новый подарок' : 'Редактирование подарока'
                }
                sortBy={{ field: 'createdAt', sort: 'desc' }}
                inline
                sx={{ mx: -2, borderColor: 'transparent' }}
                selectProps={{
                  filter: { clientId: { _eq: clientId } },
                }}
                formDialogProps={{
                  formProps: {
                    defaultValues: {
                      creatorId: user.id,
                    },
                  },
                  formSubmitterProps: {
                    preSubmit: (item) => ({ ...item, clientId }),
                  },
                }}
              >
                <FormInput
                  name="text"
                  label="Текст подарка"
                  required
                  multiline
                  minRows={2}
                />
                <AutocompleteInput
                  mode="hasura"
                  source="user"
                  selection="id fullName"
                  itemText="fullName"
                  name="creatorId"
                  label="Отправитель"
                />
              </DataTableEx>
            )}
          </Grid>
        </>
      )}
    </FormTab>
  );
}
