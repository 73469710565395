export const Permissions = {
  // Задачи
  TASKS_ENABLED: 'TASKS_ENABLED',
  TASKS_VIEW_ALL: 'TASKS_VIEW_ALL',
  TASKS_EDIT_DATE: 'TASKS_EDIT_DATE',
  TASKS_EDIT_NOT_YOURS_TASK: 'TASKS_EDIT_NOT_YOURS_TASK',
  TASKS_DELETE: 'TASKS_DELETE',

  // Сделки
  DEALS_ENABLED: 'DEALS_ENABLED',
  DEALS_VIEW_ALL: 'DEALS_VIEW_ALL',
  DEALS_REPORT_VIEW: 'DEALS_REPORT_VIEW',
  DEALS_EDIT_RECEIVED_COMMISSION: 'DEALS_EDIT_RECEIVED_COMMISSION',
  DEALS_CANCEL_FINAL_STAGE: 'DEALS_CANCEL_FINAL_STAGE',
  DEALS_DELETE: 'DEALS_DELETE',

  // Лиды
  LEADS_ENABLED: 'LEADS_ENABLED',

  // Клиенты
  CLIENTS_ENABLED: 'CLIENTS_ENABLED',
  CLIENTS_VIEW_ALL: 'CLIENTS_VIEW_ALL',
  CLIENTS_VIEW_ALL_SEARCH: 'CLIENTS_VIEW_ALL_SEARCH',
  CLIENTS_ARCHIVE: 'CLIENTS_ARCHIVE',

  // Объекты
  ESTATES_ENABLED: 'ESTATES_ENABLED',
  ESTATES_VIEW_ALL: 'ESTATES_VIEW_ALL',
  ESTATES_VIEW_ALL_SEARCH: 'ESTATES_VIEW_ALL_SEARCH',
  ESTATES_ARCHIVE: 'ESTATES_ARCHIVE',

  // Жилые комплексы
  RESIDENTIAL_COMPLEXES_ENABLED: 'RESIDENTIAL_COMPLEXES_ENABLED',

  // Звонки
  CALLS_ENABLED: 'CALLS_ENABLED',
  CALLS_VIEW_ALL: 'CALLS_VIEW_ALL',

  // Статистика
  ANALYZE_STATISTICS: 'ANALYZE_STATISTICS',
  ANALYZE_STATISTICS_VIEW_ALL: 'ANALYZE_STATISTICS_VIEW_ALL',
  ANALYZE_VIEW_SALARIES: 'ANALYZE_VIEW_SALARIES',

  // Выборка
  ANALYZE_DATABASE_QUERY: 'ANALYZE_DATABASE_QUERY',
  ANALYZE_DATABASE_QUERY_MANAGEMENT: 'ANALYZE_DATABASE_QUERY_MANAGEMENT',

  // Настройкиа
  SETTINGS_USERS_FULL: 'SETTINGS_USERS_FULL',
  SETTINGS_ROLES_FULL: 'SETTINGS_ROLES_FULL',
  SETTINGS_FUNNELS_FULL: 'SETTINGS_FUNNELS_FULL',
  SETTINGS_DOCUMENTS_FULL: 'SETTINGS_DOCUMENTS_FULL',
  SETTINGS_FORMULES_FULL: 'SETTINGS_FORMULES_FULL',
  SETTINGS_PARAMETERS_FULL: 'SETTINGS_PARAMETERS_FULL',

  // Другое
  IS_CLIENT: 'IS_CLIENT',
  RELEASE_NOTES_MANAGEMENT: 'RELEASE_NOTES_MANAGEMENT',
};
