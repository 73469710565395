import { FormGetter, FormTab, DataTableExRef } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useRef } from 'react';

import { TasksTable } from 'components/TasksTable';

export default function TasksTab(props: { dealId: number }) {
  const { dealId } = props;

  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormTab
      tab="tasks"
      grid={false}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                ml: 'auto',
                mb: 2,
              }}
            >
              <Button
                startIcon={<Add />}
                variant="outlined"
                size="small"
                onClick={() => {
                  tableRef.current?.openFormDialog();
                }}
              >
                Добавить
              </Button>
            </Box>
            <FormGetter
              names={['clientId', 'sellerId']}
              render={({ clientId, sellerId }) => (
                <TasksTable
                  id="deal-tasks-table"
                  ref={tableRef}
                  skeletonRowsCount={5}
                  filterColumns={(columns) =>
                    columns.filter(
                      (x) => !['client', 'phone'].includes(x.field),
                    )
                  }
                  selectProps={{
                    filter: { dealId: { _eq: dealId } },
                  }}
                  formDialogProps={{
                    formProps: {
                      defaultValues: {
                        clientId,
                        dealId,
                      },
                    },
                    ...{
                      disableClient: true,
                      disableDeal: true,
                      clientOptions: [clientId, sellerId].filter((x) => !!x),
                    },
                  }}
                />
              )}
            />
          </>
        )
      }
    </FormTab>
  );
}
