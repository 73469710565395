import {
  ConfigurationContext,
  DateInput,
  FormDialog,
  FormGetter,
  FormInput,
  Grid,
  NavigationContext,
  NotificationsContext,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { ReleaseNote } from 'components/ReleaseNote';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { ReleaseNote as ReleaseNoteType } from 'types/entities';

export function Version() {
  const [isNewItemDialogOpened, setIsNewItemDialogOpened] = useState(false);
  const [releases, setReleases] = useState<ReleaseNoteType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const { navbarContainer } = useContext(NavigationContext);
  const { showConfirm } = useContext(NotificationsContext);
  const { hasura } = useContext(ConfigurationContext);
  const { hasPermission, setLatestViewedReleaseId } = useContext(UserContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchReleases = async () => {
    setIsLoading(true);

    const notes = await hasura.request({
      type: 'query',
      source: 'releaseNote',
      selection: 'id version content date',
      limit: 30,
      orderBy: { date: 'DESC' },
    });

    setReleases(notes);
    setIsLoading(false);

    if (notes.length) {
      localStorage.setItem('latest-release', notes[0].id);
      setLatestViewedReleaseId(notes[0].id);
    }
  };

  const onDelete = async () => {
    if (
      !(await showConfirm({
        title: 'Удалить релиз?',
        accept: 'Да',
        cancel: 'Отмена',
      }))
    ) {
      return;
    }

    await hasura.request({
      type: 'mutation',
      action: 'update',
      source: 'releaseNote',
      where: { id: { _eq: selectedItemId } },
      set: { isRemoved: true },
    });

    setIsNewItemDialogOpened(false);
    fetchReleases();
  };

  useEffect(() => {
    fetchReleases();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      {hasPermission(Permissions.RELEASE_NOTES_MANAGEMENT) && (
        <Portal container={navbarContainer} disablePortal={isSmallScreen}>
          <Button
            variant="contained"
            sx={{ ml: 'auto' }}
            startIcon={<Add />}
            onClick={() => {
              setSelectedItemId(null);
              setIsNewItemDialogOpened(true);
            }}
          >
            Добавить
          </Button>
        </Portal>
      )}

      <Typography
        variant="h3"
        sx={{ mb: { xs: 1, md: 3 }, mt: { xs: 0, md: 2 } }}
      >
        Обновления системы
      </Typography>

      {releases.map((release, idx) => (
        <ReleaseNote
          key={release.id}
          {...release}
          isLatest={idx === 0}
          onEdit={() => {
            setSelectedItemId(release.id);
            setIsNewItemDialogOpened(true);
          }}
          sx={{ mt: 2 }}
        />
      ))}

      <FormDialog
        open={isNewItemDialogOpened}
        source="releaseNote"
        onClose={() => {
          setIsNewItemDialogOpened(false);
        }}
        entityId={selectedItemId}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography variant="h5">Новый релиз</Typography>
            {selectedItemId !== null && (
              <Button
                sx={{ ml: 'auto', mr: 3 }}
                color="error"
                onClick={onDelete}
              >
                Удалить
              </Button>
            )}
          </Box>
        }
        maxWidth="xl"
        onSubmit={() => {
          fetchReleases();
        }}
      >
        <FormInput
          name="version"
          label="Версия"
          required
          placeholder="3.0.0"
          md={4}
        />
        {/* {selectedItemId && ( */}
        <DateInput name="date" label="Дата" md={3} />
        {/* )} */}
        <Grid xs={12} container>
          <FormInput
            name="content"
            label="Описание"
            required
            multiline
            rows={10}
            md={6}
            helperText="Markdown-разметка"
          />
          <Grid md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormGetter
              names={['content']}
              render={({ content }) => (
                <Box
                  sx={{
                    flex: 1,
                    minHeight: 200,
                    borderColor: '#e6e8f0',
                    borderWidth: '1px',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    p: 1,
                    pt: 0,
                    maxHeight: 265,
                    overflowY: 'scroll',
                  }}
                >
                  <ReactMarkdown>{content}</ReactMarkdown>
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </FormDialog>
    </Container>
  );
}
