import {
  DataTable,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
  FileInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

export function Documents() {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      { field: 'name', headerName: 'Название', flex: 1 },
      {
        field: 'fileId',
        headerName: 'Файл',
        type: 'file',
        sortable: false,
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Дата', type: 'dateTime' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Шаблоны договоров"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedItem(null);
            setIsEditModalOpened(true);
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTable
        id="document-templates-table"
        ref={tableRef}
        mode="hasura"
        source="documentTemplate"
        columns={columns}
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        deletable
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по названию',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
          }),
        }}
      />
      <FormDialog
        source="documentTemplate"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={!selectedItem ? 'Новый договор' : 'Редактирование договора'}
        maxWidth="sm"
        onSubmit={() => {
          tableRef.current?.reload();
        }}
        formSubmitterProps={{
          preSubmit(item) {
            return {
              ...item,
              type: 'deal',
            };
          },
        }}
      >
        <FormInput name="name" label="Название" required />
        <FileInput
          name="fileId"
          label="Договор"
          required
          dropzoneProps={{
            accept: {
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
            },
          }}
          md={12}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
