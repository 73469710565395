import { TablePageLayout } from '@kirz/mui-admin';
import React, { useContext, useMemo } from 'react';

import { CallsTable } from 'components/CallsTable';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Calls() {
  const { user, hasPermission } = useContext(UserContext);
  const { tags } = useContext(SettingsContext);

  const viewAllCalls = hasPermission(Permissions.CALLS_VIEW_ALL);

  const tableFilter = useMemo(
    () => ({
      _and: [
        ...(!viewAllCalls
          ? [
              {
                _or: [
                  {
                    userId: { _eq: user.id },
                  },
                  {
                    clientsByPhone: {
                      client: {
                        participants: {
                          userId: { _eq: user.id },
                        },
                      },
                    },
                  },
                ],
              },
            ]
          : []),
      ],
    }),
    [user.id, viewAllCalls],
  );

  return (
    <TablePageLayout title="Звонки">
      <CallsTable
        id="calls-table"
        skeletonRowsCount={50}
        selectProps={{
          filter: tableFilter,
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по телефону, ФИО клиента или сотрудника',
          },
          filter: (search) => ({
            _or: [
              ...search.flatMap((str) => [
                { userPhone: { _ilike: `%${str.replace(/[+ \-()]/g, '')}%` } },
                {
                  clientPhone: { _ilike: `%${str.replace(/[+ \-()]/g, '')}%` },
                },
              ]),
              {
                user: {
                  _or: search.map((str) => ({
                    fullName: { _ilike: `%${str}%` },
                  })),
                },
              },
              {
                clientsByPhone: {
                  client: {
                    _or: search.map((str) => ({
                      fullName: { _ilike: `%${str}%` },
                    })),
                  },
                },
              },
            ],
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'date',
              name: 'Дата (от)',
              field: 'from',
              filter: (value) => ({ createdAt: { _gte: value } }),
            },
            {
              type: 'date',
              name: 'Дата (до)',
              field: 'to',
              filter: (value) => ({ createdAt: { _lt: value } }),
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Сотрудник',
              field: 'userId',
              source: 'user',
              selection: 'id fullName',
              itemText: 'fullName',
            },
            {
              type: 'autocomplete',
              mode: 'hasura',
              name: 'Клиент',
              field: 'clientId',
              source: 'client',
              selection: 'id fullName',
              itemText: 'fullName',
              filter: (clientId) => ({
                clientsByPhone: { clientId: { _eq: clientId } },
              }),
              fetchAll: false,
            },
            {
              type: 'autocomplete',
              name: 'Тип звонка',
              field: 'direction',
              options: [
                { value: 'in', text: 'Входящий' },
                { value: 'out', text: 'Исходящий' },
              ],
            },
            {
              type: 'autocomplete',
              name: 'Тег',
              options: tags
                .filter((x) => x.entity === 'call')
                .map((x) => ({ value: x.id, text: x.name })),
              field: 'tagId',
              filter: (value) => ({ tags: { tagId: { _eq: value } } }),
            },
            {
              type: 'text',
              name: 'Телефон клиента',
              field: 'clientPhone',
              filter: (value) => ({
                clientPhone: { _ilike: value.replace(/[+ \-()]/g, '') },
              }),
            },
            {
              type: 'text',
              name: 'Телефон эксперта',
              field: 'clientPhone',
              filter: (value) => ({
                userPhone: { _ilike: `%${value.replace(/[+ \-()]/g, '')}%` },
              }),
            },
            {
              type: 'boolean',
              name: 'Состоялся?',
              field: 'isFinished',
              filter: (value) => ({ recordLink: { _isNull: !value } }),
            },
          ],
        }}
      />
    </TablePageLayout>
  );
}
