import {
  FormTab,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useContext, useMemo, useRef } from 'react';

import { NewDealCommentDialog } from 'components/NewDealCommentDialog';
import { UserContext } from 'contexts';

export default function NotesTab(props: { dealId: number }) {
  const { dealId } = props;
  const { user } = useContext(UserContext);

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'createdAt',
        headerName: 'Дата',
        type: 'date',
      },
      {
        field: 'user',
        headerName: 'Сотрудник',
        selector: 'user {shortFullName}',
        width: 150,
        valueGetter: ({ row }) => row.user?.shortFullName,
        onSort: (sort) => [{ user: { fullName: sort } }],
      },
      {
        field: 'text',
        headerName: 'Текст',
        flex: 4,
      },
    ],
    [],
  );

  return (
    <FormTab
      tab="notes"
      grid={false}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                ml: 'auto',
                mb: 2,
              }}
            >
              <Button
                startIcon={<Add />}
                variant="outlined"
                size="small"
                onClick={() => {
                  tableRef.current?.openFormDialog();
                }}
              >
                Добавить
              </Button>
            </Box>
            <DataTableEx
              id="deal-notes-table"
              ref={tableRef}
              source="dealNote"
              columns={columns}
              skeletonRowsCount={5}
              selectProps={{
                filter: { dealId: { _eq: dealId } },
              }}
              sortBy={{ field: 'createdAt', sort: 'desc' }}
              formTitle={(isNew) =>
                isNew ? 'Добавить примечание' : 'Отредактировать примечание'
              }
              formDialogProps={{
                formSubmitterProps: {
                  preSubmit: (item) => ({ dealId, userId: user.id, ...item }),
                },
              }}
              components={{
                FormDialog: NewDealCommentDialog,
              }}
            />
          </>
        )
      }
    </FormTab>
  );
}
