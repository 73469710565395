import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Play } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export function Queries() {
  const { hasPermission } = useContext(UserContext);

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'name', headerName: 'Название', flex: 1 },
      {
        field: 'execute',
        headerName: '',
        width: 140,
        sortable: false,
        selector: false,
        renderCell: ({ row }) => (
          <Button endIcon={<Play />} href={`/queries/${row.id}/execute`}>
            Выполнить
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Шаблоны запросов"
      actionContent={
        hasPermission(Permissions.ANALYZE_DATABASE_QUERY_MANAGEMENT) && (
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Добавить
          </Button>
        )
      }
    >
      <DataTableEx
        id="queries-table"
        ref={tableRef}
        source="databaseQuery"
        editPageUrl={(row) =>
          hasPermission(Permissions.ANALYZE_DATABASE_QUERY_MANAGEMENT)
            ? `/queries/${row.id}`
            : `/queries/${row.id}/execute`
        }
        deletable={hasPermission(Permissions.ANALYZE_DATABASE_QUERY_MANAGEMENT)}
        columns={columns}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) =>
          isNew ? 'Новый запрос' : 'Редактирование запроса'
        }
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по названию',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
          }),
        }}
      >
        <FormInput name="name" label="Название" required />
      </DataTableEx>
    </TablePageLayout>
  );
}
