import {
  AutocompleteInput,
  FormGetter,
  FormInput,
  FormTab,
} from '@kirz/mui-admin';
import React from 'react';

import { PaymentForms } from 'constants/estate';

export default function СlientPriceTab({ dealId }: { dealId: number }) {
  return (
    <FormTab tab="price">
      <FormInput name="contractNumber" label="Номер договора" readOnly />
      <FormInput
        name="contractArea"
        label="Метраж по договору"
        type="number"
        readOnly
      />
      <AutocompleteInput
        label="Форма оплаты"
        name="paymentForm"
        options={PaymentForms}
        readOnly
      />
      <FormGetter
        names={['price', 'isSeller', 'isBuyer', 'dealPrice']}
        render={({ isBuyer, isSeller, ...x }) => {
          console.log({ isSeller, isBuyer });
          const getPriceValue = (key: string) => {
            return x.price?.[key] ?? null;
          };

          return (
            <>
              <FormInput
                label="Сумма сделки"
                name="dealPrice"
                type="number"
                readOnly
                value={
                  isBuyer
                    ? x.dealPrice ?? null
                    : getPriceValue('sellerReceivedSum')
                }
              />
              <FormInput
                label="Сумма сделки по договору"
                name="dealContractPrice"
                type="number"
                readOnly
              />
              {isSeller && (
                <>
                  <FormInput
                    label="Налог"
                    name="tax"
                    type="number"
                    readOnly
                    value={getPriceValue('tax')}
                  />
                  <FormInput
                    label="Сумма предыдущей сделки"
                    name="purchasePrice"
                    type="number"
                    readOnly
                    value={getPriceValue('purchasePrice')}
                  />
                  <FormInput
                    label="Сумма предыдущей сделки по договору"
                    name="contractPurchasePrice"
                    type="number"
                    readOnly
                    value={getPriceValue('contractPurchasePrice')}
                  />
                  <FormInput
                    label="Общий доход"
                    name="totalIncome"
                    type="number"
                    readOnly
                    value={getPriceValue('totalIncome')}
                  />
                  <FormInput
                    label="Годовой доход"
                    name="yearlyIncome"
                    type="number"
                    readOnly
                    value={getPriceValue('yearlyIncome')}
                  />
                </>
              )}
            </>
          );
        }}
      />
    </FormTab>
  );
}
