import {
  AutocompleteInput,
  AutocompleteInputProps,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import React from 'react';

type EstateAutocompleteInputProps = AutocompleteInputProps & {
  showOpenInNew?: boolean;
  sellerId?: number;
};

export function EstateAutocompleteInput({
  showOpenInNew = true,
  sellerId,
  ...props
}: EstateAutocompleteInputProps) {
  return (
    <AutocompleteInput
      {...props}
      name={props.name ?? 'estateId'}
      mode="hasura"
      source="estate"
      label="Объект"
      selection="id realAddress residentialComplex { id name }"
      itemText={(item) =>
        `#${item.id} ${
          item.residentialComplex
            ? item.residentialComplex.name
            : item.realAddress || ''
        }`.trim()
      }
      fetchAll={false}
      filter={(input) => {
        const search = (input as unknown as string) ?? '';
        const isId =
          Number.isInteger(parseInt(search, 10)) && search.length < 7;

        const defaultFilter = {
          _or: [
            ...(isId ? [{ id: { _eq: parseInt(search, 10) } }] : []),
            { realAddress: { _ilike: `%${search}%` } },
            { residentialComplex: { name: { _ilike: `%${search}%` } } },
          ],
        };

        if (sellerId && !isId) {
          return {
            price: {
              purchaseDeal: {
                isRemoved: { _eq: false },
                _or: [
                  { clientId: { _eq: sellerId } },
                  {
                    clients: {
                      type: { _in: ['buyer'] },
                      clientId: { _eq: sellerId },
                      isRemoved: { _eq: false },
                    },
                  },
                ],
              },
            },
            isRemoved: { _eq: false },
            ...defaultFilter,
          };
        }

        return defaultFilter;
      }}
      inputProps={{
        ...(showOpenInNew && {
          InputProps: {
            startAdornment: (
              <OpenInNewInputAdorement
                field={props.name ?? 'estateId'}
                baseUrl="/estates"
              />
            ),
          },
        }),
      }}
    />
  );
}
