import {
  ConfigurationContext,
  FormInput,
  SelectInput,
  DataTable,
  DataTableRef,
  HasuraDataTableColumnDef,
  FormDialog,
  AutocompleteInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { FunnelStageTypes, FunnelTypes } from 'constants/funnels';

type Props = {
  open: boolean;
  onClose: () => void;
  entityId?: number;
  onSubmit?: () => void;
  onStageAdded?: (item: any) => void;
  onStageDeleted?: (item: any) => void;
  onStageSortChanged?: () => void;
};

export function EditFunnelDialog({
  open,
  onClose,
  onSubmit,
  onStageAdded,
  onStageDeleted,
  onStageSortChanged,
  entityId,
}: Props) {
  const { hasura } = useContext(ConfigurationContext);
  const [stagesSortValues, setStagesSortValues] = useState<number[] | null>(
    null,
  );
  const [isEditItemModalOpened, setIsEditItemModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'serialNumber',
        type: 'sort',
        sortKey: 'serialNumber',
        rowsSortValues: stagesSortValues,
        onSortChange: async (direction, row, targetRowSort) => {
          const { id, funnelId } = row;

          await hasura.request({
            type: 'custom',
            query: `
            mutation ChangeSort($where1: FunnelStageBoolExp!, $set1: FunnelStageSetInput!, $where2: FunnelStageBoolExp!, $set2: FunnelStageSetInput!) {
              result2: updateFunnelStage(where: $where2, _set: $set2) {
                affected_rows
              }
              result1: updateFunnelStage(where: $where1, _set: $set1) {
                affected_rows
              }
            }
          `,
            variables: {
              where1: {
                funnelId: { _eq: funnelId },
                id: { _eq: id },
              },
              set1: { serialNumber: targetRowSort },
              where2: {
                funnelId: { _eq: funnelId },
                serialNumber: { _eq: targetRowSort },
              },
              set2: { serialNumber: row.serialNumber },
            },
          });

          tableRef.current?.reload();

          if (onStageSortChanged) {
            onStageSortChanged();
          }
        },
      },
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Тип',
        type: 'select',
        minWidth: 200,
        items: FunnelStageTypes,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [stagesSortValues],
  );

  return (
    <FormDialog
      source="funnel"
      entityId={entityId}
      open={open}
      onClose={onClose}
      title={!entityId ? 'Новая воронка' : 'Редактирование воронки'}
      maxWidth="md"
      onSubmit={onSubmit}
      {...(entityId && {
        formFetcherProps: {
          onSelection: (selections) => [
            ...selections,
            'stages { serialNumber }',
          ],
          onFetch(item) {
            setStagesSortValues(item.stages.map((x: any) => x.serialNumber));
            return item;
          },
        },
      })}
    >
      <FormInput name="name" label="Название" required md={4} />
      <FormInput
        name="dealCommission"
        label="Комиссия по сделке"
        required
        type="number"
        md={4}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <AutocompleteInput name="type" label="Тип" md={4} options={FunnelTypes} />
      {entityId && (
        <Grid xs={12} sx={{ mt: 2, height: 400 }}>
          <DataTable
            id="funnel-stages-table"
            ref={tableRef}
            mode="hasura"
            source="funnelStage"
            title={{
              title: 'Этапы',
              actionButton: (
                <Button
                  sx={{ ml: 'auto' }}
                  startIcon={<AddIcon />}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setSelectedItem(null);
                    setIsEditItemModalOpened(true);
                  }}
                >
                  Добавить этап
                </Button>
              ),
            }}
            columns={columns}
            pageSizeOptions={[10, 20]}
            selectProps={{
              onSelection: (selections) => [...selections, 'funnelId'],
              filter: {
                funnelId: { _eq: entityId },
              },
            }}
            editable={{
              onEdit: (row) => {
                setSelectedItem(row);
                setIsEditItemModalOpened(true);
              },
            }}
            deletable={{
              onDeleted: (row) => {
                setStagesSortValues(
                  stagesSortValues!.filter((x) => x !== row.serialNumber),
                );

                if (onStageDeleted) {
                  onStageDeleted(row);
                }
              },
            }}
            sortBy={{ field: 'serialNumber', sort: 'asc' }}
          />
          <FormDialog
            source="funnelStage"
            entityId={selectedItem?.id}
            open={isEditItemModalOpened}
            onClose={() => {
              setIsEditItemModalOpened(false);
            }}
            title={!selectedItem ? 'Новый этап' : 'Редактирование этапа'}
            maxWidth="sm"
            onSubmit={(item) => {
              tableRef.current?.reload();
              setStagesSortValues([
                ...(stagesSortValues || []),
                item.serialNumber,
              ]);

              if (onStageAdded) {
                onStageAdded(item);
              }
            }}
            formFetcherProps={{
              onFetch: (row) => ({
                ...row,
                type: row.type ?? 'other',
              }),
            }}
            formSubmitterProps={{
              selection: ['serialNumber'],
              preSubmit: (row) => ({
                ...row,
                funnelId: entityId,
              }),
            }}
          >
            <FormInput name="name" label="Название" required />
            <SelectInput name="type" label="Тип" items={FunnelStageTypes} />
          </FormDialog>
        </Grid>
      )}
    </FormDialog>
  );
}
