import {
  ConfigurationContext,
  FormGetter,
  NotificationsContext,
} from '@kirz/mui-admin';
import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { NewTaskDialog } from 'components/NewTaskDialog';
import { colors } from 'theme/colors';

import { NewDealCommentDialog } from './NewDealCommentDialog';

type Props = {
  dealId: number;
  sx?: SxProps;
};

export function DealQuickActionsArea({ dealId, sx }: Props) {
  const {
    rest: { client: apiClient },
  } = useContext(ConfigurationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useContext(NotificationsContext);
  const fileInput = useRef<any>(null);
  const [isNewTaskDialogOpened, setIsNewTaskDialogOpened] = useState(false);
  const [isNewDealNoteDialogOpened, setIsNewDealNoteDialogOpened] =
    useState(false);

  const uploadFile = useCallback(
    async (file: File) => {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('dealId', dealId.toString());

      await apiClient.post('/files', formData);
      showAlert('Файл загружен');

      setIsLoading(false);
    },
    [apiClient],
  );

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
          height: 56,
          justifyContent: 'center',
          alignItems: 'center',
          border: `thin solid ${colors.border}`,
          borderRadius: 1,
        },
        sx as any,
      ]}
    >
      {isLoading ? (
        <CircularProgress size={14} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            '& > span': {
              opacity: 0.7,
            },
            '& > p': {
              display: 'inline',
              textDecoration: 'underline',
              opacity: 0.7,
              cursor: 'pointer',
              '&:hover': {
                opacity: 1,
              },
            },
          }}
        >
          <span>Прикрепите</span>{' '}
          <Typography
            variant="body2"
            onClick={() => {
              fileInput.current?.click();
            }}
          >
            файл
          </Typography>
          <span>, добавьте</span>{' '}
          <Typography
            variant="body2"
            onClick={() => {
              setIsNewDealNoteDialogOpened(true);
            }}
          >
            комментарий
          </Typography>{' '}
          <span>или</span>{' '}
          <Typography
            variant="body2"
            onClick={() => {
              setIsNewTaskDialogOpened(true);
            }}
          >
            задачу
          </Typography>
        </Typography>
      )}

      <input
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={(e) => {
          const [file] = e.target.files ?? [];
          if (!file) {
            return;
          }

          uploadFile(file);
        }}
      />

      {isNewTaskDialogOpened && (
        <FormGetter
          names={['clientId', 'sellerId']}
          render={(x) => (
            <NewTaskDialog
              open={isNewTaskDialogOpened}
              onClose={async () => {
                setIsNewTaskDialogOpened(false);
              }}
              formProps={{
                defaultValues: {
                  dealId,
                  clientId: x.clientId,
                },
              }}
              disableDeal
              disableClient
              clientOptions={[x.clientId, x.sellerId].filter((x) => !!x)}
              title="Новая задача"
            />
          )}
        />
      )}

      {isNewDealNoteDialogOpened && (
        <NewDealCommentDialog
          open={isNewDealNoteDialogOpened}
          onClose={async () => {
            setIsNewDealNoteDialogOpened(false);
          }}
          formSubmitterProps={{
            preSubmit: (item) => ({ dealId, ...item }),
          }}
          title="Комментарий"
        />
      )}
    </Box>
  );
}
