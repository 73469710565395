import {
  HasuraDataTableColumnDef,
  useNavigate,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  SelectInput,
  FormInput,
  ConditionalInput,
  FormGetter,
  Grid,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { RoleCommissionType, RoleCommissionTypes } from 'constants/other';
import { AmountInput, PercentInput } from 'shared/inputs';

import { RoleCommissionValuesTable } from './RoleCommissionValuesTable';

export const RoleCommissionsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'funnelId',
            headerName: 'Воронка',
            selector: 'funnel { *name* }',
            type: 'relationship',
          },
          {
            field: 'comission',
            headerName: 'Комиссия',
            selector: 'values { type value } type value',
            sortable: false,
            valueGetter({
              row,
            }: {
              row: {
                values: { type: string; value: number }[];
                type: string;
                value: number | null;
              };
            }) {
              if (row.type === RoleCommissionType.MULTIPLE) {
                return row.values
                  .map(
                    (x) =>
                      `${x.value}${
                        x.type === RoleCommissionType.PERCENT ? '%' : '₽'
                      }`,
                  )
                  .join(', ');
              }

              return `${row.value}${
                row.type === RoleCommissionType.PERCENT ? '%' : '₽'
              }`;
            },
          },
        ]),
      [filterColumns, navigate],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="roleCommission"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 0}
        sortBy={{ field: 'funnelId', sort: 'asc' }}
        persistStateMode="query"
        persistScrollBar={false}
        addButton={false}
        deletable={false}
        formDialogProps={{
          title: 'Редактирование комиссии',
          maxWidth: 'md',
          formProps: {
            shouldUnregister: false,
          },
        }}
      >
        <SelectInput
          name="type"
          label="Тип"
          items={RoleCommissionTypes}
          md={6}
        />
        <ConditionalInput
          deps={['type']}
          variants={[
            {
              if: (y) => y.type !== RoleCommissionType.MULTIPLE,
              input: (y) => (
                <FormInput
                  {...(y.type === RoleCommissionType.FIXED
                    ? AmountInput
                    : PercentInput)}
                  name="value"
                  label="Значение"
                  required
                  md={6}
                  lg={6}
                  sx={{}}
                />
              ),
            },
          ]}
        />
        <Grid md={12}>
          <FormGetter
            names={['type', 'id']}
            render={(x) =>
              x.type === RoleCommissionType.MULTIPLE && (
                <RoleCommissionValuesTable
                  id="role-commission-values-table"
                  inline
                  formDialogProps={{
                    formSubmitterProps: {
                      preSubmit(item) {
                        return { ...item, roleCommissionId: x.id };
                      },
                    },
                  }}
                  selectProps={{
                    filter: {
                      roleCommissionId: { _eq: x.id },
                    },
                  }}
                />
              )
            }
          />
        </Grid>
      </DataTableEx>
    );
  },
);
