import {
  AutocompleteInput,
  CheckboxInput,
  ConfigurationContext,
  NotificationsContext,
  FormDialogProps,
  FormDialog,
} from '@kirz/mui-admin';
import React, { useContext } from 'react';

import { RoomsNumbers } from 'constants/estate';

export function LinkEstateToClientDialog(
  props: Partial<FormDialogProps> & {
    clientId: number;
  },
) {
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);

  const { clientId, ...rest } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source="clientEstate"
      title="Привязать объект"
      maxWidth="sm"
      autoFocus={false}
      formProps={{
        defaultValues: { isOwner: false },
      }}
      formSubmitterProps={{
        preSubmit: async ({ estateId, ...other }) => {
          const [existingItem] = await hasura.request({
            source: 'clientEstate',
            selection: 'id',
            where: {
              estateId: { _eq: estateId },
              clientId: { _eq: clientId },
            },
            limit: 1,
          });

          if (existingItem) {
            showAlert('Объект уже существует', 'error');
            return null;
          }

          return { ...other, estateId, clientId };
        },
      }}
    >
      <AutocompleteInput
        mode="hasura"
        source="estate"
        selection="id residentialComplex {name} address roomsNumber totalArea"
        itemText={(item) => {
          const mainName = item.residentialComplex?.name || item.address;
          const name = [
            mainName,
            // eslint-disable-next-line max-len
            item.roomsNumber &&
              RoomsNumbers.find(
                (x) => x.value === item.roomsNumber,
              )?.text?.toLowerCase(),
            item.totalArea && `${item.totalArea}м²`,
          ]
            .filter((x) => !!x)
            .join(', ');

          return `#${item.id} ${name}`;
        }}
        name="estateId"
        label="Объект"
        required
      />
      <CheckboxInput name="isOwner" label="Собственник?" />
    </FormDialog>
  );
}
