import {
  FormInput,
  SelectInput,
  SubmitButton,
  Form,
  VerificationCodeInput,
} from '@kirz/mui-admin';
import {
  Alert,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useState } from 'react';

import { CompanyLogo } from 'components/CompanyLogo';
import { UserContext } from 'contexts';
import { api } from 'services/api';
import {
  LoginError,
  LoginPayload,
  UserCompany,
  UserIdentity,
} from 'types/auth';

export function Login() {
  const { initialize } = useContext(UserContext);
  const [loginStep, setLoginStep] = useState<
    'phone' | 'identity' | 'code' | 'company' | 'password'
  >('phone');
  const [identities, setIdentities] = useState<UserIdentity[]>([]);
  const [, setCompanies] = useState<UserCompany[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const theme = useTheme();

  const handleSubmit = async (payload: LoginPayload) => {
    const { success, errorCode, errorData } = await api.login(payload);
    if (success) {
      await initialize!();
      return;
    }

    setErrorMessage(null);
    setInfoMessage(null);

    switch (errorCode) {
      case LoginError.PHONE_VALIDATION_REQUIRED: {
        setLoginStep('code');
        setInfoMessage('Проверочный код был отправлен на ваш номер телефона');
        break;
      }
      case LoginError.IDENTITY_SELECTION_REQUIRED: {
        setLoginStep('identity');
        setIdentities(errorData.identities as UserIdentity[]);
        setInfoMessage(
          'Для вашего номера телефона доступны несколько уровней доступа',
        );
        break;
      }
      case LoginError.COMPANY_SELECTION_REQUIRED: {
        setLoginStep('company');
        setCompanies(errorData.companies as UserCompany[]);
        setInfoMessage(
          'Ваш номер телефона используется в нескольких компаниях',
        );
        break;
      }
      case LoginError.PASSWORD_VALIDATION_REQUIRED: {
        setLoginStep('password');
        setInfoMessage('Введите пароль');
        break;
      }
      case LoginError.IDENTITY_NOT_FOUND: {
        setErrorMessage('Пользователь не найден');
        setLoginStep('phone');
        break;
      }
      case LoginError.WRONG_PASSWORD: {
        setErrorMessage('Неверный пароль');
        break;
      }
      case LoginError.WRONG_VALIDATION_CODE: {
        setErrorMessage('Неверный проверочный код');
        break;
      }
      default: {
        throw new Error(`Unknown error: ${errorCode}`);
      }
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper elevation={16} sx={{ flex: 1, p: 4, width: '100%' }}>
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ position: 'relative', pt: 2 }}
        >
          <CompanyLogo
            htmlColor={theme.palette.brand.main}
            sx={{
              width: 50,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
          <Typography variant="h4">Вход</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Авторизация в CRM
          </Typography>
          <Form
            sx={{ pt: 2, alignSelf: 'stretch' }}
            onSubmit={handleSubmit}
            spacing={3}
            shouldUnregister={false}
          >
            <FormInput
              label="Телефон"
              clearable
              required
              name="phone"
              type="tel"
              disabled={loginStep !== 'phone'}
              autoFocus
            />
            {infoMessage && (
              <Grid xs={12}>
                <Alert severity="info">{infoMessage}</Alert>
              </Grid>
            )}
            {errorMessage && (
              <Grid xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            {loginStep === 'code' && (
              <VerificationCodeInput
                name="validationCode"
                required
                length={4}
                TextFieldsProps={{
                  inputProps: {
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                  },
                }}
              />
            )}
            {loginStep === 'identity' && (
              <SelectInput
                label="Уровень доступа"
                required
                name="identityId"
                items={identities.map((x) => ({
                  value: x.userId,
                  text: `${x.name} [${x.roleName}]`,
                }))}
              />
            )}
            {loginStep === 'password' && (
              <FormInput
                label="Пароль"
                type="password"
                clearable
                required
                name="password"
                autoFocus
              />
            )}
            <SubmitButton
              variant="contained"
              size="large"
              grid
              fullWidth
              xs={12}
            >
              Войти
            </SubmitButton>
          </Form>
        </Stack>
      </Paper>
    </Container>
  );
}
