import React, { useContext } from 'react';

import { UserContext } from 'contexts';

type PermissionDef = string | { not: string };

export function RoleGuard(props: {
  permission: PermissionDef | PermissionDef[];
  children?: React.ReactNode;
}) {
  const { permission, children } = props;
  const { hasPermission } = useContext(UserContext);

  const check = (condition: PermissionDef) =>
    typeof condition === 'string'
      ? hasPermission(condition)
      : !hasPermission(condition.not);

  const isAllowed = (() => {
    if (typeof permission === 'string') {
      return check(permission);
    }

    if ('not' in permission) {
      return check(permission);
    }

    return !!permission.find(check);
  })();

  if (!isAllowed || !children) {
    return null;
  }

  return children as JSX.Element;
}
