import {
  ConfigurationContext,
  FormGetter,
  FormPageLayout,
  FormSetter,
  FormTabs,
  HiddenInput,
  NotificationsContext,
} from '@kirz/mui-admin';
import { CurrencyRuble } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  AccountMultipleOutline,
  AccountSupervisorOutline,
  Bank,
  CalendarMultiselect,
  ClockOutline,
  CommentTextOutline,
  FileDocumentOutline,
  ListStatus,
  Trello,
} from 'mdi-material-ui';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

import DealStagesLine from './DealStagesLine';
import {
  MainTab,
  NotesTab,
  ParticipantsTab,
  TasksTab,
  PriceTab,
  СlientPriceTab,
} from './tabs';
import ClientsTab from './tabs/ClientsTab';
import FilesTab from './tabs/FilesTab';
import DelayedTab from './tabs/InstallmentTab';
import MortgageTab from './tabs/MortgageTab';

export default function Deal() {
  const routeParams = useParams() as Record<string, string>;
  const { showConfirm } = useContext(NotificationsContext);
  const { hasPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const { hasura } = useContext(ConfigurationContext);
  const dealId = parseInt(routeParams.id, 10);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  const buildBadge = (source: string, filter: Record<string, any>) => {
    return {
      badge: {
        subscribe: {
          source,
          selection: 'aggregate {count}',
          extractCount: (data: any) => data.aggregate.count,
          aggregation: true,
          filter,
        },
        badgeProps: {
          sx: {
            '.MuiBadge-badge': { backgroundColor: 'rgba(255,255,255,0.75)' },
          },
        },
      },
    };
  };

  return (
    <FormPageLayout
      source="deal"
      readOnly={isClient}
      breadcrumbs={[
        { text: 'Сделки', icon: Trello, href: '/deals' },
        (item) => ({
          text: `Сделка #${item.id}${
            item.name?.length > 1 && !isClient ? `: ${item.name}` : ''
          }`,
          copyOnClick: true,
        }),
      ]}
      defaultRoute="/deals"
      formFetcherProps={{
        onSelection(selections) {
          if (isClient) {
            return selections;
          }

          return [...selections, 'funnelId', 'funnelStageId'];
        },
      }}
      formSubmitterProps={{
        onSubmit({ ...item }) {
          return item;
        },
      }}
      tagsSlot={
        hasPermission(Permissions.DEALS_DELETE) && (
          <Button
            sx={{ ml: 2, mr: 2 }}
            color="error"
            onClick={async () => {
              const confirm = await showConfirm({
                title: 'Удалить сделку?',
                accept: 'Да',
                cancel: 'Нет',
              });

              if (!confirm) {
                return;
              }

              await hasura.request({
                type: 'mutation',
                source: 'deal',
                action: 'update',
                set: {
                  isRemoved: true,
                },
                where: {
                  id: { _eq: dealId },
                },
              });

              navigate(-1);
            }}
          >
            Удалить
          </Button>
        )
      }
    >
      {!isClient && (
        <>
          <HiddenInput name="funnelStageId" />
          <FormSetter
            render={(setValue) => (
              <FormGetter
                names={['funnelId', 'funnelStageId']}
                render={({ funnelStageId, funnelId }) => {
                  return (
                    <DealStagesLine
                      dealId={dealId}
                      funnelId={funnelId}
                      funnelStageId={funnelStageId}
                      onStageChanged={(newStageId) => {
                        setValue('funnelStageId', newStageId);
                      }}
                    />
                  );
                }}
              />
            )}
          />
        </>
      )}

      <FormGetter
        names={['paymentForm', 'isBuyer', 'isSeller']}
        render={({ paymentForm, isBuyer, isSeller, ...x }) => {
          return (
            <Box
              sx={{
                mt: 2,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormTabs
                persistStateMode="query"
                tabs={[
                  { id: 'main', label: 'Информация', icon: <ClockOutline /> },
                  ...(!isClient
                    ? [
                        {
                          id: 'clients',
                          label: 'Участники',
                          icon: <AccountSupervisorOutline />,
                          ...buildBadge('dealClient', {
                            isRemoved: { _eq: false },
                            dealId: { _eq: dealId },
                          }),
                        },
                      ]
                    : []),
                  {
                    id: 'price',
                    label: 'Стоимость',
                    icon: <CurrencyRuble />,
                  },
                  ...(paymentForm === 'mortgage' && (!isClient || isBuyer)
                    ? [{ id: 'mortgage', label: 'Ипотека', icon: <Bank /> }]
                    : []),
                  ...(paymentForm === 'delayed' && (!isClient || isBuyer)
                    ? [
                        {
                          id: 'installment',
                          label: 'Рассрочка',
                          icon: <CalendarMultiselect />,
                        },
                      ]
                    : []),
                  ...(!isClient
                    ? [
                        { id: 'tasks', label: 'Задачи', icon: <ListStatus /> },
                        {
                          id: 'notes',
                          label: 'Комментарии',
                          icon: <CommentTextOutline />,
                        },
                        {
                          id: 'participants',
                          label: 'Сотрудники',
                          icon: <AccountMultipleOutline />,
                        },
                      ]
                    : []),
                  {
                    id: 'files',
                    label: 'Документы',
                    icon: <FileDocumentOutline />,
                  },
                ]}
                tabsWrapperStyle={{ mx: { xs: 0, md: 0 } }}
              >
                <MainTab dealId={dealId} />
                {isClient ? (
                  <СlientPriceTab dealId={dealId} />
                ) : (
                  <PriceTab dealId={dealId} />
                )}
                <MortgageTab />
                <DelayedTab dealId={dealId} />
                {!isClient && (
                  <>
                    <ClientsTab dealId={dealId} />
                    <TasksTab dealId={dealId} />
                    <NotesTab dealId={dealId} />
                    <ParticipantsTab dealId={dealId} />
                  </>
                )}
                <FilesTab dealId={dealId} />
              </FormTabs>
            </Box>
          );
        }}
      />
    </FormPageLayout>
  );
}
