import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

export function Roles() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'createdAt', headerName: 'Дата создания', type: 'dateTime' },
      { field: 'name', headerName: 'Название', flex: 1 },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Роли"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTableEx
        id="roles-table"
        ref={tableRef}
        source="role"
        editPageUrl="/roles"
        columns={columns}
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) => (isNew ? 'Новая роль' : 'Редактирование роли')}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по названию',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
          }),
        }}
      >
        <FormInput name="name" label="Название" required />
      </DataTableEx>
    </TablePageLayout>
  );
}
