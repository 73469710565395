import {
  AutocompleteInput,
  ConfigurationContext,
  DateInput,
  FormInput,
  RadioGroupInput,
  FormDialog,
  FormDialogProps,
} from '@kirz/mui-admin';
import { Alert, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useState } from 'react';

import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function NewClientDialog(props: Partial<FormDialogProps>) {
  const [existingClient, setExistingClient] = useState<any>(null);
  const { user } = useContext(UserContext);
  const { leadSources } = useContext(SettingsContext);
  const { hasura } = useContext(ConfigurationContext);
  const { ...rest } = props;

  return (
    <FormDialog
      {...(rest as any)}
      source={rest.source ?? 'client'}
      title={rest.title ?? 'Новый клиент'}
      maxWidth={rest.maxWidth ?? 'md'}
      autoFocus={false}
      formProps={{
        defaultValues: {
          gender: 'male',
          userId: user.id,
          ...props?.formProps?.defaultValues,
        },
      }}
      formSubmitterProps={{
        async preSubmit({ phone, email, ...item }) {
          const [client] = await hasura.request({
            source: 'client',
            selection: 'id fullName',
            where: {
              phones: {
                phone: { _eq: phone },
              },
            },
            limit: 1,
          });

          if (client) {
            setExistingClient({ ...client, phone });
            return null;
          }

          return {
            ...item,
            phones: {
              data: [{ phone, isFavourite: true }],
            },
            ...(email && {
              emails: {
                data: [{ email, isFavourite: true }],
              },
            }),
          };
        },
      }}
    >
      {existingClient && (
        <Grid xs={12}>
          <Alert severity="error">
            Номер телефона +{existingClient.phone} уже принадлежит пользователю{' '}
            <Link href={`/clients/${existingClient.id}`}>
              {existingClient.fullName}
            </Link>
          </Alert>
        </Grid>
      )}
      <FormInput name="secondName" label="Фамилия" md={4} autoFocus />
      <FormInput name="firstName" label="Имя" md={4} required />
      <FormInput name="middleName" label="Отчество" md={4} />
      <FormInput
        name="phone"
        label="Телефон"
        md={4}
        type="phone"
        required
        disabled={!!props?.formProps?.defaultValues?.phone}
      />
      <FormInput name="email" label="E-mail" md={4} type="email" />
      <AutocompleteInput
        name="source"
        label="Источник"
        md={4}
        required
        options={leadSources.map((x) => ({ value: x, text: x }))}
      />
      <AutocompleteInput
        name="userId"
        mode="hasura"
        source="user"
        label="Эксперт"
        selection="id fullName"
        itemText="fullName"
        md={5}
        required
      />
      <DateInput name="birthday" label="День рождения" sm={6} md={3} />
      <RadioGroupInput
        name="gender"
        label="Пол"
        sm={6}
        md={4}
        items={[
          { text: 'муж.', value: 'male' },
          { text: 'жен.', value: 'female' },
        ]}
      />
      <FormInput
        name="comment"
        label="Комментарий"
        type="text"
        multiline
        rows={2}
      />
    </FormDialog>
  );
}
