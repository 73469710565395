import {
  AutocompleteInput,
  ConfigurationContext,
  FormTab,
  NotificationsContext,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, { useContext, useMemo, useRef } from 'react';

export default function AdditionalContactsTab(props: { clientId: number }) {
  const { clientId } = props;
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);

  const additionalClientsRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 70 },
      {
        field: 'fullName',
        headerName: 'ФИО',
        flex: 1,
        sortable: false,
      },
      {
        field: 'favouritePhone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
        sortable: false,
      },
      {
        field: 'favouriteEmail',
        headerName: 'E-mail',
        type: 'email',
        flex: 1,
        sortable: false,
      },
      {
        field: 'user',
        headerName: 'Эксперт',
        flex: 1,
        sortable: false,
      },
    ],
    [],
  );

  return (
    <FormTab
      tab="other-contacts"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex' }}
    >
      {(isVisible) =>
        !isVisible ? null : (
          <DataTableEx
            id="additional-contacts-table"
            ref={additionalClientsRef}
            source="clientRelationship"
            columns={columns}
            selectProps={{
              filter: {
                _or: [
                  { clientId: { _eq: clientId } },
                  { relatedClientId: { _eq: clientId } },
                ],
              },
              onSelection() {
                const clientSelections =
                  '{ id fullName favouritePhone favouriteEmail user {fullName} }';
                return [
                  'id',
                  `client ${clientSelections}`,
                  `relatedClient ${clientSelections}`,
                ];
              },
              onFetch: (items) =>
                items.map(({ id, client, relatedClient }) => {
                  const realClient =
                    client.id === clientId ? relatedClient : client;
                  return {
                    ...realClient,
                    user: realClient.user?.fullName,
                    clientId: realClient.id,
                    relationshipId: id,
                  };
                }),
            }}
            skeletonRowsCount={1}
            formTitle={() => 'Добавить доп. контакт'}
            sx={{ mx: -2, borderColor: 'transparent' }}
            title="Дополнительные контакты"
            editable={{
              link: (row) => `/clients/${row.clientId}`,
            }}
            deleteProps={{
              filter(row) {
                return { id: { _eq: row.relationshipId } };
              },
            }}
            formDialogProps={{
              formSubmitterProps: {
                preSubmit: async ({ relatedClientId }) => {
                  const [existingRelatedClient] = await hasura.request({
                    source: 'clientRelationship',
                    selection: 'id',
                    where: {
                      _or: [
                        {
                          clientId: { _eq: clientId },
                          relatedClientId: { _eq: relatedClientId },
                        },
                        {
                          clientId: { _eq: relatedClientId },
                          relatedClientId: { _eq: clientId },
                        },
                      ],
                    },
                    limit: 1,
                  });

                  if (existingRelatedClient) {
                    showAlert('Доп. контакт уже существует', 'error');
                    return null;
                  }

                  return { clientId, relatedClientId };
                },
              },
            }}
          >
            <AutocompleteInput
              mode="hasura"
              source="client"
              selection="id fullName favouritePhone"
              itemText={(x) =>
                `#${x.id} ${x.fullName} ${
                  x.favouritePhone ? `+${x.favouritePhone}` : ''
                }`
              }
              name="relatedClientId"
              label="Клиент"
              required
              fetchAll={false}
              filter={(str) => ({
                id: { _neq: clientId },
                _or: [
                  ...(Number.isInteger(parseInt(str, 10)) && str.length < 7
                    ? [{ id: { _eq: parseInt(str, 10) } }]
                    : []),
                  { fullName: { _ilike: `%${str}%` } },
                  {
                    phones: {
                      phone: { _ilike: `%${str}%` },
                      isFavourite: { _eq: true },
                    },
                  },
                ],
              })}
            />
          </DataTableEx>
        )
      }
    </FormTab>
  );
}
