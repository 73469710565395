import {
  AttachmentsZone,
  AutocompleteInput,
  CheckboxInput,
  ControlledPromise,
  DateInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  FormSetter,
  FormTab,
  FormTabs,
  HasuraSelector,
  OpenInNewInputAdorement,
  TimeInput,
} from '@kirz/mui-admin';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FileDocumentOutline, ListStatus, Text } from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NewTaskDialog } from 'components/NewTaskDialog';
import { TagsGroup } from 'components/TagsGroup';
import { TasksTable } from 'components/TasksTable';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

export function Task() {
  const routeParams = useParams() as Record<string, string>;
  const taskId = parseInt(routeParams.id, 10);
  const { tags } = useContext(SettingsContext);
  const { user, hasPermission } = useContext(UserContext);
  const isFinished = useRef(false);
  const [isNewTaskDialogOpened, setIsNewTaskDialogOpened] = useState(false);
  const [isReadonly, setIsReadonly] = useState(true);
  const newTaskCreationPromiseRef = useRef<ControlledPromise<boolean> | null>(
    null,
  );
  const submittedTaskRef = useRef<any | null>(null);

  return (
    <FormPageLayout
      source="task"
      breadcrumbs={[
        { text: 'Задачи', icon: ListStatus, href: '/tasks' },
        (item) => ({ text: `Задача #${item.id}`, copyOnClick: true }),
      ]}
      breadcrumbsDeps={[]}
      defaultRoute="/tasks"
      tagsSlot={
        <Box sx={{ ml: 1 }}>
          <FormGetter
            names={['systemTags', 'tags']}
            render={({ systemTags, tags: taskTags }) =>
              systemTags &&
              taskTags && (
                <TagsGroup
                  ids={[...systemTags, ...taskTags]}
                  size="small"
                  placeholder={false}
                />
              )
            }
          />
        </Box>
      }
      formProps={{
        readOnly: isReadonly,
      }}
      formFetcherProps={{
        onFetch(item) {
          isFinished.current = item.result?.length > 0;
          setIsReadonly(
            !hasPermission(Permissions.TASKS_EDIT_NOT_YOURS_TASK) &&
              item.userId !== user.id,
          );
          return item;
        },
      }}
      formSubmitterProps={{
        selection: ['result'],
        async preSubmit(item) {
          submittedTaskRef.current = item;

          if (!isFinished.current && item.result?.length > 0) {
            setIsNewTaskDialogOpened(true);

            newTaskCreationPromiseRef.current = new ControlledPromise();
            await newTaskCreationPromiseRef.current.wait();

            isFinished.current = true;
          }

          return item;
        },
      }}
    >
      <HasuraSelector
        name="systemTags"
        selection="systemTags: tags(where: {tag: {systemType: {_isNull: false}}}) {tagId}"
      />
      <HasuraSelector
        name="funnelStage"
        selection="deal { funnelStage { id name funnelId }}"
        refetchOnSubmit
        resolveValue={(row) => {
          return {
            ...row,
            funnelStage: row?.deal?.funnelStage,
          };
        }}
      />
      <FormTabs
        persistStateMode="query"
        tabs={[
          { id: 'main', label: 'Информация', icon: <Text /> },
          { id: 'files', label: 'Файлы', icon: <FileDocumentOutline /> },
        ]}
        tabsWrapperStyle={{ mx: { xs: 0, md: 0 } }}
      >
        <FormTab
          tab="main"
          grid={false}
          sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
        >
          {(isVisible) => (
            <>
              <Grid container sx={{ flexBasis: 'auto !important' }}>
                <AutocompleteInput
                  name="clientId"
                  mode="hasura"
                  source="client"
                  label="Клиент"
                  selection="id fullName"
                  itemText="fullName"
                  md={4}
                  fetchAll={false}
                  required
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="clientId"
                          baseUrl="/clients"
                        />
                      ),
                    },
                  }}
                />
                <FormSetter
                  render={(setValue) => (
                    <FormGetter
                      names={['clientId']}
                      onChange={(newValue, prevValue) => {
                        if (newValue.clientId && !prevValue?.clientId) {
                          return;
                        }

                        setValue('dealId', null);
                      }}
                      render={({ clientId }) => (
                        <AutocompleteInput
                          name="dealId"
                          mode="hasura"
                          source="deal"
                          label="Сделка"
                          selection="id name"
                          itemText={(x) =>
                            `Сделка #${x.id}${x.name ? `: ${x.name}` : ''}`
                          }
                          md={4}
                          disabled={!clientId}
                          filter={{
                            clientId: { _eq: clientId },
                          }}
                          disableFetch={!clientId}
                          inputProps={{
                            InputProps: {
                              startAdornment: (
                                <OpenInNewInputAdorement
                                  field="dealId"
                                  baseUrl="/deals"
                                />
                              ),
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
                <FormGetter
                  names={['funnelStage']}
                  render={({ funnelStage }) =>
                    funnelStage ? (
                      <FormInput
                        name="funnelStage"
                        label="Этап сделки"
                        value={funnelStage.name}
                        md={4}
                        readOnly
                      />
                    ) : (
                      <Grid
                        xs="auto"
                        md={4}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                      />
                    )
                  }
                />
                <AutocompleteInput
                  name="creatorId"
                  mode="hasura"
                  source="user"
                  label="Постановщик"
                  selection="id fullName"
                  itemText="fullName"
                  readOnly
                  fetchAll={false}
                  md={4}
                />
                <AutocompleteInput
                  name="userId"
                  mode="hasura"
                  source="user"
                  label="Исполнитель"
                  selection="id fullName"
                  itemText="fullName"
                  required
                  md={4}
                />
                <Grid
                  xs="auto"
                  md={4}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                />
                <DateInput
                  name="date"
                  label="Дата"
                  md={2}
                  required
                  readOnly={!hasPermission(Permissions.TASKS_EDIT_DATE)}
                />
                <TimeInput
                  name="time"
                  label="Время"
                  md={2}
                  readOnly={!hasPermission(Permissions.TASKS_EDIT_DATE)}
                />
                <FormGetter
                  names={['result']}
                  render={({ result }) => (
                    <FormInput
                      name="status"
                      label="Статус"
                      value={result?.length > 0 ? 'Завершена' : 'Не завершена'}
                      md={2}
                      readOnly
                    />
                  )}
                />
                <AutocompleteInput
                  name="tags"
                  label="Теги"
                  multiple
                  md={5}
                  options={tags
                    .filter(
                      (x) =>
                        (!x.systemType || x.systemType === 'task_birthday') &&
                        x.entity === 'task',
                    )
                    .map((x) => ({ value: x.id, text: x.name }))}
                  formFetcherValueResolver={{
                    source: 'taskTag',
                    selection: 'tagId',
                    filter: { taskId: { _eq: taskId } },
                    resolveItems: (items) => items.map((x) => x.tagId),
                  }}
                  formSubmitterValueResolver={{
                    type: 'hasura-many-to-many',
                    source: 'taskTag',
                    foreignKey: 'tagId',
                    entityId: { taskId, companyId: user.companyId },
                  }}
                />
                <CheckboxInput name="isImportant" label="Важная" md={1} />
                <FormInput
                  name="task"
                  label="Текст задачи"
                  md={6}
                  required
                  multiline
                  minRows={3}
                />
                <FormInput
                  name="result"
                  label="Результат"
                  md={6}
                  multiline
                  minRows={3}
                />
              </Grid>
              {isVisible && (
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="subtitle1">Последние задачи</Typography>
                  <FormGetter
                    names={['clientId']}
                    render={({ clientId }) => (
                      <TasksTable
                        id="client-latest-tasks-table"
                        sx={{
                          mt: 1,
                          minHeight: 400,
                          mx: -2,
                          borderColor: 'transparent',
                        }}
                        disableInitialization={clientId == null}
                        selectProps={{
                          filter: {
                            clientId: { _eq: clientId },
                            id: { _neq: taskId },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </>
          )}
        </FormTab>
        <FormTab tab="files">
          <AttachmentsZone
            name="taskId"
            entityId={taskId}
            attachmentsTypes={[{ value: 'files', text: 'Файлы' }]}
          />
        </FormTab>
      </FormTabs>
      {isNewTaskDialogOpened && (
        <FormGetter
          names={['clientId', 'dealId', 'userId', 'funnelStage']}
          render={(defaultValues) => (
            <NewTaskDialog
              open={isNewTaskDialogOpened}
              onClose={async () => {
                setIsNewTaskDialogOpened(false);

                newTaskCreationPromiseRef.current?.resolve(true);
                newTaskCreationPromiseRef.current = null;

                // Disabled temporary: https://gitlab.pqlab.dev/dev/qhouse/frontend/-/issues/968

                // if (
                //   submittedTaskRef.current?.task ===
                //   'Подтвердить завершение общения с клиентом'
                // ) {
                //   setIsNewTaskDialogOpened(false);

                //   newTaskCreationPromiseRef.current?.resolve(true);
                //   newTaskCreationPromiseRef.current = null;
                //   return;
                // }

                // const otherOpenedTasks = await hasura.request({
                //   type: 'query',
                //   source: 'task',
                //   limit: 2,
                //   where: {
                //     isFinished: { _eq: false },
                //     clientId: { _eq: defaultValues.clientId },
                //   },
                //   selection: 'id',
                // });

                // if (
                //   otherOpenedTasks.length < 2 &&
                //   user.mentorId &&
                //   newTaskCreationPromiseRef.current
                // ) {
                //   const isConfirmed = await showConfirm({
                //     title: 'Прекратить общение с клиентом?',
                //     text: 'У клиента нет других активных задач',
                //     cancel: 'Отмена',
                //     accept: 'Прекратить',
                //   });

                //   if (!isConfirmed) {
                //     return;
                //   }

                //   await hasura.request({
                //     type: 'mutation',
                //     source: 'task',
                //     action: 'insertOne',
                //     item: {
                //       ...defaultValues,
                //       userId: user.mentorId,
                //       task: 'Подтвердить завершение общения с клиентом',
                //       date: dayjs().format('YYYY-MM-DD'),
                //       time: dayjs().add(1, 'hour').format('HH:mm'),
                //       companyId: user.companyId,
                //       creatorId: user.id,
                //       isImportant: true,
                //     },
                //   });
                // }

                // setIsNewTaskDialogOpened(false);

                // newTaskCreationPromiseRef.current?.resolve(true);
                // newTaskCreationPromiseRef.current = null;
              }}
              formProps={{ defaultValues }}
              title="Следующая задача"
              disableClient
              disableDeal
              formSubmitterProps={{
                selection: ['id'],
                onSubmit(item) {
                  newTaskCreationPromiseRef.current?.resolve(true);
                  newTaskCreationPromiseRef.current = null;
                },
              }}
            />
          )}
        />
      )}
    </FormPageLayout>
  );
}
