import { Box, Link, Skeleton, SxProps, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { OrderBoolAscendingVariant } from 'mdi-material-ui';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { UserAvatar } from 'components/UserAvatar';
import { FunnelStage, FunnelStageDeal } from 'types/entities';

export default function DealsBoardItem(props: {
  deal: FunnelStageDeal;
  stage: FunnelStage;
  index: number;
  sx?: SxProps;
}) {
  const { deal, stage, index, sx } = props;
  const isInitialized = !!deal.createdAt;

  return (
    <Draggable draggableId={`${stage.id}-${deal.id}`} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            ...sx,
            bgcolor: 'white',
            border: '1px solid #dbdbdb',
            position: 'relative',
            padding: '1.0rem',
            boxShadow: '0 1px 2px rgba(var(--black,#000),.1)',
            cursor: 'grab',
            lineHeight: '16px',
            userSelect: 'none',
            ...(!isInitialized && {
              pointerEvents: 'none',
            }),
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: '4px',
              float: 'right',
              fontSize: '10px',
              color: '#666',
              opacity: 0.6,
            }}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {!isInitialized ? (
              <Skeleton width={100} />
            ) : stage.type === 'callStage' ||
              stage.type === 'difficultCallStage' ? (
              (dayjs(deal.funnelStageUpdatedAt) as any).fromNow()
            ) : (
              dayjs(deal.createdAt).format('DD.MM.YYYY HH:mm')
            )}
          </Box>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <Link
              href={!isInitialized ? '#' : `/deals/${deal.id}`}
              sx={{
                fontWeight: 600,
                lineHeight: 1.2,
                fontSize: '14px',
                color: '#303030',
                textDecoration: 'none',
                '&::hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {!isInitialized ? (
                <Skeleton width={160} />
              ) : (
                `Сделка #${deal.id}${
                  deal?.name?.length ? `(${deal.name})` : ''
                }`
              )}
            </Link>
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 0.5,
              overflow: 'hidden',
              color: '#666',
              fontSize: '12px',
              fontWeight: 600,
              letterSpacing: '0.5px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Link
              href={
                !isInitialized || !deal.client
                  ? '#'
                  : `/clients/${deal.client.id}`
              }
              sx={{
                color: 'rgb(102, 102, 102)',
                textDecoration: 'none',
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isInitialized ? (
                <Skeleton width={100} />
              ) : !deal.client ? (
                '—'
              ) : (
                `#${deal.client.id} ${deal.client.fullName}`
              )}
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title={
                !isInitialized
                  ? ''
                  : `${deal.completedTasksCount} из ${deal.allTasksCount} выполнено`
              }
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}
              >
                {isInitialized && (
                  <OrderBoolAscendingVariant
                    sx={{ mr: '2px', fontSize: '12px', opacity: 0.7 }}
                  />
                )}
                {!isInitialized ? (
                  <Skeleton width={60} />
                ) : (
                  `${deal.completedTasksCount} из ${deal.allTasksCount}`
                )}
              </Box>
            </Tooltip>
            <Box sx={{ ml: 'auto', marginBottom: '-13px', display: 'flex' }}>
              {!isInitialized ? (
                <>
                  <Box sx={{ mr: 0.5 }}>
                    <Skeleton variant="circular" width={21} />
                  </Box>
                  <Skeleton variant="circular" width={21} />
                </>
              ) : (
                deal.participants.map((participant, idx) => (
                  <Box
                    key={participant.id}
                    sx={{ mr: idx < deal.participants.length - 1 ? 0.5 : 0 }}
                  >
                    <UserAvatar
                      fullName={participant.fullName}
                      color={participant.avatarColor}
                      size={21}
                      fontSize="9px"
                    />
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
}
