import { InputAdornment, Tooltip } from '@mui/material';
import { FunctionVariant } from 'mdi-material-ui';
import React from 'react';

import { AmountInput } from 'shared/inputs';

export const computedInputProps = (
  field: string,
  label?: string,
  style?: any,
) => {
  return {
    name: field,
    label,
    readOnly: true,
    ...(style || AmountInput),
    xs: 12,
    md: 3,
    lg: 3,
    xl: 2,
    InputProps: {
      ...(style || AmountInput).InputProps,
      startAdornment: (
        <Tooltip title="Вычисляемое значение">
          <InputAdornment position="start">
            <FunctionVariant />
          </InputAdornment>
        </Tooltip>
      ),
    },
    placeholder: '—',
  };
};
