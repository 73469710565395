import {
  AutocompleteInput,
  ConditionalInput,
  DateInput,
  FormGetter,
  FormInput,
  FormSetter,
  FormTab,
  Grid,
  HasuraSelector,
  HiddenInput,
  OpenInNewInputAdorement,
} from '@kirz/mui-admin';
import {
  Alert,
  Badge,
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { FilterVariantPlus } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ConditionalInputGroup } from 'components/ConditionalInputGroup/ConditionalInputGroup';
import { DealQuickActionsArea } from 'components/DealQuickActionsArea';
import { EstateAutocompleteInput } from 'components/EstateAutocompleteInput';
import { Obligations, Types as EstateTypes } from 'constants/estate';
import { FunnelType } from 'constants/funnels';
import { DealClientTypeValue } from 'constants/other';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';
import { SettingsContext } from 'contexts/SettingsContext';

import DealTimeline from '../timeline/DealTimeline';
import DealTimelineItem from '../timeline/DealTimelineItem';
import { dealLogTypes } from '../timeline/dealLogs';

export default function MainTab({ dealId }: { dealId: number }) {
  const { funnelStages } = useContext(SettingsContext);
  const timelineControlRef = useRef<any>();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [dealLogsFilterAnchorEl, setDealLogsFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const { user, hasPermission } = useContext(UserContext);
  const isClient = hasPermission(Permissions.IS_CLIENT);

  const additionalInfoFields = useRef([
    'clientSource',
    'clientRefererUser',
    'clientRefererClient',
    'estateType',
    'clients',
  ]).current;

  const dealsFilter = useMemo(() => {
    if (!selectedFilters.length) {
      return null;
    }

    return {
      type: { _in: selectedFilters },
    };
  }, [selectedFilters]);

  useEffect(() => {
    const interval = setInterval(() => {
      timelineControlRef.current?.reload();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <FormTab tab="main" sx={{ mt: -2, mx: -2, flex: 1 }}>
      <Grid
        container
        sx={{
          flex: 1,
        }}
      >
        <Grid
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isClient ? (
            <>
              <HasuraSelector
                name="clientFields"
                selection="
                  id
                  client { id fullName } 
                  seller { id fullName } 
                  user { id fullName phone } 
                  clients { clientId type }
                  dealPrice
                  dealContractPrice
                  price {
                    tax
                    purchasePrice
                    contractPurchasePrice
                    sellerReceivedSum
                    totalIncome
                    yearlyIncome
                  }
                "
                resolveValue={(item) => {
                  return {
                    name: `Сделка #${item.id}`,
                    client: item.client,
                    isBuyer:
                      item.client?.id === user.id ||
                      !!item.clients.find(
                        (x: any) =>
                          [DealClientTypeValue.BUYER].includes(x.type) &&
                          x.clientId === user.id,
                      ),
                    isSeller:
                      item.seller?.id === user.id ||
                      !!item.clients.find(
                        (x: any) =>
                          [DealClientTypeValue.SELLER].includes(x.type) &&
                          x.clientId === user.id,
                      ),
                    seller: item.seller,
                  };
                }}
              />
              <FormGetter
                render={(x) => (
                  <>
                    <FormInput
                      name="name"
                      label="Название"
                      readOnly
                      value={x.name ?? null}
                    />
                    <DateInput name="dealDate" label="Дата сделки" readOnly />
                    <FormInput
                      name="seller"
                      label="Продавец"
                      readOnly
                      value={x?.seller?.fullName ?? null}
                    />
                    <FormInput
                      name="client"
                      label="Покупатель"
                      readOnly
                      value={x?.client?.fullName ?? null}
                    />
                    <FormInput
                      name="user"
                      label="Персональный менеджер"
                      readOnly
                      value={x?.user?.fullName ?? null}
                    />
                    <FormInput
                      name="userPhone"
                      label="Телефон менеджера"
                      readOnly
                      type="phone"
                      value={x?.user?.phone ?? null}
                      onClick={() => {
                        document.location.href = `tel:${x?.user?.phone}`;
                      }}
                    />
                  </>
                )}
              />
            </>
          ) : (
            <>
              <HiddenInput name="systemType" />

              <FormGetter
                names={['systemType']}
                render={(x) =>
                  x.systemType &&
                  x.systemType !== FunnelType.DEFAULT && (
                    <Grid xs={12}>
                      <Alert severity="info">
                        {x.systemType === FunnelType.RENT
                          ? 'Тип сделки: Аренда'
                          : 'Тип сделки: Техническая'}
                      </Alert>
                    </Grid>
                  )
                }
              />
              <Grid xs={12} container>
                <ConditionalInputGroup
                  deps={['systemType']}
                  if={(y) => {
                    return y.systemType === FunnelType.RENT;
                  }}
                  inputs={[
                    <DateInput
                      name="rentDeliveryDate"
                      label="Дата сдачи"
                      required
                      xs={12}
                    />,
                    <FormInput
                      name="rentContractTerm"
                      label="Срок договора"
                      required
                      xs={12}
                    />,
                    <FormInput
                      name="rentFee"
                      type="number"
                      label="Арендная плата"
                      required
                      xs={12}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₽</InputAdornment>
                        ),
                      }}
                    />,
                  ]}
                />
              </Grid>
              <FormInput name="name" label="Название" />
              <DateInput name="dealDate" label="Дата сделки" />
              <Grid container>
                <AutocompleteInput
                  name="sellerId"
                  mode="hasura"
                  source="client"
                  fetchAll={false}
                  label="Продавец"
                  selection="id fullName"
                  itemText="fullName"
                  md={12}
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="sellerId"
                          baseUrl="/clients"
                        />
                      ),
                    },
                  }}
                />
                <AutocompleteInput
                  name="clientId"
                  mode="hasura"
                  source="client"
                  fetchAll={false}
                  label="Покупатель"
                  selection="id fullName"
                  itemText="fullName"
                  md={12}
                  inputProps={{
                    InputProps: {
                      startAdornment: (
                        <OpenInNewInputAdorement
                          field="clientId"
                          baseUrl="/clients"
                        />
                      ),
                    },
                  }}
                />
              </Grid>

              <FormGetter
                names={['sellerId']}
                render={({ sellerId }) => (
                  <EstateAutocompleteInput {...({ sellerId } as any)} />
                )}
              />
              <AutocompleteInput
                name="userId"
                mode="hasura"
                source="user"
                label="Эксперт"
                selection="id fullName"
                itemText="fullName"
              />
              <AutocompleteInput
                name="funnelId"
                mode="hasura"
                source="funnel"
                label="Воронка"
                selection="id name"
                itemText="name"
                required
                disableClearable
              />
              <FormSetter
                render={(setValue) => (
                  <FormGetter
                    names={['funnelId']}
                    onChange={(newValue, prevValue) => {
                      if (newValue.funnelId && !prevValue.funnelId) {
                        return;
                      }

                      const newValue2 =
                        funnelStages.filter(
                          (x) => x.funnelId === newValue.funnelId,
                        )[0]?.id ?? null;

                      setValue('funnelStageId', newValue2);
                    }}
                  />
                )}
              />
              <AutocompleteInput
                name="obligations"
                label="Обременения"
                options={Obligations}
              />
              <HasuraSelector
                name={additionalInfoFields}
                selection={`client { source refererUser { shortFullName } refererClient { shortFullName } } estate { type } clients(where:{role:{_eq: "agent"}}) { type client { id shortFullName favouritePhone }}`}
                resolveValue={(item) => {
                  return {
                    clientSource: item.client?.source ?? null,
                    clientRefererUser: item.client?.refererUser ?? null,
                    clientRefererClient: item.client?.refererClient ?? null,
                    estateType: item.estate?.type ?? null,
                    clients: (item.clients ?? []).map((x: any) => ({
                      ...x.client,
                      type: x.type,
                    })),
                  };
                }}
                refetchOnSubmit
              />

              <Grid xs={12}>
                <FormGetter
                  names={additionalInfoFields}
                  render={(x) => (
                    <DealTimelineItem
                      name=" "
                      user={false}
                      contentOnly
                      nameColumnWidth={150}
                      list={[
                        {
                          name: 'Источник лида',
                          value:
                            x.clientSource === undefined
                              ? ''
                              : x.clientSource ?? '–',
                        },
                        {
                          name: 'Реферер эксперт',
                          value:
                            x.clientRefererUser === undefined
                              ? ''
                              : x.clientRefererUser?.shortFullName ?? '–',
                        },
                        {
                          name: 'Реферер клиент',
                          value:
                            x.clientRefererClient === undefined
                              ? ''
                              : x.clientRefererClient?.shortFullName ?? '–',
                        },
                        {
                          name: 'Тип объекта',
                          value:
                            x.estateType === undefined
                              ? ''
                              : EstateTypes.find(
                                  (y) => y.value === x.estateType,
                                )?.text ?? '–',
                        },
                        ...(x.clients === undefined
                          ? ''
                          : x.clients.map((client: any, clientIdx: number) => ({
                              name:
                                `Агент ` +
                                (client.type === 'buyer'
                                  ? 'покупателя'
                                  : 'продавца'),
                              value: `${client.shortFullName}${
                                client.favouritePhone
                                  ? ` +${client.favouritePhone}`
                                  : ''
                              }`,
                              url: `/clients/${client.id}`,
                            }))),
                      ]}
                      sx={{
                        pt: 1,
                        pb: 0,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        {!isClient && (
          <Grid
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                mt: { xs: 0, md: 1 },
                mx: { xs: 1, md: 2 },
                mb: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Badge
                badgeContent={selectedFilters.length}
                color="primary"
                sx={{ ml: -0.1, mr: 2 }}
              >
                <IconButton
                  onClick={(e) => {
                    setDealLogsFilterAnchorEl(e.currentTarget);
                  }}
                >
                  <FilterVariantPlus />
                </IconButton>
              </Badge>
              <DealQuickActionsArea dealId={dealId} sx={{ flex: 1 }} />

              <Menu
                anchorEl={dealLogsFilterAnchorEl}
                open={!!dealLogsFilterAnchorEl}
                onClose={() => {
                  setDealLogsFilterAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    maxHeight: 350,
                  },
                }}
              >
                {dealLogTypes
                  .filter((x) =>
                    [
                      'on_funnelStage_changed',
                      'on_task_created',
                      'on_task_removed',
                      'on_task_finished',
                      'on_note_created',
                      'on_call_created',
                    ].includes(x.value),
                  )
                  .map((x) => {
                    const isSelected = selectedFilters.includes(x.value);

                    return (
                      <MenuItem
                        dense
                        value={x.value}
                        onClick={() => {
                          setSelectedFilters(
                            isSelected
                              ? selectedFilters.filter((y) => y !== x.value)
                              : [...selectedFilters, x.value],
                          );
                        }}
                      >
                        <Checkbox checked={isSelected} />
                        {x.text}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Box>

            <Box
              sx={{
                width: '100%',
                height: '100%',
                flex: '1 1 0',
                overflowY: 'auto',
                minHeight: 700,
              }}
            >
              <DealTimeline
                dealId={dealId}
                controlRef={timelineControlRef}
                filter={dealsFilter}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </FormTab>
  );
}
