import { FormInputProps } from '@kirz/mui-admin';
import { InputAdornment, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FunctionVariant } from 'mdi-material-ui';
import React from 'react';

export const readOnlyProps = {
  readOnly: true,
  formFetcherValueResolver: null,
  formSubmitterValueResolver: null,
};

export const PercentInput: Partial<FormInputProps<any>> = {
  InputProps: {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  },
  InputLabelProps: {
    shrink: true,
  },
  // clearable: false,
  // md: 3,
  // lg: 'auto',
  type: 'number',
  min: undefined,
  signed: true,
  max: undefined,
  placeholder: '0',
  // sx: { width: { xs: '100%', lg: 215 } },
};

export const AmountInput: Partial<FormInputProps<any>> = {
  ...PercentInput,
  min: undefined,
  max: undefined,
  signed: true,
  InputProps: {
    endAdornment: <InputAdornment position="end">₽</InputAdornment>,
  },
};

export const PercentCombobox = {
  // ...({ md: 3, lg: 'auto' } as any),
  disableClearable: true,
  freeSolo: true,
  placeholder: '0',
  signed: true,
  inputProps: {
    ...PercentInput,
    md: undefined,
    lg: undefined,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end" sx={{ mr: '6px' }}>
          %
        </InputAdornment>
      ),
    },
  } as any,
};

export function SignSeparator({ sign: Sign }: any) {
  return (
    <Grid
      sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}
      xs={0}
      md="auto"
    >
      <Sign />
    </Grid>
  );
}

export function ComputedFieldAdornment() {
  return (
    <Tooltip title="Вычисляемое значение">
      <InputAdornment position="start">
        <FunctionVariant />
      </InputAdornment>
    </Tooltip>
  );
}
