import {
  DataTable,
  AutocompleteInput,
  CheckboxInput,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider } from '@mui/material';
import { DeleteRestore } from 'mdi-material-ui';
import React, { useMemo, useRef, useState } from 'react';

export function Users() {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isArchivedSelected, setIsArchivedSelected] = useState<boolean>(false);

  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      { field: 'fullName', headerName: 'ФИО', flex: 1 },
      {
        field: 'phone',
        headerName: 'Телефон',
        type: 'phone',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'E-mail',
        type: 'email',
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'Роль',
        flex: 1,
        selector: 'role {id *name*}',
        type: 'relationship',
        valueGetter: (x) => x.value.name,
      },
      {
        headerName: 'Руководитель',
        field: 'mentor { isRemoved *shortFullName* }',
        width: 140,
        type: 'relationship',
      },
      {
        field: 'recover',
        type: 'icon-button',
        icon: <DeleteRestore />,
        tooltip: 'Восстановить',
        selector: false,
        tabs: ['archived'],
        onClick: async (
          row,
          {
            configuration: { hasura },
            notifications: { showConfirm, showAlert },
          },
        ) => {
          if (
            !(await showConfirm({
              title: 'Восстановить пользователя?',
              accept: 'Восстановить',
              cancel: 'Отмена',
            }))
          ) {
            return;
          }

          await hasura.request({
            type: 'mutation',
            action: 'update',
            source: 'user',
            where: { id: { _eq: row.id } },
            set: { isRemoved: false },
          });

          showAlert('Восстановлено', 'success');
          await tableRef.current!.reload();
        },
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Пользователи"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedItem(null);
            setIsEditModalOpened(true);
          }}
        >
          Добавить
        </Button>
      }
    >
      <DataTable
        id="users-table"
        ref={tableRef}
        mode="hasura"
        source="user"
        columns={columns}
        disableRemovedFilter
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        deletable={!isArchivedSelected}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        tabsFilter={{
          tabs: [
            {
              id: 'active',
              label: 'Все',
              filter: { isRemoved: { _eq: false } },
            },
            {
              id: 'archived',
              label: 'Удаленные',
              filter: { isRemoved: { _eq: true } },
            },
          ],
          tabsProps: {
            onChange(e, value) {
              setIsArchivedSelected(value === 'archived');
            },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Поиск по имени и номеру телефона',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { fullName: { _ilike: `%${str}%` } },
              { phone: { _ilike: `%${str.replace(/[+ \-()]/g, '')}%` } },
            ]),
          }),
        }}
      />
      <FormDialog
        source="user"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={
          !selectedItem ? 'Новый пользователь' : 'Редактирование пользователя'
        }
        maxWidth="md"
        onSubmit={() => {
          tableRef.current?.reload();
        }}
        formProps={{
          defaultValues: { cardDarkTheme: false },
        }}
        formSubmitterProps={{
          mode: 'rest',
          url: () =>
            !selectedItem
              ? { url: '/users', method: 'POST' }
              : { url: `/users/${selectedItem.id}`, method: 'PUT' },
        }}
        formFetcherProps={{
          selector: {
            password: null,
          },
        }}
      >
        <FormInput name="secondName" label="Фамилия" required md={4} />
        <FormInput name="firstName" label="Имя" required md={4} />
        <FormInput name="middleName" label="Отчество" md={4} />
        <FormInput name="phone" label="Телефон" type="tel" required md={4} />
        <FormInput name="email" label="E-mail" type="email" md={4} />
        <AutocompleteInput
          mode="hasura"
          source="user"
          name="mentorId"
          label="Руководитель"
          selection="id shortFullName"
          itemText="shortFullName"
          itemValue="id"
          md={4}
          required
        />
        <AutocompleteInput
          mode="hasura"
          source="role"
          name="roleId"
          label="Роль"
          selection="id name"
          itemText="name"
          itemValue="id"
          md={6}
          required
        />
        <FormInput
          name="password"
          label="Пароль"
          type="password"
          md={6}
          required={!selectedItem}
          autoComplete="new-password"
          placeholder={selectedItem && 'Введите для изменения'}
        />
        <Divider />
        <FormInput name="slug" label="Slug" md={4} />
        <FormInput name="instagram" label="Instagram" md={4} />
        <FormInput name="telegram" label="Telegram" md={4} />
        <FormInput name="position" label="Должность" md={6} />
        <CheckboxInput
          name="cardDarkTheme"
          label="Темная тема визитки"
          md={6}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
