import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  BoxProps,
  ButtonBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import { UserAvatar } from 'components/UserAvatar';
import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

type AppBarAccountProps = BoxProps;

export function AppBarAccount({ ...props }: AppBarAccountProps) {
  const { logout } = useContext(UserContext);
  const { user, hasPermission } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isClient = hasPermission(Permissions.IS_CLIENT);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box {...props} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'right' }}>
          {!isClient && (
            <>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.primary',
                  fontWeight: '500',
                  lineHeight: 1,
                  fontSize: { xs: '0.8rem', md: '1rem' },
                }}
              >
                {user.fullName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  fontWeight: '500',
                  lineHeight: 1,
                  mt: '4px',
                  fontSize: { xs: '0.7rem', md: '0.875rem' },
                }}
              >
                {user.role.name}
              </Typography>
            </>
          )}
        </Box>
        <ButtonBase
          sx={{ ml: isClient ? 0 : 1.5 }}
          color="inherit"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <UserAvatar
            fullName={user.fullName}
            color="rgb(17, 24, 39)"
            tooltip={false}
          />
        </ButtonBase>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={
          isClient
            ? {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }
            : {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }
        }
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Настройки
        </MenuItem> */}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>
    </>
  );
}
