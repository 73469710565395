import {
  HasuraDataTableColumnDef,
  formatPhone,
  useNavigate,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Box, Button, Tooltip } from '@mui/material';
import {
  PhoneIncoming,
  PhoneMissed,
  PhoneOutgoing,
  Play,
} from 'mdi-material-ui';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { TagsGroup } from 'components/TagsGroup';

export const CallsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          { field: 'createdAt', headerName: 'Дата', type: 'dateTime' },
          {
            field: 'user',
            selector: 'user { *shortFullName* } userPhone',
            headerName: 'Сотрудник',
            minWidth: 150,
            flex: 1,
            renderCell: ({ value, row }) => (
              <Box sx={{ fontSize: '13px' }}>
                <Box sx={{ fontSize: ' 15px' }}>
                  {value?.shortFullName || '—'}
                </Box>
                <Box
                  component="a"
                  href={`tel://+${row.userPhone}`}
                  sx={{
                    color: 'black',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {formatPhone(row.userPhone)}
                </Box>
              </Box>
            ),
          },
          {
            field: 'clientByPhone',
            selector: 'clientsByPhone {client {*shortFullName*}} clientPhone',
            headerName: 'Клиент',
            minWidth: 150,
            flex: 1,
            renderCell: ({ value, row }) => (
              <Box sx={{ fontSize: '13px' }}>
                <Box sx={{ fontSize: ' 15px' }}>
                  {row.clientsByPhone?.[0]?.client?.shortFullName || '—'}
                </Box>
                <Box
                  component="a"
                  href={`tel://+${row.clientPhone}`}
                  sx={{
                    color: 'black',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {formatPhone(row.clientPhone)}
                </Box>
              </Box>
            ),
            sortable: false,
          },
          {
            field: 'recordLink',
            headerName: 'Запись',
            width: 150,
            renderCell: ({ value, row }) => (
              <Button
                disabled={!value}
                onClick={() => {
                  navigate(`/calls/${row.id}`);
                }}
                startIcon={<Play />}
              >
                Прослушать
              </Button>
            ),
          },
          {
            field: 'duration',
            headerName: 'Длительность',
            width: 140,
            renderCell: ({ value, row }) => (
              <>
                {`${Math.floor((value || 0) / 60)
                  .toString()
                  .padStart(2, '0')}:${Math.floor((value || 0) % 60)
                  .toString()
                  .padStart(2, '0')}`}
                {!row.recordLink && (
                  <Tooltip title="Звонок пропущен">
                    <PhoneMissed
                      color="error"
                      fontSize="small"
                      sx={{ ml: 0.8 }}
                    />
                  </Tooltip>
                )}
              </>
            ),
            sortable: false,
          },
          {
            field: 'direction',
            headerName: 'Тип',
            width: 100,
            renderCell: ({ value }) => {
              const Icon = value === 'in' ? PhoneIncoming : PhoneOutgoing;

              return (
                <Tooltip title={value === 'in' ? 'Входящий' : 'Исходящий'}>
                  <Icon
                    htmlColor={value === 'in' ? 'green' : 'red'}
                    fontSize="small"
                  />
                </Tooltip>
              );
            },
          },
          {
            field: 'tags',
            headerName: 'Теги',
            selector: 'tags { tagId }',
            sortable: false,
            width: 100,
            renderCell(params) {
              return <TagsGroup ids={params.value.map((x: any) => x.tagId)} />;
            },
          },
        ]),
      [filterColumns, navigate],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="call"
        editPageUrl="/calls"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 0}
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        persistScrollBar={false}
        addButton={false}
      />
    );
  },
);
