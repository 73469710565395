import { FormInput, FormTab } from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';

import { RoleCommissionsTable } from 'components/RoleCommissionsTable';

type Props = {
  roleId: number;
};

export function CommissionsTab({ roleId }: Props) {
  return (
    <FormTab
      tab="commissions"
      grid={false}
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      {(isVisible) =>
        isVisible && (
          <RoleCommissionsTable
            id="role-commissions-table"
            title="Комиссия по сделке"
            sx={{ minHeight: 400, mx: -2, borderColor: 'transparent' }}
            selectProps={{
              filter: {
                roleId: { _eq: roleId },
              },
            }}
          />
        )
      }
    </FormTab>
  );
}
