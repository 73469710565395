import { ConditionalInput } from '@kirz/mui-admin';
import React from 'react';

type Props = {
  deps?: string[];
  if: (values: Record<string, any>) => boolean;
  inputs: JSX.Element[];
};

export function ConditionalInputGroup({ deps, if: condition, inputs }: Props) {
  return (
    <>
      {inputs.map((input, idx) => (
        <ConditionalInput
          key={idx}
          deps={deps}
          variants={[
            {
              if: condition,
              input: input as any,
            },
          ]}
        />
      ))}
    </>
  );
}
