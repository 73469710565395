import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { NewDealDialog } from 'components/NewDealDialog';
import { FunnelStageTypes } from 'constants/funnels';
import { priceFormatter } from 'utils/format';

export const DealsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          { headerName: 'ID', field: 'id', width: 70 },
          { headerName: 'Название', field: 'name', width: 100 },
          {
            headerName: 'Покупатель',
            field: 'client',
            type: 'relationship',
            selector: 'client { *shortFullName* }',
            flex: 1,
          },
          {
            headerName: 'Статус',
            field: 'funnelStage',
            type: 'relationship',
            selector: 'funnelStage { *name* }',
            flex: 1,
          },
          {
            headerName: 'Воронка',
            field: 'funnel',
            type: 'relationship',
            selector: 'funnelStage { funnel { *name* } }',
            flex: 1,
          },
          {
            headerName: 'Тип стадии сделки',
            field: 'funnelStageType',
            type: 'relationship',
            selector: 'funnelStage { *type* }',
            flex: 1,
            valueFormatter({ value }) {
              return FunnelStageTypes.find((x) => x.value === value)?.text;
            },
          },
          {
            headerName: 'Сумма сделки',
            field: 'dealPrice',
            valueFormatter: priceFormatter,
            flex: 1,
          },
          {
            headerName: 'Дата сделки',
            field: 'dealDate',
            type: 'date',
            flex: 1,
          },
          {
            headerName: 'Эксперт',
            field: 'user',
            type: 'relationship',
            selector: 'user { *shortFullName* }',
            flex: 1,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="deal"
        columns={columns}
        skeletonRowsCount={rest.skeletonRowsCount ?? 3}
        sortBy={rest.sortBy ?? { field: 'id', sort: 'desc' }}
        persistStateMode="query"
        editable={
          rest.editable ?? {
            link: (row) => `/deals/${row.id}`,
          }
        }
        components={{
          FormDialog: NewDealDialog,
        }}
      />
    );
  },
);
