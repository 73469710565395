import { BasePageLayout } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useRef, useState } from 'react';

import { DealsBoard } from 'components/DealsBoard';
import DealsFilter from 'components/DealsBoard/DealsFilter';

export function UserDeals() {
  const [stagesFilter, setStagesFilter] = useState<Record<string, any>>();
  const [dealsFilter, setDealsFilter] = useState<Record<string, any>>();

  const filtersControlRef = useRef<any>();

  return (
    <BasePageLayout
      title="Воронки"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            filtersControlRef.current?.openNewDealDialog();
          }}
        >
          Добавить
        </Button>
      }
    >
      <Box sx={{ ml: 2, mt: 1 }}>
        <DealsFilter
          mode="funnel"
          controlRef={filtersControlRef}
          onStagesFilterChange={(value) => {
            setStagesFilter(value);
          }}
          onDealsFilterChange={(value) => {
            setDealsFilter(value);
          }}
        />
      </Box>
      <DealsBoard stagesFilter={stagesFilter} dealsFilter={dealsFilter} />
    </BasePageLayout>
  );
}
