import { FormPageLayout, FormTabs } from '@kirz/mui-admin';
import { Percent } from '@mui/icons-material';
import { AccountCogOutline, ListStatus } from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import { CommissionsTab, PermissionsTab } from './tabs';

export default function Role() {
  const routeParams = useParams() as Record<string, string>;
  const roleId = parseInt(routeParams.id, 10);

  return (
    <FormPageLayout
      source="role"
      breadcrumbs={[
        { text: 'Роли', icon: ListStatus, href: '/roles' },
        (item) => ({ text: item.name, copyOnClick: true }),
      ]}
      breadcrumbsDeps={[]}
      defaultRoute="/roles"
    >
      <FormTabs
        persistStateMode="query"
        tabs={[
          {
            id: 'permissions',
            label: 'Управление доступом',
            icon: <AccountCogOutline />,
          },
          {
            id: 'commissions',
            label: 'Настройка комиссий',
            icon: <Percent />,
          },
        ]}
      >
        <PermissionsTab />
        <CommissionsTab roleId={roleId} />
      </FormTabs>
    </FormPageLayout>
  );
}
